import axios from "axios";
import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import {
  GET_ALL_ALERTS_API,
  GET_ALL_REMINDERS_API,
} from "../../routes/ApiRoutes";
import toast from "react-hot-toast";

export function ConvertToReadableDate(dateString) {
  const date = new Date(dateString.date);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
}

const AlertsDates = ({ type }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [list, setList] = useState([]);

  const handleAlerts = () => {
    const url = type === "alert" ? GET_ALL_ALERTS_API : GET_ALL_REMINDERS_API;

    axios
      .get(url, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useLayoutEffect(() => {
    handleAlerts();
  }, []);

  return (
    <Container>
      {list.map((val, i) => (
        <Items key={i}>
          <p>{type === "alert" ? val.alertName : val.reminderName}</p>
          <span>
            <ConvertToReadableDate date={val.date} />
          </span>
        </Items>
      ))}
    </Container>
  );
};

export default AlertsDates;

export const Container = styled("div")`
  div {
    border-top: 1px solid #ffffff90;
    &:first-child {
      border-top: none;
    }
  }
`;
export const Items = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 1em;
  p {
    margin: 0;
  }
`;
