import React from "react";
import { ModalContainer } from "./index.style";
import AddTaskComponent from "../AddTaskComponent";

const CreateTaskModal = ({ visible, setVisible }) => {
  return (
    <ModalContainer
      title=""
      footer={false}
      open={visible}
      onCancel={setVisible}
      width="700px"
    >
      <AddTaskComponent />
    </ModalContainer>
  );
};

export default CreateTaskModal;
