import React, { useLayoutEffect, useState } from "react";
import { CreateModal } from "./index.style";
import { Button, Col, DatePicker, Form, Row, Select, TimePicker } from "antd";
import CustomInput from "../../common/CustomInput";
import { FormFooter } from "../../screens/user/dashboard/index.style";
import axios from "axios";
import {
  ADD_REMINDER_API,
  CREATE_ALERT_API,
  GET_ALL_PATIENT_API,
} from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import moment from "moment";

const CreateAlert = ({ visible, setVisible, titleName, updateList }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [isProgress, setIsProgress] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const format = "HH:mm";

  const handleCreateReminder = (value) => {
    const { alert_name, patient_id } = value;
    setIsProgress(true);
    axios
      .post(
        ADD_REMINDER_API,
        {
          patient: patient_id,
          reminderName: alert_name,
          date,
          time,
        },
        {
          headers: {
            Authorization: user.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setVisible(false);
          updateList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => alert(err))
      .finally(() => {
        setIsProgress(false);
      });
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const handleCreateAlert = (value) => {
    const { alert_name, patient_id } = value;
    setIsProgress(true);
    axios
      .post(
        CREATE_ALERT_API,
        {
          patient: patient_id,
          alertName: alert_name,
          date,
          time,
        },
        {
          headers: {
            Authorization: user.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setVisible(false);
          updateList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => alert(err))
      .finally(() => {
        setIsProgress(false);
      });
  };

  const handleSubmit = (value) => {
    if (titleName === "Reminder") {
      handleCreateReminder(value);
    } else {
      handleCreateAlert(value);
    }
  };

  const handlePatientList = () => {
    axios
      .get(GET_ALL_PATIENT_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setPatientList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  useLayoutEffect(() => {
    handlePatientList();
  }, []);

  return (
    <CreateModal
      footer={false}
      title={<h3>Create {titleName}</h3>}
      open={visible}
      onCancel={setVisible}
      width="700px"
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col span={24}>
            <CustomInput
              label={`${titleName} Name`}
              name="alert_name"
              placeholder={`${titleName} Name`}
            />
          </Col>
          <Col span={24}>
            <Form.Item label="Patient" name="patient_id">
              <Select placeholder="Choose Patient">
                {patientList.map((val, i) => (
                  <Select.Option value={val._id} key={i}>
                    {val.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Date">
              <DatePicker
                disabledDate={disabledDate}
                onChange={(val, val2) => {
                  setDate(val2);
                }}
                placeholder="mm/dd/yyy"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Time">
              <TimePicker
                format={format}
                placeholder="hh/mm"
                onChange={(val, val2) => {
                  setTime(val2);
                }}
              />
            </Form.Item>
          </Col>
          <FormFooter style={{ borderTop: "none", width: "100%" }}>
            <Button disabled={isProgress} htmlType="submit">
              Add
            </Button>
          </FormFooter>
        </Row>
      </Form>
    </CreateModal>
  );
};

export default CreateAlert;
