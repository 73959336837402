import styled from "styled-components";

export const Wrapper = styled("div")`
  h1 {
    color: #ffffff !important;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.3em;
  }

  label {
    color: #ffffff !important;
  }
  .selectionArea {
    padding: 1.5em;
    .ant-select-selector {
      border: 1px solid #ffffff !important;
      background: #15112c !important;
      border-radius: 50px;
      width: 100%;
      // height: 56px;
      padding: 1em;
      input {
        &:placeholder {
          color: #ffffff90 !important;
        }
      }
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          background: #3a49d6 !important;
          border-radius: 20px;
        }
        .anticon-close {
          svg {
            color: #ffffff;
          }
        }
      }
    }
    .ant-select-arrow {
      right: 2%;
      background-size: contain;
      background-image: url(./icons/arrow-down.svg);
      width: 20px;
      background-repeat: no-repeat;
      .anticon {
        svg {
          display: none;
          color: #ffffff !important;
        }
      }
      .anticon-search {
        display: none;
        transform: rotateY(0deg) !important;
        svg {
          color: #ffffff !important;
          transform: rotateY(0deg) !important;
        }
      }
    }
    textarea,
    .ant-picker {
      border: 1px solid #ffffff;
      color: #ffffff;
      background: #15112c;
      width: 100%;
      border-radius: 40px;
      margin-right: 1em;
      height: 56px;
      input {
        ::placeholder {
          color: #ffffff90;
        }
      }
      .ant-picker-input {
        flex-direction: row-reverse;
      }
      .ant-picker-clear,
      .ant-picker-suffix {
        display: none;
        margin-right: 0.5em;
        svg {
          color: #ffffff;
        }
        &:hover {
          svg {
            color: #ffffff;
          }
        }
      }
      .ant-picker-clear {
        display: none;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      background: #15112c;
      border-radius: 10px;
      padding-bottom: 2em;
      margin-bottom: 2em;
      .selectionArea {
        padding: 0.3em;
    }
  }
`;
export const AddPatients = styled("div")`
  background: #15112c;
  border-radius: 20px;
  padding: 1em 1.5em;
  margin-bottom: 1em;
  label {
    color: #ffffff !important;
  }
  textarea,
  .ant-picker {
    border: 1px solid #ffffff;
    color: #ffffff;
    background: #15112c;
    width: 100%;
    border-radius: 40px;
    margin-right: 1em;
    height: 56px;
    input {
      ::placeholder {
        color: #ffffff90;
      }
    }
    .ant-picker-input {
      flex-direction: row-reverse;
    }
    .ant-picker-clear,
    .ant-picker-suffix {
      display: none;
      margin-right: 0.5em;
      svg {
        color: #ffffff;
      }
      &:hover {
        svg {
          color: #ffffff;
        }
      }
    }
    .ant-picker-clear {
      display: none;
    }
  }
  textarea {
    padding: 1em;
    border-radius: 20px;
    &:focus,
    &:hover {
      background: #15112c !important;
    }
    ::placeholder {
      color: #acacac;
    }
  }
  .ant-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #ffffff;
    height: 56px;
    .ant-upload {
      border: 1px solid #ffffff00;
      color: #acacac;
      background: #15112c;
      width: 100%;
      height: 50px;
      img {
        height: 20px;
        margin-right: 1em;
      }
    }
  }
  .ant-upload-list {
    color: white;
    .anticon svg {
      color: white !important;
    }
  }
  .imgUpload {
    .ant-upload {
      border-radius: 40px !important;
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 1em 0.5em;
      .ant-upload {
        .ant-upload {
        }
      }
    }
  }
`;
export const AddBtn = styled("div")`
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  svg {
    cursor: pointer;
    color: #ffffff;
    font-size: 4em;
  }
`;
