import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 1em 0 4em;
  background: #15112c;
  border-radius: 12px;
  position: relative;
  header {
    position: absolute;
    top: 24px;
    right: 10px;
    z-index: 2;

    button {
      width: 116px;
      height: 36px;
      border-radius: 60px;
      background: #3a49d6;
      border: 1px solid #3a49d6;
      span {
        color: #ffffff;
      }
      &:hover {
        background: #3a49d699 !important;
        border: 1px solid #3a49d6;
        span {
          color: #ffffff;
        }
      }
    }
    .todoDataTable {
      td,
      th {
        &:first-child {
          text-align: start !important;
          padding-left: 1.5em !important;
        }
      }
      td {
        &:nth-child(2) {
          padding-left: 1.5em !important;
          text-align: center !important;
        }
        span {
          color: unset !important;
        }
      }
    }
  }
  @media (max-width: 768px) {
    & {
      background: #15112c00;
      header {
        display: flex;
        justify-content: end;
        position: relative;
        top: -10px;
        right: 0px;
      }
    }
  }
`;
export const AddPatients = styled("div")``;
