import React from "react";
import {
  CollapseData,
  CollapseHeader,
  MessageCard,
  Wrapper,
} from "../../user/dashboard/index.style";
import { Col, Collapse, Row } from "antd";
import FacilitiesList from "../../../components/dataTables/FacilitiesList";
import AdminLists from "../../../components/dataTables/AdminLists";

const SuperAdminHome = () => {
  const items = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>Admin List</h1> <span>View all</span>
        </CollapseHeader>
      ),
      children: (
        <div className="mobileTable">
          <AdminLists staffCategory="Nurse Names" />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <CollapseHeader>
          <h1>Facilities List</h1> <span>View all</span>
        </CollapseHeader>
      ),
      children: (
        <div className="mobileTable">
          <FacilitiesList />
        </div>
      ),
    },
  ];

  return (
    <Wrapper>
      <Row gutter={16}>
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <MessageCard>
            <div>
              <h3>Greetings !</h3>
              <h2>{JSON.parse(localStorage.getItem("user")).fullName}</h2>
            </div>
            <p>
              Welcome back to <span>WellChain Super Admin Portal</span>
            </p>
          </MessageCard>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 14 }}>
          <div>
            <CollapseData className="superTab">
              <Collapse items={items} />
            </CollapseData>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default SuperAdminHome;
