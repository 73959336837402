import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { CALENDAR_API } from "../../routes/ApiRoutes";
import { DateContext } from "../../screens/user/patientCalendar";
import { useParams } from "react-router-dom";

const PatientCalendar = ({ showEvent }) => {
  const { patientId } = useParams();
  const { dueDate, updateDate } = useContext(DateContext);

  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [events, setEvents] = useState({});

  useEffect(() => {
    fetchEventData(date.getFullYear(), date.getMonth() + 1);
  }, [date]);

  const fetchEventData = async (year, month) => {
    try {
      const response = await fetch(CALENDAR_API + `/patient/${patientId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          year: year,
          month: month < 10 ? `0${month}` : month.toString(),
        }),
      });
      const data = await response.json();
      if (data.status) {
        setEvents(data.data);
      } else {
        console.error("Failed to fetch events data.");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const daysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return new Date(year, month, 0).getDate();
  };

  const firstDayOfMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month, 1).getDay();
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(date.getFullYear(), date.getMonth() - 1);
    setDate(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1);
    setDate(nextMonth);
  };

  const handleToday = () => {
    setDate(new Date());
  };

  const handleDateClick = (day) => {
    setSelectedDate(day);
    updateDate(day);
  };

  const renderCalendar = () => {
    const days = [];
    const totalDays = daysInMonth(date);
    const firstDay = firstDayOfMonth(date);

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="empty-day"></div>);
    }

    for (let i = 1; i <= totalDays; i++) {
      // Create date as UTC
      const currentDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), i)
      );
      const isToday =
        currentDate.toISOString().split("T")[0] ===
        new Date().toISOString().split("T")[0];
      const dateString = currentDate.toISOString().split("T")[0];
      const eventList = events[dateString] ? events[dateString].tasks : [];
      const reminderList = events[dateString]
        ? events[dateString].reminders
        : [];
      const alertList = events[dateString] ? events[dateString].alerts : [];

      days.push(
        <div
          key={`day-${i}`}
          onClick={() => {
            handleDateClick(currentDate);
            showEvent();
          }}
          className={`day add-event ${isToday ? "active" : ""}`}
          style={{ overflowY: "auto" }}
        >
          <div style={{ textAlign: "right" }}>
            {i}
            <div className="event-button">Add Event</div>
          </div>
          {alertList.map((item, index) => (
            <AllEvents>
              <span className="dot"></span>
              <div key={`event-${index}`}>
                <h6>Alerts</h6>
                <span>{item.alertName}</span>
              </div>
            </AllEvents>
          ))}
          {reminderList.map((item, index) => (
            <AllEvents>
              <span className="dot"></span>
              <div key={`event-${index}`}>
                <h6>Reminder</h6>
                <span>{item.reminderName}</span>
              </div>
            </AllEvents>
          ))}
          {eventList.map((item, index) => (
            <AllEvents>
              <span className="dot"></span>
              <div key={`event-${index}`}>
                <h6>To-Do</h6>
                <span>{item.description}</span>
              </div>
            </AllEvents>
          ))}
        </div>
      );
    }

    return days;
  };

  return (
    <CalendarContainer>
      <div className="mobHeader">
        <p>
          {date.toLocaleString("default", { month: "long", year: "numeric" })}
        </p>
      </div>
      <div className="header">
        <div className="buttons">
          <div>
            <button onClick={handlePrevMonth}>
              <FiChevronLeft />
            </button>
            <button onClick={handleNextMonth}>
              <FiChevronRight />
            </button>
          </div>
          <span onClick={handleToday}>today</span>
        </div>
        <p className="webHeader">
          {date.toLocaleString("default", { month: "long", year: "numeric" })}
        </p>
        <div className="listBtn">
          <span>month</span>
          <span>week</span>
          <span>day</span>
          <span>list</span>
        </div>
      </div>
      <div className="days">
        <div className="day item">Sun</div>
        <div className="day item">Mon</div>
        <div className="day item">Tue</div>
        <div className="day item">Wed</div>
        <div className="day item">Thu</div>
        <div className="day item">Fri</div>
        <div className="day item">Sat</div>
        {renderCalendar()}
      </div>
    </CalendarContainer>
  );
};

export default PatientCalendar;

const CalendarContainer = styled("div")`
  width: 98%;
  border-radius: 5px;
  margin: auto;

  .mobHeader {
    display: none;
    text-align: center;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  p {
    font-size: 1.5em;
  }
  .day.active {
    background-color: #ffdc2826;
  }
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3px;
    button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      border: 1px solid #2c3e50;
      background: #2c3e50;
      cursor: pointer;
      font-size: 1.2em;
      color: #ffffff;
      margin-right: 1px;
      width: 52px;
    }
    span {
      cursor: pointer;
      background: #2c3e50;
      border: 1px solid #2c3e50;
      font-size: 8px;
      width: 52px;
      margin-left: 1.2em;
      padding: 2em 1em 1.2em;
    }
  }
  .add-event {
    position: relative;
    .event-button {
      display: none;
    }
    &:hover {
      .event-button {
        display: block;
        color: white;
        position: absolute;
        text-align: center;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
        cursor: default;
      }
    }
  }

  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
  .empty-day,
  .day {
    height: 120px;
    font-size: 10px;
    text-align: center;
    padding: 0.5em 1em;
    border: 1px solid #ccc;
  }
  .item {
    height: 25px;
    text-align: center;
    padding: 10px 5px 0px !important;
    margin-bottom: 1px;
    line-height: 2.5;
    border: 1px solid #ccc;
  }
  .listBtn {
    display: flex;
    span {
      border: 1px solid #2c3e50;
      background: #2c3e50;
      padding: 2em 1.5em 1.3em;
      cursor: pointer;
      font-size: 8px;
      color: #ffffff;
      margin-right: 2px;
      &:first-child {
        width: 45px;
        border: 1px solid #1a252f;
        border-radius: 5px;
        background: #1a252f;
      }
    }
  }
  @media (max-width: 560px) {
    & {
      .mobHeader {
        display: block;
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .webHeader {
          display: none;
        }
      }
      .buttons {
        button {
          display: inline-flex;
          align-items: center;
          height: 30px;
          cursor: pointer;
          font-size: 1em;
        }
        span {
          width: 30px;
          margin-left: 0.2em;
          padding: 1em;
        }
      }
      .listBtn {
        display: flex;
        span {
          border: 1px solid #2c3e50;
          background: #2c3e50;
          padding: 1em;
          cursor: pointer;
          font-size: 10px;
          color: #ffffff;
          margin-right: 2px;
          &:first-child {
            width: auto;
            border: 1px solid #1a252f;
            background: #1a252f;
          }
        }
      }
      .empty-day,
      .day {
        height: 60px;
        padding: 0.5em;
        font-size: 6px;
      }
      .item {
        height: 15px;
        padding: 0px !important;
      }
      .add-event {
        .event-button {
          font-size: 7px !important;
        }
      }
    }
  }
`;

const AllEvents = styled("div")`
  display: flex;
  justify-content: start;
  text-align: left;
  h6 {
    margin: 0 0;
    line-height: 0.7;
    font-size: 12px;
  }
  div {
    margin: 0 0 0.5em;
    span {
      font-size: 12px;
    }
  }
  .dot {
    width: 8px;
    height: 8px;
    margin-top: 0px;
    margin-right: 4px;
    border-radius: 10px;
    background: #3788d8;
  }
  @media (max-width: 560px) {
    & {
      h6 {
        margin: 0 0;
        line-height: 2;
        font-size: 6px;
      }
      div {
        margin: 0 0 0.2em;
        span {
          font-size: 3px;
          line-height: 2;
          display: block;
        }
      }
      .dot {
        width: 3px;
        height: 3px;
      }
    }
  }
`;
