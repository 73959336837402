import styled from "styled-components";

export const Wrapper = styled("div")`
  background-image: linear-gradient(-45deg, #000000, #000000, #08024a);
  height: 100vh;
  overflow: hidden;
`;
export const Content = styled("div")`
  display: flex;
  height: 97vh;
  overflow-y: scroll;
  .mobileTable {
    background: #15112c;
    padding-bottom: 1em;
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    &::-webkit-scrollbar {
      // width: 9px !important;
      height: 5px !important;
    }
    .rolesDataTable {
      .ant-table-thead {
        th {
          border-bottom: 1px solid #ffffff;
        }
      }
    }
    .staffDataTable {
      .ant-table-thead {
        th {
          background: rgb(21, 17, 44) !important;
          border-bottom: 1px solid #ffffff;
        }
      }
    }
  }
  .adminPageTable {
    .adminPatientListData {
      table {
        .ant-table-thead {
          th {
            background: #24204a !important;
            border-bottom: 1px solid #ffffff90;
          }
        }
      }
    }
  }
  @media (max-width: 1445px) {
    & {
      .staffDataTable {
        overflow-x: auto !important;
        &::-webkit-scrollbar-thumb {
          background: #888 !important;
        }
        &::-webkit-scrollbar {
          // width: 9px !important;
          height: 3px !important;
        }
        table {
          tbody {
            td {
              padding: 16px 4px !important;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    & {
      .mobileTable {
        // border-radius: 12px !important;
        overflow-x: auto;
        table {
          width: 500px !important;
        }
        .adminPatientListData {
          table {
            width: 450px !important;
            .ant-table-thead {
              th {
                background: rgb(21, 17, 44) !important;
                border-bottom: 1px solid #ffffff90 !important;
              }
            }
          }
        }
      }
      .adminPatientListData {
        table {
          width: 550px !important;
          .ant-table-thead {
            th {
              background: rgb(21, 17, 44) !important;
              border-bottom: 1px solid #ffffff90;
            }
          }
        }
      }
      .staffDataTable {
        table {
          .thead {
            th {
              background: rgb(21, 17, 44) !important;
              border-bottom: 1px solid #ffffff90 !important;
            }
          }
        }
      }
    }

    .userManagementListTable {
      border-radius: 12px !important;
      overflow: hidden;
      table {
        width: 100% !important;
      }
    }
  }
`;
export const Container = styled("div")`
  width: 100%;
  // height: 97vh;
  overflow-y: scroll;
  padding: 1em 1em 4em;
  position: relative;
  color: #ffffff;
  @media (max-width: 768px) {
    padding-bottom: 2em !important;
  }
`;
