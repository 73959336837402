import { Col, Collapse, Row } from "antd";
import React from "react";
import {
  CollapseData,
  CollapseHeader,
  MessageCard,
  Wrapper,
} from "../../user/dashboard/index.style";
import StaffDataTable from "../../../components/dataTables/Staffs";
import AdminPatientListData from "../../../components/dataTables/AdminPatients";

const AdminHome = () => {
  const items = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>Doctors List</h1> <span>View all</span>
        </CollapseHeader>
      ),
      children: <StaffDataTable staffCategory="Doctors Names" type="doctors" />,
    },
    {
      key: "2",
      label: (
        <CollapseHeader>
          <h1>Nurses List</h1> <span>View all</span>
        </CollapseHeader>
      ),
      children: <StaffDataTable staffCategory="Nurse Names" type="nurses" />,
    },
    {
      key: "3",
      label: (
        <CollapseHeader>
          <h1>Caregivers List</h1> <span>View all</span>
        </CollapseHeader>
      ),
      children: <StaffDataTable staffCategory="Nurse Names" type="caretaker" />,
    },
  ];

  const taskData = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>patients List</h1> <span>View all</span>
        </CollapseHeader>
      ),
      children: (
        <div className="mobileTable">
          <AdminPatientListData />
        </div>
      ),
    },
  ];
  return (
    <Wrapper>
      {/* <EditTask visible={showEditTaskModal} setVisible={handleCancel} />
      <DeleteMessage
        visible={showDeleteTaskMessage}
        setVisible={handleCancel}
        message="Task"
      /> */}
      <Row gutter={16}>
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <MessageCard>
            <div>
              <h3>Greetings !</h3>
              <h2>Admin</h2>
            </div>
            <p>
              Welcome back to <span>WellChain Admin Portal</span>
            </p>
          </MessageCard>
          <div>
            <CollapseData className="adminTabs">
              <Collapse items={items} />
            </CollapseData>
          </div>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 14 }}>
          <div>
            <CollapseData type="custom" className="webCollapse">
              <Collapse items={taskData} />
            </CollapseData>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default AdminHome;
