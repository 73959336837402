import React, { useEffect, useState } from "react";
import { CustomTable, Status, TableAction } from "./index.style";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
// import DeleteMessage from "../modals/DeleteMessage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  ACTIVE_PATIENT_API,
  DISCHARGE_PATIENT_API,
  GET_DISCHARGE_PATIENT_LIST_API,
} from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import TimestampConverter from "../TimestampConverter";
import PatientAdmitMessage from "../modals/PatientAdmitMessage";
import DeleteMessage from "../modals/DeleteMessage";

const DischargedPatients = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showDeleteTaskMessage, setShowDeleteTaskMessage] = useState(false);
  const [data, setData] = useState([]);
  const [dischargeModal, setDischargeModal] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleShowDelete = () => {
    setShowDeleteTaskMessage(true);
  };

  const handleCancel = () => {
    setShowDeleteTaskMessage(false);
  };
  const navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      dataIndex: "image",
      key: "image",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: (img, name) => (
        <div
          onClick={() => navigate(`/patient-profile/${name?._id}`)}
          style={{ display: "flex", alignItems: "center" }}
        >
          <img src={img.path} alt="" width="100%" height="auto" />
          <p>{name.name}</p>
        </div>
      ),
    },
    {},
    {
      title: "Registration Date",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: (text) => <TimestampConverter timestamp={text} />,
    },
    {
      title: "Calendar",
      dataIndex: "status",
      key: "status",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: (val, record) => (
        <Status
          onClick={() => navigate("/patient-calendar/" + record._id)}
          style={{
            backgroundColor: "#3A49D6",
            color: "#ffffff",
            display: "inline-flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
        >
          <img src="/icons/calendar-new.svg" alt="" />
          <span
            style={{
              color: "#ffffff",
            }}
          >
            View
          </span>
        </Status>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: (val, record) => (
        <TableAction>
          <span
            onClick={() => {
              setActiveModal(true);
            }}
          >
            <img src="/icons/Star.svg" alt="" />
          </span>
          <span onClick={() => navigate(`/edit-patient/${record._id}`)}>
            <img src="/icons/edit.svg" alt="" />
          </span>
          <span onClick={handleShowDelete}>
            <img src="/icons/ben.svg" alt="" />
          </span>
          <span
            onClick={() => {
              setDischargeModal(true);
            }}
          >
            <img src="/icons/amend.png" alt="" />
          </span>
        </TableAction>
      ),
    },
  ];

  const handleDataFetching = () => {
    setIsLoading(true);
    axios
      .get(GET_DISCHARGE_PATIENT_LIST_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRowClick = (record) => {
    setSelectedRowKeys(record);
  };

  const handleDischarge = () => {
    axios
      .get(DISCHARGE_PATIENT_API + selectedRowKeys?._id, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          toast.success("Patient Discharged");
          setDischargeModal(false);
          handleDataFetching();
        } else {
          toast.error(res.data.message);
        }
      });
  };

  const handleActive = () => {
    axios
      .get(ACTIVE_PATIENT_API + selectedRowKeys?._id, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          toast.success("Patient Activated");
          setActiveModal(false);
          handleDataFetching();
        } else {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    handleDataFetching();
  }, []);

  return (
    <>
      <DeleteMessage
        visible={showDeleteTaskMessage}
        setVisible={handleCancel}
        message="Patient"
        updateList={() => handleDataFetching()}
        id={selectedRowKeys?._id}
      />

      <PatientAdmitMessage
        visible={dischargeModal}
        setVisible={() => setDischargeModal(false)}
        message="Discharge"
        onSubmit={handleDischarge}
      />
      <PatientAdmitMessage
        visible={activeModal}
        setVisible={() => setActiveModal(false)}
        message="Active"
        onSubmit={handleActive}
      />
      <div className="todoDataTable dischargedPatientsTable">
        <CustomTable
          loading={isLoading}
          dataSource={data}
          columns={columns}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </div>
    </>
  );
};

export default DischargedPatients;
