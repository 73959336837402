import axios from "axios";
import { SINGLE_PATIENT_DATA_API } from "../routes/ApiRoutes";
import toast from "react-hot-toast";

export const getSinglePatient = async ({
  id,
  user,
  setPatientData,
  documentationForm,
  setDocumentationForm,
  setSelectedItems,
}) => {
  await axios
    .get(SINGLE_PATIENT_DATA_API + id, {
      headers: {
        Authorization: user.token,
      },
    })
    .then((res) => {
      if (res.data.status) {
        setPatientData(res.data.data.patient);
        setDocumentationForm({
          ...documentationForm,
          patient: res.data.data.patient._id,
        });
        setSelectedItems(res.data.data.patient.patientCareTeam);
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((err) => {
      alert(err);
    });
};
