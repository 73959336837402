import React, { useState } from "react";
import { CreateModal } from "./index.style";
import { Button, Col, Form, Row, Upload } from "antd";
import CustomInput from "../../common/CustomInput";
import { FormFooter } from "../../screens/user/dashboard/index.style";
import axios from "axios";
import { DELETE_ADMIN, GET_ALL_FACILITIES } from "../../routes/AdminApiRoutes";
import toast from "react-hot-toast";

const EditAdmin = ({ visible, setVisible, data, updateList }) => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const [isProgress, setIsProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editedValues, setEditedValues] = useState({});

  const handleApiCallResetPassword = (url, payload) => {
    return axios.patch(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
    });
  };

  const handleApiCall = (url, payload) => {
    return axios.patch(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const handleResetPassword = async () => {
    setIsProgress(true);
    try {
      const res = await handleApiCallResetPassword(
        `${DELETE_ADMIN}${data.id}`,
        {
          resetPassword: true,
        }
      );
      res.data.status
        ? toast.success(res.data.message)
        : toast.error(res.data.message);
      setVisible(false);
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setIsProgress(false);
    }
  };

  const handleEdit = async (url, payload) => {
    setIsLoading(true);
    try {
      const res = await handleApiCall(url, payload);
      res.data.status ? updateList() : toast.error(res.data.message);
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    if (
      !editedValues.fullName &&
      !editedValues.email &&
      !editedValues.name &&
      !editedValues.logo
    ) {
      setVisible(false);
      return;
    }
    if (editedValues.fullName || editedValues.email) {
      handleEdit(`${DELETE_ADMIN}${data.id}`, {
        fullName: editedValues?.fullName,
        email: editedValues?.email,
      });
    }
    if (editedValues.name || editedValues.logo) {
      handleEdit(`${GET_ALL_FACILITIES}/${data.facility.id}`, {
        name: editedValues?.name ?? data?.facility?.name,
        logo: editedValues?.logo,
      });
    }
    setVisible(false);
  };

  const handleBeforeUpload = (file) => {
    setEditedValues({ ...editedValues, logo: file });
    return false; // Prevent the file from being uploaded immediately
  };
  // const handleAvatarChange = (info) => {
  //   const file = info.file.originFileObj || info.file;
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setEditedValues({
  //         ...editedValues,
  //         logo: file,
  //       });
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     toast.error("No file found in the upload info.");
  //   }
  // };

  return (
    <CreateModal
      footer={false}
      title={<h3>Edit Admin</h3>}
      open={visible}
      onCancel={() => setVisible(false)}
      width="700px"
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              style={{ cursor: "pointer" }}
              label="Upload Facility Picture"
              name="facility_picture"
            >
              <Upload
                // maxCount={1}
                // onChange={handleAvatarChange}
                // showUploadList={false}
                beforeUpload={handleBeforeUpload}
              >
                <img src="/icons/camera.svg" alt="Upload" />
                Click to Upload
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <CustomInput
              label="Facility Name"
              name="facility_name"
              value={data?.facility?.name}
              onChange={(e) =>
                setEditedValues({ ...editedValues, name: e.target.value })
              }
            />
          </Col>
          <Col span={24}>
            <CustomInput
              label="Admin Name"
              name="admin_name"
              value={data?.fullName}
              onChange={(e) =>
                setEditedValues({ ...editedValues, fullName: e.target.value })
              }
            />
          </Col>
          <Col span={24}>
            <CustomInput
              label="Admin Email"
              name="admin_email"
              value={data?.email}
              onChange={(e) =>
                setEditedValues({ ...editedValues, email: e.target.value })
              }
            />
          </Col>
          <FormFooter style={{ borderTop: "none", width: "100%" }}>
            <Button
              type="primary"
              style={{ marginRight: "10px" }}
              onClick={handleResetPassword}
              disabled={isProgress}
              loading={isProgress}
            >
              Reset Password
            </Button>
            <Button
              htmlType="submit"
              disabled={isLoading}
              loading={isLoading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </FormFooter>
        </Row>
      </Form>
    </CreateModal>
  );
};

export default EditAdmin;
