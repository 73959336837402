import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { MdOutlineArrowForward } from "react-icons/md";
import { Link } from "react-router-dom";
import { GET_PATIENT_BY_CARE_TEAM_API } from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import axios from "axios";

const MyPatients = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [careTeamPatient, setCareTeamPatient] = useState([]);
  const handlePatientListByCareTeam = () => {
    axios
      .get(GET_PATIENT_BY_CARE_TEAM_API, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          setCareTeamPatient(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useLayoutEffect(() => {
    handlePatientListByCareTeam();
  }, []);
  return (
    <Container>
      {careTeamPatient.map((val, i) => (
        <Items key={i}>
          <div>
            <img src={val.image.path} alt="" />
            <div>
              <p>{val.name}</p>
              <div style={{ height: "40px", overflowY: "auto" }}>
                <span>{val.documentation}</span>
              </div>
            </div>
          </div>
          <div>
            <Link to={`/patient-profile/${val._id}`}>
              <MdOutlineArrowForward />
            </Link>
          </div>
        </Items>
      ))}
    </Container>
  );
};

export default MyPatients;

export const Container = styled("div")`
  padding: 2em;
`;
export const Items = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 1em;
  div {
    display: flex;
    div {
      flex-direction: column;
    }
    span {
      color: #ffffff80;
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 1em;
    }
    svg {
      color: #ffffff;
      font-size: 2em;
    }
  }
  p {
    margin: 0;
  }
`;
