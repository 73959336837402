import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 0em 0 1em;
  background: #15112c;
  border-radius: 10px;

  header {
    padding: 1em 2em 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin: 0;
    }
    button {
      width: 116px;
      height: 36px;
      border-radius: 60px;
      background: #3a49d6;
      border: 1px solid #3a49d6;
      span {
        color: #ffffff;
      }
      &:hover {
        background: #3a49d699 !important;
        border: 1px solid #3a49d6;
        span {
          color: #ffffff;
        }
      }
    }
  }

  //   .todoDataTable,
  .alertDataTable {
    td,
    th {
      &:first-child {
        // display: none !important;
      }
    }
    td {
      &:nth-child(2) {
        padding-left: 1.5em !important;
      }
      span {
        color: #ffffff !important;
      }
    }
    .searchItems {
    width: 15%;
    }
  }
  .todoDataTable {
    td,
    th {
      &:first-child {
        text-align: start !important;
        padding-left: 1.5em !important;
      }
    }
    td {
      &:nth-child(2) {
        padding-left: 1.5em !important;
        text-align: center !important;
      }
      span {
        color: unset !important;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 0em 0em;
      background: #15112c00;
      header {
        padding: 1em 0em;
        h2 {
          font-size: 1em;
          font-weightL 400;
        }
        button {
          span {
            font-size: 0.9em;
          }
        }
      }
        .alertDataTable {
    .searchItems {
    width: 45%;
    margin-top: 1em;
    }
  }

      .todoDataTable {
        background: #15112c;
      }
    }
  }
`;
