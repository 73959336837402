import React from "react";
import { Buttons, MessageContainer } from "./index.style";
import { Button } from "antd";

const PatientAdmitMessage = ({
  visible,
  setVisible,
  message,
  onSubmit,
  loading,
}) => {
  return (
    <MessageContainer
      centered
      footer={false}
      open={visible}
      onCancel={setVisible}
    >
      <h3>Sure you want to {message}?</h3>
      <p>Are you sure you want to {message} this Patient?</p>
      <Buttons>
        <Button onClick={setVisible} disabled={loading}>
          No, cancel
        </Button>
        <Button onClick={onSubmit} loading={loading} disabled={loading}>
          Yes, Confirm
        </Button>
      </Buttons>
    </MessageContainer>
  );
};

export default PatientAdmitMessage;
