import React, { useLayoutEffect, useState } from "react";
import {
  Col,
  Row,
  DatePicker,
  Select,
  Form,
  Input,
  TimePicker,
  Button,
  Upload,
} from "antd";
import { InputContainer } from "../../../common/CustomInput";
import {
  AddPatients,
  CardFooter,
  GraphHeader,
  PythoScore,
  Wrapper,
} from "./index.style";
import { BsEyeFill } from "react-icons/bs";
import { FormFooter } from "../dashboard/index.style";
import { FaCaretUp, FaPlus } from "react-icons/fa";
import ReminderCard from "../../../components/cards/Reminder";
import PythoHistory from "../../../components/cards/PythoHistory";
import TodoListCard from "../../../components/cards/TodoList";
import WaitListCard from "../../../components/cards/WaitList";
import DiagnosisCard from "../../../components/cards/Diagnosis";
import { PythoScoreGraph } from "../../../components/Chart";
import { useNavigate, useParams } from "react-router-dom";
import { ShowPdfButton, TitleHeading } from "../../../common/index.style";
import useWindowDimensions from "../../../components/Dimension";
import { useEffect } from "react";
import axios from "axios";
import {
  ADD_DOCUMENTATION_API,
  ADD_MEDICAL_REPORT_API,
  ALERTS_FOR_PATIENT_API,
  GENERATE_REPORT_API,
  GET_ALL_USERS_API,
  GET_DOCUMENTATIONS_API,
  GET_TODO_OF_PATIENT_API,
  GET_WAITLIST_OF_PATIENT_API,
  REMINDERS_FOR_PATIENT_API,
  SINGLE_PATIENT_DATA_API,
  UPDATE_PATIENT_API,
} from "../../../routes/ApiRoutes";
import toast from "react-hot-toast";
import AddMultiAlerts from "../../../components/AddMultiAlerts";
import PatientReportsTable from "../../../components/dataTables/patientReportsTable";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getSinglePatient } from "../../../utils/API_REQUEST";

dayjs.extend(customParseFormat);

const { TextArea } = Input;

const PatientProfile = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const param = useParams();
  const id = param.id;

  const [selectedItems, setSelectedItems] = useState([]);
  const [showDocument, setShowDocument] = useState(false);
  const [patientData, setPatientData] = useState({});
  const [alertsList, setAlertsList] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [todoList, setTodoList] = useState([]);
  const [waitlist, setWaitlist] = useState([]);
  const [documentationForm, setDocumentationForm] = useState({
    patient: "",
    document: "",
    date: "",
    time: "",
  });
  const [userList, setUserList] = useState([]);

  const handleUsersList = () => {
    axios
      .get(GET_ALL_USERS_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setUserList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const format = "HH:mm";
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  useEffect(() => {
    if (width <= 993) {
      setShowDocument(true);
    } else {
      setShowDocument(false);
    }
  }, [width]);

  const handleAlertsData = () => {
    axios
      .get(ALERTS_FOR_PATIENT_API + id, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setAlertsList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleRemindersData = () => {
    axios
      .get(REMINDERS_FOR_PATIENT_API + id, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setReminders(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleGenerateReport = () => {
    axios
      .get(
        GENERATE_REPORT_API +
          id +
          `?startDateStr=${generateForm.startDateStr}&endDateStr=${generateForm.endDateStr}&hours=${generateForm.hours}`,
        {
          headers: {
            Authorization: user.token,
            "Content-Type": "application/pdf",
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `${generateForm.startDateStr}_${generateForm.endDateStr}_${generateForm.hours}hrs_report.pdf`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.parentNode.removeChild(fileLink);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleTodoList = () => {
    axios
      .get(GET_TODO_OF_PATIENT_API + id, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          setTodoList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleWaitList = () => {
    axios
      .get(GET_WAITLIST_OF_PATIENT_API + id, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          setWaitlist(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleDocumentation = (values) => {
    const data = {
      patient: id,
      document: values.document,
      date: dayjs(values.date).format("MM/DD/YYYY"),
      time: dayjs(values.time).format("HH:mm"),
    };
    axios
      .post(ADD_DOCUMENTATION_API, data, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          toast.success("Documentation Added");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const [documentation, setDocumentation] = useState([]);
  const [generateForm, setGenerateForm] = useState({
    startDateStr: "",
    endDateStr: "",
    hours: 8,
  });

  const getDocumentations = () => {
    axios
      .get(
        GET_DOCUMENTATIONS_API +
          id +
          `?startDateStr=${generateForm.startDateStr}&endDateStr=${generateForm.endDateStr}&hours=${generateForm.hours}`,
        {
          headers: { Authorization: user.token },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setDocumentation(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleUpdateCareteam = (val) => {
    const patientCareTeam = val.join(", ");
    axios
      .post(
        UPDATE_PATIENT_API + patientData._id,
        { patientCareTeam },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res.data.status) {
          toast.success("Care Team Selected");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useLayoutEffect(() => {
    getSinglePatient({
      id: param.id,
      user,
      setPatientData,
      documentationForm,
      setDocumentationForm,
      setSelectedItems,
    });

    handleAlertsData();
    handleRemindersData();
    handleTodoList();
    handleWaitList();
    handleUsersList();
  }, []);

  return (
    <Wrapper>
      <Row gutter={16}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <AddPatients>
            <PythoScore>
              <div>
                <span>
                  <p>42</p>
                  <div>
                    <span>+9%</span>
                    <FaCaretUp />
                  </div>
                </span>
                <span>Pytho Score</span>
              </div>
              <p>Record No. {patientData?.patientId?.toUpperCase()}</p>
            </PythoScore>
            <CardFooter>
              <div>
                <p>{patientData.name}</p>
                <span>
                  <span>{patientData.gender} </span>
                  <span>{patientData.age} years </span>
                </span>
              </div>
              <Button
                onClick={() => navigate("/patient-calendar/" + patientData._id)}
              >
                View Calendar
              </Button>
            </CardFooter>
          </AddPatients>
          <PythoHistory />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <ReminderCard list={reminders} titleName="Reminder" />
          <ReminderCard list={alertsList} titleName="Alert" />
        </Col>
      </Row>

      <AddMultiAlerts id={id} />
      <AddPatients>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <TodoListCard value={todoList} />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <WaitListCard value={waitlist} />
          </Col>
        </Row>
      </AddPatients>
      <AddPatients>
        <DiagnosisCard patient={patientData} titleName="Diagnosis" />
      </AddPatients>
      <AddPatients>
        <DiagnosisCard patient={patientData} titleName="Medications" />
      </AddPatients>
      <AddPatients>
        <GraphHeader>
          <h1>Pytho Score Graph</h1>
          <div>
            <Row gutter={{ xs: 6, md: 20 }}>
              <Col span={12}>
                <span>from</span>
                <DatePicker placeholder="mm/dd/yyy" />
              </Col>
              <Col span={12}>
                <span>to</span>
                <DatePicker placeholder="mm/dd/yyy" />
              </Col>
            </Row>
          </div>
        </GraphHeader>
        {showDocument ? (
          <div style={{ height: "300px", width: "100%" }}>
            <PythoScoreGraph />
          </div>
        ) : (
          <div style={{ height: "400px", width: "100%" }}>
            <PythoScoreGraph />
          </div>
        )}
      </AddPatients>
      <div className="selectionArea">
        <h1>Selecting Patient Care Team</h1>
        <Form.Item label="Care Team">
          <Select
            mode="multiple"
            placeholder="Care Team"
            value={selectedItems}
            onChange={(val) => {
              setSelectedItems(val);
              handleUpdateCareteam(val);
            }}
            style={{
              width: "100%",
            }}
            options={userList?.map((item) => ({
              value: item._id,
              label: item.name,
            }))}
          />
        </Form.Item>
      </div>

      <TitleHeading>
        <div className="header">
          <h1>Medical Reports</h1>
          <Upload
            style={{ cursor: "pointer" }}
            accept="application/pdf"
            showUploadList={false}
            customRequest={({ file }) => {
              if (file.type.startsWith("application/pdf")) {
                axios
                  .post(
                    ADD_MEDICAL_REPORT_API + patientData._id,
                    { reports: file },
                    {
                      headers: {
                        Authorization: user.token,
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.status) {
                      toast.success("Medical Report Added");
                      getSinglePatient({
                        id: param.id,
                        user,
                        setPatientData,
                        documentationForm,
                        setDocumentationForm,
                        setSelectedItems,
                      });
                    } else {
                      toast.error(res.data.message);
                    }
                  })
                  .catch((err) => {
                    alert(err);
                  });
              } else {
                toast.error("Only PDF files are allowed.");
              }
            }}
            maxCount={1}
            multiple={false}
          >
            <FaPlus />
            Add Medical Report
          </Upload>
        </div>
        <div className="content">
          <Row gutter={{ md: 30 }}>
            {patientData.reports?.map((val, i) => (
              <Col key={i} xs={{ span: 24 }} md={{ span: 12 }}>
                <ShowPdfButton
                  onClick={() => {
                    window.open(val.path, "_blank");
                  }}
                >
                  {val.name} <BsEyeFill />
                </ShowPdfButton>
              </Col>
            ))}
          </Row>
        </div>
      </TitleHeading>
      <TitleHeading>
        <Form
          layout="vertical"
          onFinish={handleDocumentation}
          initialValues={{
            date: dayjs(),
            time: dayjs(),
          }}
        >
          <div className="header">
            <h1>Enter Documentation</h1>
          </div>
          <div className="content" style={{ paddingTop: "2em" }}>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 14 }}>
                <Row gutter={{ xs: 3, md: 16 }}>
                  <Col span={8}>
                    <Form.Item
                      label="Date"
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: "Please input your date!",
                        },
                      ]}
                    >
                      <DatePicker placeholder="mm/dd/yyy" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Time"
                      name="time"
                      rules={[
                        {
                          required: true,
                          message: "Please input your time!",
                        },
                      ]}
                    >
                      <TimePicker format={format} placeholder="hh/mm" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <InputContainer>
                      {patientData?.patientId && (
                        <Form.Item
                          label="Patient Registration No"
                          name="patient"
                          initialValue={patientData?.patientId}
                        >
                          <Input placeholder="Enter Reg No." disabled />
                        </Form.Item>
                      )}
                    </InputContainer>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Enter Documentation"
                  name="document"
                  rules={[
                    {
                      required: true,
                      message: "Please input your documentation!",
                    },
                  ]}
                >
                  <TextArea
                    style={{
                      height: 120,
                      resize: "none",
                    }}
                    placeholder="Enter Documentation"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <FormFooter className="saveBtn">
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </FormFooter>
        </Form>
      </TitleHeading>
      <TitleHeading>
        <div className="header report">
          <h1>Staffing Report</h1>
          <GraphHeader className="staffingReport">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span> &nbsp;</span>
              <Select
                placeholder="8hr"
                onChange={(val) => {
                  setGenerateForm({
                    ...generateForm,
                    hours: val,
                  });
                }}
                value={generateForm.hours}
              >
                <Select.Option value="1">1 hr</Select.Option>
                <Select.Option value="2">2 hr</Select.Option>
                <Select.Option value="3">3 hr</Select.Option>
                <Select.Option value="4">4 hr</Select.Option>
                <Select.Option value="5">5 hr</Select.Option>
                <Select.Option value="6">6 hr</Select.Option>
                <Select.Option value="7">7 hr</Select.Option>
                <Select.Option value="8">8 hr</Select.Option>
              </Select>
            </div>

            <div>
              <span>from</span>
              <DatePicker
                placeholder="mm/dd/yyy"
                onChange={(val, val2) => {
                  setGenerateForm({
                    ...generateForm,
                    startDateStr: val2,
                  });
                }}
              />
            </div>

            <div>
              <span>to</span>
              <DatePicker
                placeholder="mm/dd/yyy"
                onChange={(val, val2) => {
                  setGenerateForm({
                    ...generateForm,
                    endDateStr: val2,
                  });
                }}
              />
            </div>

            <div>
              <Button
                onClick={
                  documentation.length > 0
                    ? handleGenerateReport
                    : getDocumentations
                }
              >
                {documentation.length > 0
                  ? "Download Report"
                  : "Generate Report"}
              </Button>
            </div>
          </GraphHeader>
        </div>
        {showDocument ? (
          <div className="content" style={{ padding: "0" }}>
            <PatientReportsTable data={documentation} />
          </div>
        ) : (
          <div className="content" style={{ padding: "0" }}>
            <PatientReportsTable data={documentation} />
          </div>
        )}
      </TitleHeading>
    </Wrapper>
  );
};

export default PatientProfile;
