import React, { useLayoutEffect, useState } from "react";
import { CreateModal } from "./index.style";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
} from "antd";
import CustomInput from "../../common/CustomInput";
import { FormFooter } from "../../screens/user/dashboard/index.style";
import { CustomModalTabs } from "../../common/index.style";
import WaitListData from "../waitListData";
import AddTaskComponent from "../AddTaskComponent";
import {
  ADD_RANDOM_TASK_API,
  ADD_REMINDER_API,
  CREATE_ALERT_API,
  GET_ALL_PATIENT_API,
} from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import { format as dateFnsFormat } from "date-fns"; // Renaming the imported format function
import axios from "axios";

const AddEvent = ({ visible, setVisible, date }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [patientList, setPatientList] = useState([]);
  const [dueDate, setDueDate] = useState(null);
  const [dueTime, setDueTime] = useState(null);

  const timeFormat = "HH:mm"; // Renaming the local constant
  const dateChange = (date, dateString) => {
    setDueDate(dateString);
  };

  const onChange = (key) => {
    console.log(key);
  };
  const { TextArea } = Input;

  const handlePatientList = () => {
    axios
      .get(GET_ALL_PATIENT_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setPatientList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useLayoutEffect(() => {
    if (date) {
      const dateStr = new Date(date);
      const formattedDate = dateFnsFormat(dateStr, "dd-MM-yyyy"); // Using the renamed imported function
      setDueDate(formattedDate);
      handlePatientList();
    }
  }, [date]);

  const handleCreateReminder = (value) => {
    axios
      .post(
        ADD_REMINDER_API,
        {
          patient: value.patient_id,
          reminderName: value.reminder_name,
          date: dueDate,
          time: dueTime,
        },
        {
          headers: {
            Authorization: user.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          toast.success("Reminder Added!");
          setVisible(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleAddRandomTask = (value) => {
    axios
      .post(
        ADD_RANDOM_TASK_API,
        {
          description: value.description,
          dueDate,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res.data.status) {
          toast.success("Random Task Added!");
          setVisible(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleCreateAlert = (value) => {
    axios
      .post(
        CREATE_ALERT_API,
        {
          patient: value.patient_id,
          alertName: value.alert_name,
          date: dueDate,
          time: dueTime,
        },
        {
          headers: {
            Authorization: user.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          toast.success("Alert Added!");
          setVisible(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const items = [
    {
      key: "1",
      label: "To do",
      children: (
        <div className="todoForm">
          <AddTaskComponent />
        </div>
      ),
    },
    {
      key: "2",
      label: "Reminder",
      children: (
        <Form layout="vertical" onFinish={handleCreateReminder}>
          <Row gutter={16}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <CustomInput
                label="Reminder Name"
                name="reminder_name"
                placeholder="Reminder Name"
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label="Patient" name="patient_id">
                <Select placeholder="Patient">
                  {patientList.map((val, i) => (
                    <Select.Option value={val._id} key={i}>
                      {val.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Date">
                <DatePicker onChange={dateChange} placeholder="mm/dd/yyy" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Time">
                <TimePicker
                  format={timeFormat} // Using the renamed local constant
                  placeholder="hh/mm"
                  onChange={(val, val2) => {
                    setDueTime(val2);
                  }}
                />
              </Form.Item>
            </Col>
            <FormFooter style={{ borderTop: "none", width: "100%" }}>
              <Button htmlType="submit">Save</Button>
            </FormFooter>
          </Row>
        </Form>
      ),
    },
    {
      key: "3",
      label: "Alerts",
      children: (
        <Form layout="vertical" onFinish={handleCreateAlert}>
          <Row gutter={16}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <CustomInput
                label="Alert Name"
                name="alert_name"
                placeholder="Alert Name"
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label="Patient" name="patient_id">
                <Select placeholder="Patient">
                  {patientList.map((val, i) => (
                    <Select.Option value={val._id} key={i}>
                      {val.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Date">
                <DatePicker onChange={dateChange} placeholder="mm/dd/yyy" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Time">
                <TimePicker
                  format={timeFormat} // Using the renamed local constant
                  placeholder="hh/mm"
                  onChange={(val, val2) => {
                    setDueTime(val2);
                  }}
                />
              </Form.Item>
            </Col>
            <FormFooter style={{ borderTop: "none", width: "100%" }}>
              <Button htmlType="submit">Save</Button>
            </FormFooter>
          </Row>
        </Form>
      ),
    },
    {
      key: "4",
      label: "Waitlist",
      children: <WaitListData />,
    },
    {
      key: "5",
      label: "Random Task",
      children: (
        <Form layout="vertical" onFinish={handleAddRandomTask}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="description" label="Write Random Task">
                <TextArea
                  maxLength={200}
                  style={{
                    height: 170,
                    resize: "none",
                    color: "#ffffff",
                  }}
                  placeholder="Write any Random Task Here"
                />
              </Form.Item>
            </Col>

            <FormFooter style={{ borderTop: "none", width: "100%" }}>
              <Button htmlType="submit">Save</Button>
            </FormFooter>
          </Row>
        </Form>
      ),
    },
  ];
  return (
    <CreateModal
      footer={false}
      title={<h3 className="modalTitle">Events</h3>}
      open={visible}
      onCancel={setVisible}
      width="1000px"
    >
      <div style={{ marginTop: "2.7rem" }}></div>

      <CustomModalTabs
        centered
        tabPosition="left"
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />
    </CreateModal>
  );
};

export default AddEvent;
