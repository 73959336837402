import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserLayout from "../layout/userLayout";
import Home from "../screens/user/dashboard";
import Login from "../screens/user/login";
import Alert from "../screens/user/alert";
import Profile from "../screens/user/profile";
import Reminder from "../screens/user/reminder";
import ToDoLists from "../screens/user/todoLists";
import AddPatient from "../screens/user/addPatient";
import PatientLists from "../screens/user/patientLists";
import CalendarPage from "../screens/user/calendar";
import UserChat from "../screens/user/chats";
import Patient from "../screens/user/activePatient";
import PatientProfile from "../screens/user/patientProfile";
import PatientCalendar from "../screens/user/patientCalendar";
import UserManagement from "../screens/admin/userManagement";

//&&&& Admin Layout

import AdminLayout from "../layout/adminLayout";
import AdminLogin from "../screens/admin/login";
import AdminProfile from "../screens/admin/profile";
import AdminHome from "../screens/admin/adminHome";
import DoctorsList from "../screens/admin/doctors";
import NursesList from "../screens/admin/nurses";
import CaregiversList from "../screens/admin/caregivers";
import AdminPatientList from "../screens/admin/patientList";
import RolesLists from "../screens/admin/rolesList";
import CreateRoles from "../screens/admin/createRoles";
import AllRoles from "../screens/admin/allRoles";

//&&&&  Super Admin Layout

import SuperAdminLayout from "../layout/superAdmin";
import SuperAdminHome from "../screens/superAdmin/adminHome";
import CreateFacility from "../screens/superAdmin/createFacility";
import FacilityList from "../screens/superAdmin/facilityList";
import SuperAdminProfile from "../screens/superAdmin/profile";
import SuperAdminLogin from "../screens/superAdmin/login";

import ProtectedRoutes from "../routing/ProtectedRoutes";
import EditPatient from "../screens/user/editPatient";
import ProtectedSuperAdmin from "../routing/ProtectedSuperAdmin";
import ProtectedAdmin from "../routing/ProtectedAdmin";

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/super/login" element={<SuperAdminLogin />} />
        <Route
          element={
            <ProtectedSuperAdmin>
              <SuperAdminLayout />
            </ProtectedSuperAdmin>
          }
        >
          <Route path="/super/home" element={<SuperAdminHome />} />
          <Route path="/super/create_facility" element={<CreateFacility />} />
          <Route path="/super/facility_list" element={<FacilityList />} />
          <Route path="/super/profile" element={<SuperAdminProfile />} />
        </Route>
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route
          element={
            <ProtectedAdmin>
              <AdminLayout />
            </ProtectedAdmin>
          }
        >
          <Route path="/admin/user_management" element={<UserManagement />} />
          <Route path="/admin/home" element={<AdminHome />} />
          <Route path="/admin/profile" element={<AdminProfile />} />
          <Route path="/doctors" element={<DoctorsList />} />
          <Route path="/nurses" element={<NursesList />} />
          <Route path="/caregivers" element={<CaregiversList />} />
          <Route path="/admin/patient-lists" element={<AdminPatientList />} />
          <Route path="/admin/create_roles" element={<CreateRoles />} />
          <Route path="/admin/create_roles/all_roles" element={<AllRoles />} />
          <Route path="/admin/roles_lists" element={<RolesLists />} />
        </Route>
        <Route path="/" element={<Login />} />
        <Route element={<UserLayout />}>
          <Route
            path="/home"
            element={
              <ProtectedRoutes>
                <Home />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/alert"
            element={
              <ProtectedRoutes>
                <Alert />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes>
                <Profile />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/reminder"
            element={
              <ProtectedRoutes>
                <Reminder />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/add-patient"
            element={
              <ProtectedRoutes>
                <AddPatient />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/edit-patient/:patientId"
            element={
              <ProtectedRoutes>
                <EditPatient />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/patient-lists"
            element={
              <ProtectedRoutes>
                <PatientLists />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/active-patients"
            element={
              <ProtectedRoutes>
                <Patient />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/patient-profile/:id"
            element={
              <ProtectedRoutes>
                <PatientProfile />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/patient-calendar/:patientId"
            element={
              <ProtectedRoutes>
                <PatientCalendar />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/todo"
            element={
              <ProtectedRoutes>
                <ToDoLists />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/calendar"
            element={
              <ProtectedRoutes>
                <CalendarPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/user_chat"
            element={
              <ProtectedRoutes>
                <UserChat />
              </ProtectedRoutes>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
