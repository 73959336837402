import React, { useEffect, useState } from "react";
import {
  Container,
  CustomTabs,
  FormContainer,
  Heading,
  Images,
  LoginForm,
  Overlay,
} from "./index.style";
import { Button, Checkbox, Form } from "antd";
import PasswordInput from "../../../common/PasswordInput";
import CustomInput from "../../../common/CustomInput";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LOGIN_API } from "../../../routes/ApiRoutes";
import toast from "react-hot-toast";

const Login = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberedPassword = localStorage.getItem("rememberedPassword");

    if (rememberedEmail && rememberedPassword) {
      form.setFieldsValue({
        email: rememberedEmail,
        password: rememberedPassword,
        remember: true,
      });
      setRememberMe(true);
    }
  }, []);

  const onFinish = (values) => {
    const { email, password } = values;

    setIsLoading(true);

    axios
      .post(LOGIN_API, {
        email,
        password,
      })
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem("user", JSON.stringify(res.data.data));
          if (rememberMe) {
            localStorage.setItem("rememberedEmail", email);
            localStorage.setItem("rememberedPassword", password);
            setRememberMe(true);
          } else {
            localStorage.removeItem("rememberedEmail");
            localStorage.removeItem("rememberedPassword");
            setRememberMe(false);
          }

          const role = res.data.data.user.role;
          if (role === "admin") {
            navigate("/admin/home");
          } else if (role === "superadmin") {
            navigate("/super/home");
          } else {
            navigate("/home");
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const [form] = Form.useForm();
  return (
    <Container>
      <Images>
        <Overlay></Overlay>
        <img src="/images/auth.jpg" alt="" />
        <Heading>
          <h2>Your Health, Our Priority</h2>
          <p>Caring for You, Every Step</p>
        </Heading>
      </Images>
      <FormContainer>
        {/* <img src="/images/logo.svg" alt="" /> */}
        <p>Welcome to Well Chain</p>
        <LoginForm
          layout="vertical"
          name="basic"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <CustomInput label="Email" name="email" placeholder="Email" />
          <PasswordInput
            label="Password"
            name="password"
            placeholder="Password"
          />
          <Form.Item
            name="remember"
            className="rememberInput"
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) => {
                setRememberMe(e.target.checked);
              }}
            >
              Remember me
            </Checkbox>
            {/* <Link to="/">Forgot Password ?</Link> */}
          </Form.Item>

          <Form.Item className="buttonArea">
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              Login
            </Button>
          </Form.Item>
        </LoginForm>
      </FormContainer>
    </Container>
  );
};

export default Login;
