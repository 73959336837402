import React, { useEffect, useState } from "react";
import { CustomTable } from "./index.style";
import axios from "axios";
import toast from "react-hot-toast";
import { GET_ALL_FACILITIES } from "../../routes/AdminApiRoutes";

const FacilitiesList = () => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const handleList = () => {
    setLoading(true);
    axios
      .get(GET_ALL_FACILITIES, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setDataSource(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  const columns = [
    {
      title: "Facilities",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name - b.name,
    },
    {},
    {
      title: "No of Patients",
      dataIndex: "stats",
      key: "stats",
      render: (data) => <>{data.patients}</>,
    },
    {
      title: "No of CareGivers",
      dataIndex: "stats",
      key: "stats",
      render: (data) => <>{data.caregivers}</>,
    },
  ];

  return (
    <div className="todoDataTable facilitiesListTable">
      <CustomTable
        dataSource={dataSource}
        columns={columns}
        loading={loading}
      />
    </div>
  );
};

export default FacilitiesList;
