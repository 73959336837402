import React, { useEffect } from "react";
import { CustomTable, Status, TableAction } from "./index.style";
import { useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import DeleteMessage from "../modals/DeleteMessage";
import { useNavigate } from "react-router-dom";
import { GET_ACTIVE_PATIENT_LIST_API } from "../../routes/ApiRoutes";
import axios from "axios";
import toast from "react-hot-toast";
import TimestampConverter from "../TimestampConverter";

const PatientActive = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showDeleteTaskMessage, setShowDeleteTaskMessage] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleShowDelete = () => {
    setShowDeleteTaskMessage(true);
  };

  const handleCancel = () => {
    setShowDeleteTaskMessage(false);
  };
  const navigate = useNavigate();

  const columns = [
    {
      title: "Name",
      dataIndex: "image",
      key: "image",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: (img, record) => (
        <div
          onClick={() => navigate(`/patient-profile/${record?._id}`)}
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <img src={img.path} alt="" width="100%" height="auto" />
          <p>{record.name}</p>
        </div>
      ),
    },
    {
      title: "Pytho Score",
      dataIndex: "assignee",
      key: "assignee",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: (assignee) => {
        let color = "#8AFF88";
        if (assignee >= 100) {
          color = "#FF8888";
        }
        return (
          <span
            style={{
              color: color,
            }}
          >
            <span
              span
              style={{
                color: color,
                textAlign: "right",
              }}
            >
              {assignee >= 100 ? "+" : "-"}9%
            </span>
            <div style={{ paddingRight: "0.3em" }}>
              {assignee} {assignee >= 100 ? <FaCaretUp /> : <FaCaretDown />}
            </div>
          </span>
        );
      },
    },
    {
      title: "Registration Date",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: (text) => <TimestampConverter timestamp={text} />,
    },
    {
      title: "Calendar",
      dataIndex: "status",
      key: "status",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: (val, record) => (
        <Status
          onClick={() => navigate("/patient-calendar/" + record._id)}
          style={{
            backgroundColor: "#3A49D6",
            color: "#ffffff",
            display: "inline-flex",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
        >
          <img src="/icons/calendar-new.svg" alt="" />
          <span
            style={{
              color: "#ffffff",
            }}
          >
            View
          </span>
        </Status>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: (val, record) => (
        <TableAction>
          <span>
            <img src="/icons/active_patient.png" alt="" />
          </span>
          <span onClick={() => navigate(`/edit-patient/${record._id}`)}>
            <img src="/icons/edit.svg" alt="" />
          </span>
          <span onClick={handleShowDelete}>
            <img src="/icons/ben.svg" alt="" />
          </span>
        </TableAction>
      ),
    },
  ];

  const handleDataFetching = () => {
    setIsLoading(true);
    axios
      .get(GET_ACTIVE_PATIENT_LIST_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRowClick = (record) => {
    setSelectedRowKeys(record);
  };

  useEffect(() => {
    handleDataFetching();
  }, []);

  return (
    <>
      <DeleteMessage
        visible={showDeleteTaskMessage}
        setVisible={handleCancel}
        message="Patient"
        id={selectedRowKeys?._id}
        updateList={handleDataFetching}
      />
      <div className="todoDataTable patientActiveTable">
        <CustomTable
          loading={isLoading}
          dataSource={data}
          columns={columns}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </div>
    </>
  );
};

export default PatientActive;
