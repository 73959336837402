import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { CustomTable, Priority, Status, TableAction } from "./index.style";
import { ADD_TASK_API } from "../../routes/ApiRoutes";
import DeleteMessage from "../modals/DeleteMessage";
import EditTask from "../modals/EditTask";
import MarkAsCompleted from "../modals/MarkAsCompleted";

const { Search } = Input;

const ExpandableText = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  return (
    <div>
      {isExpanded ? text : `${text.substring(0, maxLength)}... `}
      <span
        style={{ color: "lightblue", cursor: "pointer" }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? "See Less" : "See More"}
      </span>
    </div>
  );
};

const TodoWaitList = ({ showEvent, type }) => {
  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [statusChangeModal, setStatusChangeModal] = useState(false);

  const fetchTasks = async () => {
    try {
      const url =
        type === "waitlist"
          ? ADD_TASK_API + "/waitlist"
          : ADD_TASK_API + "/todo";

      setIsLoading(true);
      const response = await fetch(url, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      });
      const result = await response.json();
      if (result.status) {
        const transformedData = result.data.map((task) => ({
          id: task.id,
          task: (
            <>
              <h3>
                <ExpandableText text={task.description} maxLength={100} />
              </h3>
              <div>
                <span>{task.patientName || "Unknown Patient"}</span>
                <span>{new Date(task.dueDate).toLocaleDateString()}</span>
              </div>
            </>
          ),
          priority:
            task.priority.charAt(0).toUpperCase() + task.priority.slice(1),
          status: task.completed ? "Completed" : "Not Complete",
          assignee: task.assigneeName.trim() || "Unassigned",
        }));
        setDataSource(transformedData);
        setFilteredData(transformedData); // Update filteredData with the fetched data
        setIsLoading(false);
      } else {
        console.error("Failed to fetch tasks: Invalid response status");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch tasks:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const handleRowClick = (record) => {
    setSelectedRowKeys(record);
  };

  const handleSearch = (value) => {
    const filtered = dataSource.filter((item) =>
      item.task.props.children[0].props.children.props.text
        .toLowerCase()
        .includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const columns = [
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        a.task.props.children[0].props.children.props.text.localeCompare(
          b.task.props.children[0].props.children.props.text
        ),
    },
    {
      title: type === "todo" ? "Assignee" : "",
      dataIndex: type === "todo" ? "assignee" : "",
      key: "assignee",
      defaultSortOrder: "descend",
      sorter:
        type === "todo" ? (a, b) => a.assignee.localeCompare(b.assignee) : null,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.priority.localeCompare(b.priority),
      render: (priority) => {
        let color = "#FF8888";
        if (priority === "Medium") {
          color = "#8AFF88";
        }
        if (priority === "Low") {
          color = "#FFEF61";
        }
        return <Priority style={{ background: color }}>{priority}</Priority>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => (
        <Status
          onClick={() => {
            if (status !== "Completed") {
              setStatusChangeModal(true);
            }
          }}
          style={
            status === "Completed"
              ? { backgroundColor: "#ffffff" }
              : { backgroundColor: "#ffffff90", cursor: "pointer" }
          }
        >
          {status}
        </Status>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (id) => (
        <TableAction>
          <span onClick={() => setEditModal(true)}>
            <img src="/icons/edit.svg" alt="edit" />
          </span>
          <span onClick={() => setDeleteModal(true)}>
            <img src="/icons/ben.svg" alt="delete" />
          </span>
          <span onClick={() => showEvent(id)}>
            <img src="/icons/calendar.svg" alt="calendar" />
          </span>
        </TableAction>
      ),
    },
  ];

  return (
    <div className="todoDataTable todoWaitListTable">
      {selectedRowKeys?.id && (
        <>
          <EditTask
            visible={editModal}
            setVisible={() => setEditModal(false)}
            updateList={() => fetchTasks()}
            id={selectedRowKeys?.id}
          />
          <MarkAsCompleted
            visible={statusChangeModal}
            setVisible={() => setStatusChangeModal(false)}
            updateList={() => fetchTasks()}
            id={selectedRowKeys?.id}
          />
        </>
      )}
      <DeleteMessage
        visible={deleteModal}
        setVisible={() => setDeleteModal(false)}
        message="task"
        updateList={() => fetchTasks()}
        id={selectedRowKeys?.id}
      />
      <div style={{ textAlign: "right" }}>
        <Search
          placeholder="Search by task description"
          onSearch={handleSearch}
          allowClear
          style={{ marginBottom: 16, width: "230px" }}
        />
      </div>
      <CustomTable
        className={type === "waitlist" ? "waitList" : ""}
        loading={isLoading}
        dataSource={filteredData}
        columns={columns}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </div>
  );
};

export default TodoWaitList;
