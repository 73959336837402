import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 0.4em 1em 1em;
  height: 100%;
  background: #15112c;
  border-radius: 12px;
  position: relative;
  p {
    font-size: 16px;
    font-weight: 600;
  }
  .ant-checkbox-wrapper {
    span {
      color: #ffffff !important;
    }
  }
  .ant-checkbox-inner {
    background: #15112c !important;
  }
  .ant-form-item-label {
    label {
      color: #ffffff;
    }
  }
  .ant-upload-list-item-name {
    color: #ffff;
  }
  .ant-upload-select {
    color: #ffffff;
    border: 1px solid;
    display: flex;
    width: 100%;
    padding: 1em;
    border-radius: 40px;
    .ant-upload {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
    margin-bottom: 1em;
    gap: 1em;
  }
  button {
    width: 100%;
    height: 54px;
    border-radius: 60px;
    background: #3a49d6;
    border: 1px solid #3a49d6;
    span {
      color: #ffffff;
    }
    &:hover {
      background: #3a49d699 !important;
      border: 1px solid #3a49d6;
      span {
        color: #ffffff;
      }
    }
  }
  .addMoreBtn {
    display: flex;
    justify-content: flex-end;
  }
  .addBtn {
    width: 142px;
    position: absolute;
    right: 1em;
    bottom: 1em;
  }
  .innerBtn {
    height: 43px;
    width: 100%;
  }
  .ant-form-item-required {
    &::before {
      display: none !important;
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 0.5em 1em 1em;
      background: #15112c;
      height: auto;
      border-radius: 12px;
      border: 1px solid #ffffff;
    }
    button {
      width: 100%;
    }
    .addBtn {
      position: static;
      padding-top: 2em;
      float: right;
    }
  }
`;
