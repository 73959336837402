import React from "react";
import { MessageContainer } from "./index.style";
import { Button, Form } from "antd";
import CustomInput from "../../common/CustomInput";
import styled from "styled-components";

const AddPermission = ({ visible, setVisible }) => {
  return (
    <MessageContainer
      centered
      footer={false}
      open={visible}
      onCancel={setVisible}
      width="479px"
    >
      <Wrapper>
        <Form layout="vertical" style={{ paddingTop: "3em" }}>
          <CustomInput
            label="Add New Permission"
            name="permission_name"
            placeholder="Permission Name"
          />
          <Button>Add</Button>
        </Form>
      </Wrapper>
    </MessageContainer>
  );
};

export default AddPermission;

const Wrapper = styled("div")`
  button {
    width: 180px;
    height: 44px;
    border-radius: 33px;
    background: #3a49d6;
    border: 1px solid #3a49d6;
    span {
      color: #ffffff;
    }
    &:hover {
      background: #3a49d699 !important;
      border: 1px solid #ffffff;
      span {
        color: #ffffff;
      }
    }
  }
`;
