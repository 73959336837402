import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CustomTable, Priority, Status } from "../dataTables/index.style";

const TodoListCard = ({ value }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    value?.map((val, i) =>
      setData([
        ...data,
        {
          key: i,
          task: (
            <>
              <div style={{ height: "40px", overflowY: "auto" }}>
                <h3>{val.description}</h3>
              </div>
              <div>
                <span>{new Date(val.dueDate).toLocaleDateString()}</span>
              </div>
            </>
          ),
          priority:
            val.priority.charAt(0).toUpperCase() + val.priority.slice(1),
          status: val.completed ? "Completed" : "Not Complete",
          assignee: val.assigneeName,
        },
      ])
    );
  }, [value]);

  const columns = [
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
    },
    {},
    {
      title: "Assignee",
      dataIndex: "assignee",
      key: "assignee",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority) => {
        let color = "#FF8888";
        if (priority === "Medium") {
          color = "#8AFF88";
        }
        if (priority === "Low") {
          color = "#FFEF61";
        }
        return <Priority style={{ background: color }}>{priority}</Priority>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Status
          style={
            status === "Completed"
              ? { backgroundColor: "#ffffff" }
              : { backgroundColor: "#ffffff90" }
          }
        >
          {status}
        </Status>
      ),
    },
  ];
  return (
    <Container>
      <h1>To-do List</h1>
      <div className="dataContainer">
        <CustomTable dataSource={data} columns={columns} />
      </div>
    </Container>
  );
};

export default TodoListCard;

export const Container = styled("div")`
  background: #15112c;
  border-radius: 10px;
  margin-bottom: 1em;
  h1 {
    margin: 0;
    font-size: 1.2em;
    padding: 1em 0em;
    text-transform: capitalize;
  }
  .header {
    background: #ffffff20;
    padding: 0.6em 2em;
  }
  .dataContainer {
    height: 180px;
    padding-bottom: 2em;
    overflow-y: scroll;
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    .ant-table-tbody {
      tr {
        .ant-table-cell,
        td {
          background: #15112c !important;
          border-bottom: 1px solid #f0f0f000;
          span,
          h3 {
            font-size: 0.9em;
          }
        }
      }
    }
    .ant-table-thead {
      tr {
        th {
          border-bottom: 1px solid #f0f0f000;
        }
      }
    }
  }
  @media (max-width: 768px) {
    & {
      .dataContainer {
        margin-bottom: 1em;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`;
export const Items = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 0.3em 2em;
  h4 {
    margin: 0;
    font-weight: 500;
  }
  p {
    margin: 0;
  }
`;
