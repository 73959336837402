import styled from "styled-components";

export const Wrapper = styled("div")``;
export const CreateNewChat = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 43px;
  height: 43px;
  background: #3a49d6;
  border-radius: 50%;
  svg {
    font-size: 1.8em;
    color: #ffffff;
  }
`;
export const ChatUsers = styled("div")`
  background: #15112c;
  border-radius: 15px;
  padding: 1em 0;
  a {
    position: relative;
    margin-right: 1em;
    span {
      position: absolute;
      top: -5px;
      right: -8px;
      background: rgb(251, 131, 131);
      color: #ffffff;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      border-radius: 50%;
    }
  }
  h2 {
    padding: 0 1em;
    font-size: 1.8em;
    font-weight: 500;
  }
`;
export const Header = styled("div")`
  display: flex;
  justify-content: space-between;
  margin: 0 1em 1em;
  div {
    &:first-child {
      align-items: center;
      display: flex;
    }
  }
`;
export const SearchArea = styled("div")`
  margin: auto 1em;
  .ant-input-search {
    background: #15112c;
  }
  .ant-input-outlined {
    background: #15112c;
    border-color: #15112c;
    box-shadow: none !important;
  }
  .ant-input-wrapper {
    border-radius: 40px;
    background: #15112c;
    color: #ffffff;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row-reverse !important;
    overflow: hidden;
    height: 40px;
    padding-left: 0.5em;
    input {
      border: none;
      font-size: 1em;
      background: #15112c;
      color: #ffffff;
      :focus {
        border-color: #15112c;
        box-shadow: none;
      }
      &::placeholder {
        color: #ffffff90;
      }
    }
    button {
      border: none;
      width: 40px;
      height: 40px;
      background: url(/icons/search-icon.svg);
      background-repeat: no-repeat;
      background-size: 52%;
      background-position: center;
      span {
        // display: none;
        opacity: 0;
      }
      :hover,
      :focus {
        background: url(/icons/search-icon.svg) !important;
        width: 40px !important;
        height: 40px !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: 52% !important;
        border-color: #15112c;
        box-shadow: none;
      }
    }
  }
  .ant-input-group-addon {
    background: #15112c;
    overflow: hidden;
    width: 50px;
    :last-child {
      left: 0px !important;
    }
  }
`;

export const People = styled("div")`
  height: 350px;
  direction: rtl;
  overflow-y: scroll;
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar {
    width: 10px !important;
  }
  .active {
    background: #000000;
  }
  @media (max-width: 768px) {
    & {
      height: 100%;
      direction: unset;
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      &::-webkit-scrollbar {
        width: 0px !important;
      }
    }
  }
`;

export const Chat = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  background: #15112c;
  padding: 0 1em;
  direction: ltr;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    right: 5%;
    left: 5%;
    bottom: 0;
    height: 1px;
    background: #ffffff90;
  }
`;
export const Actions = styled("div")`
  display: inline-flex;
  justify-content: space-between;
  width: 60px;
  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      cursor: pointer;
    }
    &:first-child {
      margin-right: 1em;
      background: rgb(251, 131, 131);
      color: #ffffff;
      width: 20px;
      height: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      border-radius: 50%;
    }
  }
`;
export const UserAvatar = styled("div")`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 1em 0;
  cursor: pointer;
  h6 {
    margin: 0;
    font-weight: 400;
    font-size: 1em;
  }

  span {
    font-size: 0.8em;
    font-weight: 300;
    color: #ffffff99 !important;
  }
  img {
    width: 40px;
    height: 40px;
    margin-right: 1em;
  }
  div {
    text-align: left;
  }
`;

export const MessageArea = styled("div")`
  background: #15112c;
  border-radius: 15px;
  padding: 1em;
  @media (max-width: 768px) {
    & {
      padding: 1em 1em 5em;
      border-radius: 15px;
      background: unset;
      background-image: linear-gradient(-45deg, #000000, #000000, #08024a);
      position: absolute;
      top: 74px;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
`;
export const MessageHeader = styled("div")`
  border-bottom: 1px solid #ffffff90;
  margin-bottom: 1em;
`;
export const ChatContainer = styled("div")`
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: end;
  overflow-y: scroll;
  @media (max-width: 768px) {
    & {
      height: 100%;
      justify-content: end;
    }
  }
`;
export const ReceiveMessage = styled("div")`
  text-align: left;
  margin-bottom: 1.5em;
  p {
    display: inline-block;
    background: #e7e7e7;
    display: inline-block;
    color: #000000;
    margin: 0 0 0.3em;
    padding: 1em 2em;
    border-radius: 50px;
  }
  span {
    display: block;
    font-size: 0.85em;
    color: #e7e7e799;
    font-weight: 300;
    padding-left: 2em;
  }
`;
export const SendMessage = styled("div")`
  text-align: right;
  margin-bottom: 1.5em;
  padding-right: 1em;
  span {
    padding-right: 2em;
    display: block;
    color: #e7e7e799;
    font-weight: 300;
    font-size: 0.85em;
  }
  .seeDot {
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      width: 10px;
      height: 10px;
      right: -12px;
      bottom: -5px;
      background: #436ddd;
      border-radius: 50%;
    }
  }
  p {
    display: inline-block;
    background: #436ddd;
    margin: 0 0 0.3em;
    padding: 1em 2em;
    border-radius: 50px;
  }
`;
export const TypeMessage = styled("div")`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1em;
  input {
    border-radius: 50px;
    height: 55px;
    &::placeholder {
      color: #000000 !important;
      font-family: "Poppins", sans-serif !important;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 60px;
    height: 55px;
    background: #3a49d6;
    border-radius: 50%;
    border: none;
    &:hover {
      border: none;
      background: #3a49d6 !important;
    }
  }
  @media (max-width: 768px) {
    & {
      position: absolute;
      background: #000000;
      bottom: 0em;
      padding-top: 0.3em;
      padding-bottom: 1em;
      right: 3%;
      left: 3%;
    }
  }
`;
