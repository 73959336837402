import React, { useState } from "react";
import { Wrapper } from "./index.style";
import { Button, Col, Form, Row, Upload } from "antd";
import CustomInput from "../../../common/CustomInput";
import { GET_ALL_FACILITIES } from "../../../routes/AdminApiRoutes";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CreateFacility = () => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("user")).token;

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleSubmit = async (values) => {
    try {
      const data = {
        name: values.facility_name,
        logo: file,
        admin: JSON.stringify({
          name: values.admin_name,
          email: values.admin_email,
        }),
      };

      setLoading(true);
      const result = await axios.post(GET_ALL_FACILITIES, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (result.data.status) {
        navigate("/super/facility_list");
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || error.message || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBeforeUpload = (file) => {
    setFile(file);
    return false; // Prevent the file from being uploaded immediately
  };

  return (
    <Wrapper>
      <p>ADD FACILITY</p>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Row
          gutter={{
            xs: 6,
            md: 12,
          }}
        >
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <CustomInput
              label="Facility Name"
              name="facility_name"
              placeholder="Facility Name"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label="Upload Facility Picture"
              name="facility_image"
              style={{ cursor: "pointer" }}
              rules={[
                {
                  required: true,
                  message: "Facility logo is required!",
                },
              ]}
            >
              <Upload beforeUpload={handleBeforeUpload} maxCount={1}>
                <img src="/icons/camera.svg" alt="" />
                Click to Upload
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <CustomInput
              label="Admin Name"
              name="admin_name"
              placeholder="Write Admin Name Here"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <CustomInput
              label="Admin Email"
              name="admin_email"
              placeholder="Write Admin Email Here"
            />
          </Col>
        </Row>
        <div className="addBtn">
          <Button
            className="innerBtn"
            type="primary"
            htmlType="submit"
            disabled={loading}
            loading={loading}
          >
            Add
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
};

export default CreateFacility;
