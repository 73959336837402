const BaseUrl = "https://gapcure.teknovation.io/";

// Login Api
const LOGIN_API = BaseUrl + "auth/login";

// Users > Get all users api
const GET_ALL_USERS_API = BaseUrl + "user/all";

// Patient > Add Patient
const ADD_PATIENT_API = BaseUrl + "patient/add";

// patient > edit patient
const EDIT_PATIENT_API = BaseUrl + "patient/update/"; // id

// Patient > All Patients List
const GET_ALL_PATIENT_API = BaseUrl + "patient/all";

// Patient > Active Patient List
const GET_ACTIVE_PATIENT_LIST_API = BaseUrl + "patient/status/active";

// Patient > Discharge Patient List
const GET_DISCHARGE_PATIENT_LIST_API = BaseUrl + "patient/status/discharged";

// Patient > Delete Patient
const DELETE_PATIENT_API = BaseUrl + "patient/delete/"; // id

// Patient > Get Patient by care team
const GET_PATIENT_BY_CARE_TEAM_API = BaseUrl + "patient";

// Reminder > Add Reminder
const ADD_REMINDER_API = BaseUrl + "reminder";

// Reminder > Get All Reminders
const GET_ALL_REMINDERS_API = BaseUrl + "reminder";

// Reminder > Delete Reminder
const DELETE_REMINDER_API = BaseUrl + "reminder/delete/"; // id

// Reminder > Update Reminder
const UPDATE_REMINDER_API = BaseUrl + "reminder/"; // id

// Alerts > Get All Alerts
const GET_ALL_ALERTS_API = BaseUrl + "alert";

// Alerts > Create Alert
const CREATE_ALERT_API = BaseUrl + "alert";

// Alerts > Delete Alert
const DELETE_ALERT_API = BaseUrl + "alert/delete/"; // id

// Alerts > Update Alert
const UPDATE_ALERT_API = BaseUrl + "alert/"; // id

// Task > Add Task
const ADD_TASK_API = BaseUrl + "task";

// Waitlist > Get Waitlist
const GET_WAITLIST_API = BaseUrl + "task";

// Task > Due date tasks
const GET_DUE_DATE_TASK_API = BaseUrl + "task/dueToday";

// Task > Delete task
const DELETE_TASK_API = BaseUrl + "task/delete/"; // id

// Profile > edit user data
const EDIT_USER_API = BaseUrl + "user/updateMe";

// Logout
const LOGOUT_API = BaseUrl + "auth/logout";

// Login user data
const GET_PROFILE_DATA_API = BaseUrl + "user/getProfile";

// Todo > get 1 task data
const GET_ONE_TASK_DATA_API = BaseUrl + "task/"; // id

// CALENDAR
const CALENDAR_API = BaseUrl + "search/calender";

// search patient
const SEARCH_PATIENT_API = BaseUrl + "search?query="; // i.e tallha

// task > mark as completed
const MARK_AS_COMPLETED_API = BaseUrl + "task/complete/"; // id

// single patient data api
const SINGLE_PATIENT_DATA_API = BaseUrl + "patient/"; // id

// alerts > get alerts against patient
const ALERTS_FOR_PATIENT_API = BaseUrl + "alert/patient/"; // id

// reminders > get reminders against patients
const REMINDERS_FOR_PATIENT_API = BaseUrl + "reminder/patient/"; // id

// add multiple alerts
const ADD_MULTIPLE_ALERTS_API = BaseUrl + "alert/many";

// add random task
const ADD_RANDOM_TASK_API = BaseUrl + "task/random";

// get todo list against patient
const GET_TODO_OF_PATIENT_API = BaseUrl + "task/patient/todo/"; // id

// get waitlist list against patient
const GET_WAITLIST_OF_PATIENT_API = BaseUrl + "task/patient/waitlist/";

// add multiple reminders
const ADD_MULTIPLE_REMINDERS_API = BaseUrl + "reminder/many";

// discharge patient
const DISCHARGE_PATIENT_API = BaseUrl + "patient/discharge/"; // id

// active patient
const ACTIVE_PATIENT_API = BaseUrl + "patient/admit/"; // id

// Add documentation
const ADD_DOCUMENTATION_API = BaseUrl + "documentation";

// get documentations
const GET_DOCUMENTATIONS_API = BaseUrl + "documentation/patient/"; // id

// add medical report again patient
const ADD_MEDICAL_REPORT_API = BaseUrl + "patient/addReport/"; // id

// update patient
const UPDATE_PATIENT_API = BaseUrl + "patient/update/"; // id

// get all notifications
const GET_ALL_NOTIFICATIONS_API = BaseUrl + "notifications";

// get all chats
const GET_ALL_CHATS_API = BaseUrl + "message/chats";

// get messages
const GET_MESSAGES_API = BaseUrl + "message/user/"; // id

// notification + messages count
const GET_COUNTS_API = BaseUrl + "notifications/count";

// delete chat
const DELETE_CHAT_API = BaseUrl + "message/deleteChat/"; // id

// mark all as read - notification
const MARK_ALL_AS_READ_API = BaseUrl + "notifications/mark-all-as-read";

// mark all messages as read
const MARK_ALL_MESSAGES_AS_READ_API = BaseUrl + "message/mark-as-read/"; // id

// send message
const SEND_MESSAGE_API = BaseUrl + "message";

// search chat
const SEARCH_CHATS_API = BaseUrl + "search/chats?query="; // query

// generate report
const GENERATE_REPORT_API = BaseUrl + "documentation/exportReport/"; // id

// admin > get all users
const GET_USERS_API = BaseUrl + "admin";

// admin > get one user
const GET_ONE_USER_API = BaseUrl + "admin/user/"; // id

// admin > add user
const ADD_USER_API = BaseUrl + "admin/createUser";

// admin > edit user
const EDIT_ADMIN_USER_API = BaseUrl + "admin/updateUser/"; // id

// admin > delete user
const DELETE_USER_API = BaseUrl + "admin/deleteUser/"; // id

// medications user
const GET_MEDICATIONS_API = BaseUrl + "medications";

// diagnosis user
const GET_DIAGNOSIS_API = BaseUrl + "diagnosis";

export {
  GET_ONE_USER_API,
  DELETE_USER_API,
  ADD_USER_API,
  GET_USERS_API,
  EDIT_ADMIN_USER_API,
  GENERATE_REPORT_API,
  EDIT_PATIENT_API,
  SEARCH_CHATS_API,
  MARK_ALL_MESSAGES_AS_READ_API,
  SEND_MESSAGE_API,
  MARK_ALL_AS_READ_API,
  DELETE_CHAT_API,
  GET_COUNTS_API,
  GET_MESSAGES_API,
  GET_ALL_CHATS_API,
  UPDATE_PATIENT_API,
  GET_ALL_NOTIFICATIONS_API,
  ADD_MEDICAL_REPORT_API,
  GET_DOCUMENTATIONS_API,
  ADD_DOCUMENTATION_API,
  ACTIVE_PATIENT_API,
  DISCHARGE_PATIENT_API,
  ADD_MULTIPLE_REMINDERS_API,
  GET_WAITLIST_OF_PATIENT_API,
  GET_TODO_OF_PATIENT_API,
  ADD_RANDOM_TASK_API,
  ADD_MULTIPLE_ALERTS_API,
  CALENDAR_API,
  LOGIN_API,
  GET_ALL_USERS_API,
  ADD_PATIENT_API,
  GET_ALL_PATIENT_API,
  DELETE_PATIENT_API,
  GET_ACTIVE_PATIENT_LIST_API,
  GET_DISCHARGE_PATIENT_LIST_API,
  ADD_REMINDER_API,
  GET_ALL_REMINDERS_API,
  DELETE_REMINDER_API,
  UPDATE_REMINDER_API,
  GET_ALL_ALERTS_API,
  CREATE_ALERT_API,
  DELETE_ALERT_API,
  ADD_TASK_API,
  GET_PATIENT_BY_CARE_TEAM_API,
  GET_WAITLIST_API,
  GET_DUE_DATE_TASK_API,
  DELETE_TASK_API,
  EDIT_USER_API,
  LOGOUT_API,
  UPDATE_ALERT_API,
  GET_PROFILE_DATA_API,
  GET_ONE_TASK_DATA_API,
  SEARCH_PATIENT_API,
  MARK_AS_COMPLETED_API,
  SINGLE_PATIENT_DATA_API,
  ALERTS_FOR_PATIENT_API,
  REMINDERS_FOR_PATIENT_API,
  GET_DIAGNOSIS_API,
  GET_MEDICATIONS_API,
};
