import React from "react";
import {
  CollapseData,
  CollapseHeader,
  MessageCard,
  Wrapper,
} from "./index.style";
import { Col, Collapse, Row } from "antd";
import AlertsDates from "../../../components/alertsDates";
import MyPatients from "../../../components/patients";
import WaitListData from "../../../components/waitListData";
import TodoList from "../../../components/dataTables/TodoList";

import AddTaskComponent from "../../../components/AddTaskComponent";

const Home = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const taskData = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>Today To-do List</h1> <span>View all</span>
        </CollapseHeader>
      ),
      children: (
        <div className="mobileTable">
          <TodoList />
        </div>
      ),
    },
  ];
  const items = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>Alerts</h1> <span>View all</span>
        </CollapseHeader>
      ),
      children: <AlertsDates type="alert" />,
    },
    {
      key: "2",
      label: (
        <CollapseHeader>
          <h1>My Patients</h1> <span>View all</span>
        </CollapseHeader>
      ),
      children: <MyPatients />,
    },
    {
      key: "3",
      label: (
        <CollapseHeader>
          <h1>Reminder</h1> <span>View all</span>
        </CollapseHeader>
      ),
      children: <AlertsDates type="reminder" />,
    },
    {
      key: "4",
      label: (
        <CollapseHeader>
          <h1>WaitList</h1> <span>View all</span>
        </CollapseHeader>
      ),
      children: <WaitListData />,
    },
  ];
  return (
    <Wrapper>
      <Row gutter={16}>
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <MessageCard>
            <div>
              <h3>Greetings !</h3>
              <h2>{user?.user?.name}</h2>
            </div>
            <p>
              Welcome back to <span>GapCure</span>
            </p>
          </MessageCard>
          <div className="showData">
            <CollapseData>
              <Collapse items={items} />
            </CollapseData>
          </div>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 14 }}>
          <AddTaskComponent />
          <CollapseData className="taskData">
            <Collapse items={taskData} />
          </CollapseData>
          <div className="hideData">
            <CollapseData>
              <Collapse items={items} />
            </CollapseData>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Home;
