import React, { useEffect } from "react";
import { CustomTable } from "./index.style";
import { useState } from "react";
import DeleteMessage from "../modals/DeleteMessage";
import axios from "axios";
import { GET_USERS_API } from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import { Space } from "antd";

const StaffDataTable = ({ staffCategory, type }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [showDeleteTaskMessage, setShowDeleteTaskMessage] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setShowDeleteTaskMessage(false);
  };

  const handleDoctorList = () => {
    setLoading(true);
    axios
      .get(GET_USERS_API, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((res) => {
        if (res.data.status) {
          if (type === "doctors") {
            const doctors = res.data.data.filter(
              (user) => user.designation === "doctor"
            );
            setUsers(doctors);
          } else if (type === "nurses") {
            const nurses = res.data.data.filter(
              (user) => user.designation === "nurse"
            );
            setUsers(nurses);
          } else if (type === "caretaker") {
            const caretaker = res.data.data.filter(
              (user) => user.designation === "caretaker"
            );
            setUsers(caretaker);
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleDoctorList();
  }, []);

  const columns = [
    {
      title: `${staffCategory}`,
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={record.profilePic} alt="" />
          <span style={{ color: "#ffffff" }}>
            {record.firstName}&nbsp;{record.lastName}
          </span>
        </div>
      ),
    },

    {
      title: "Contact",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "No of Patients",
      dataIndex: "patients",
      key: "patients",
    },
  ];

  return (
    <>
      <DeleteMessage
        visible={showDeleteTaskMessage}
        setVisible={handleCancel}
        message="Patient"
      />
      <div className="staffDataTable">
        <CustomTable dataSource={users} columns={columns} loading={loading} />
      </div>
    </>
  );
};

export default StaffDataTable;
