import React, { useState } from "react";
import { CustomTable } from "./index.style";

const ExpandableText = ({ text }) => {
  const MAX_LENGTH = 150;
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  if (text.length <= MAX_LENGTH) {
    return <span>{text}</span>;
  }
  return (
    <span>
      {expanded ? text : `${text.substring(0, MAX_LENGTH)}... `}
      <span
        style={{ color: "lightblue", cursor: "pointer" }}
        onClick={toggleExpanded}
      >
        {expanded ? "See less" : "See more"}
      </span>
    </span>
  );
};

const PatientReportsTable = ({ data }) => {
  const columns = [
    {
      title: "Documents",
      dataIndex: "document",
      key: "document",
      width: "70%",
      sorter: (a, b) => a.document.localeCompare(b.document),
      render: (text) => <ExpandableText text={text} />,
    },
    {
      title: "Date & Time",
      dataIndex: "designation",
      key: "designation",
      render: (_, record) => {
        return (
          <div>
            {new Date(record.date).toLocaleDateString()} & {record.time}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mobileTable userManagementListTable">
        <CustomTable dataSource={data} columns={columns} />
      </div>
    </>
  );
};

export default PatientReportsTable;
