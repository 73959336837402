import styled from "styled-components";

export const Wrapper = styled("div")`
  h1 {
    color: #ffffff !important;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.3em;
  }

  label {
    color: #ffffff !important;
  }
  .selectionArea {
    padding: 1.5em;
    .ant-select-selector {
      border: 1px solid #ffffff !important;
      background: #15112c !important;
      border-radius: 50px;
      width: 100%;
      // height: 56px;
      padding: 1em;
      input {
        &:placeholder {
          color: #ffffff90 !important;
        }
      }
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          background: #3a49d6 !important;
          border-radius: 20px;
        }
        .anticon-close {
          svg {
            color: #ffffff;
          }
        }
      }
    }
    .ant-select-arrow {
      right: 2%;
      background-size: contain;
      background-image: url(./icons/arrow-down.svg);
      width: 20px;
      background-repeat: no-repeat;
      .anticon {
        svg {
          display: none;
          color: #ffffff !important;
        }
      }
      .anticon-search {
        display: none;
        transform: rotateY(0deg) !important;
        svg {
          color: #ffffff !important;
          transform: rotateY(0deg) !important;
        }
      }
    }
    textarea,
    .ant-picker {
      border: 1px solid #ffffff;
      color: #ffffff;
      background: #15112c;
      width: 100%;
      border-radius: 40px;
      margin-right: 1em;
      height: 56px;
      input {
        ::placeholder {
          color: #ffffff90;
        }
      }
      .ant-picker-input {
        flex-direction: row-reverse;
      }
      .ant-picker-clear,
      .ant-picker-suffix {
        display: none;
        margin-right: 0.5em;
        svg {
          color: #ffffff;
        }
        &:hover {
          svg {
            color: #ffffff;
          }
        }
      }
      .ant-picker-clear {
        display: none;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      h1 {
        font-size: 1em;
      }
      .selectionArea {
        padding: 0.5em;
      }
    }
  }
`;
export const AddPatients = styled("div")`
  background: #15112c;
  border-radius: 10px;
  padding: 1em 1.5em;
  margin-bottom: 1em;
  position: relative;
  label {
    color: #ffffff !important;
  }
  .chartContainer {
    width: 100%
    height: 400px;
  }
  .cardFooter {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 1em;
  }

  button {
    background: #3a49d6 !important;
    border: 1px solid #3a49d6 !important;
    border-radius: 20px;
    padding: 0.2em 2em;
    span {
      color: #ffffff !important;
    }
  }
  svg {
    g {
      text {
        fill: #ffffff !important;
        font-size: 1em !important;
      }
    }
  }
  textarea,
  .ant-picker {
    border: 1px solid #ffffff;
    color: #ffffff;
    background: #15112c;
    width: 100%;
    border-radius: 40px;
    margin-right: 1em;
    height: 56px;
    input {
      ::placeholder {
        color: #ffffff90;
      }
    }
    .ant-picker-input {
      flex-direction: row-reverse;
    }
    .ant-picker-clear,
    .ant-picker-suffix {
      display: none;
      margin-right: 0.5em;
      svg {
        color: #ffffff;
      }
      &:hover {
        svg {
          color: #ffffff;
        }
      }
    }
    .ant-picker-clear {
      display: none;
    }
  }
  textarea {
    padding: 1em;
    border-radius: 20px;
    &:focus,
    &:hover {
      background: #15112c !important;
    }
    ::placeholder {
      color: #ffffff90;
    }
  }
  .ant-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #ffffff;
    height: 56px;
    .ant-upload {
      border: 1px solid #ffffff00;
      color: #ffffff;
      background: #15112c;
      width: 100%;
      height: 50px;
      img {
        height: 20px;
        margin-right: 1em;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 1em 0.5em;
      svg {
        g {
          text {
            fill: #ffffff !important;
            font-size: 0.7em !important;
          }
        }
      }
    }
  }
`;
export const AddBtn = styled("div")`
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  position: ${(props) => props.positionStyle};
  right: 1%;
  bottom: 25%;
  svg {
    cursor: pointer;
    color: #ffffff;
    font-size: 4em;
  }
  @media (max-width: 768px) {
    & {
      right: 3%;
      bottom: unset !important;
      top: 0.8em;
      svg {
        font-size: 2em;
      }
    }
  }
`;
export const PythoScore = styled("div")`
  display: flex;
  justify-content: space-between;
  div {
    p {
      font-size: 2.5em;
      margin: 0 0.3em 0 0;
    }
    color: #ff8888;
    span {
      display: flex;
      color: #ffffff;
      justify-content: start;
      align-items: center;
      &:first-child {
        color: #ff8888;
      }
    }
  }
  svg {
    cursor: pointer;
    color: #ff8888;

    font-size: 1.3em;
  }
`;

export const GraphHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  padding-left: 1.6em;
  gap: 1em;
  h1 {
    color: #ffffff !important;
    text-transform: capitalize !important;
  }
  .ant-select {
    color: #ffffff;
    background: #15112c;
    width: 100px !important;
    height: 50px !important;
    border-radius: 10px !important;
    margin-left: auto !important;
    input {
      ::placeholder {
        color: #ffffff;
      }
    }
  }
  .ant-select-selection-placeholder {
    color: #ffffff70;
  }
  .ant-select-selector {
    color: #ffffff;
    background: #15112c !important;
    border-radius: 10px !important;
  }
  .ant-select-arrow {
    transform: rotateX(45deg);
    svg {
      color: #ffffff;
      font-size: 1.1em;
    }
  }
  .ant-select-selection-item {
    svg {
      display: none;
    }
  }
  div {
    width: 450px;
    span {
      text-transform: capitalize !important;
      color: #ffffff;
      display: block;
    }
    .ant-picker {
      width: 100%;
      border-radius: 10px;
      margin-right: 0em;
      height: 50px;
    }
  }
  @media (max-width: 768px) {
    & {
      width: 100%;
      justify-content: space-between;
      padding-left: 0em;
      gap: 0.5em;
      h1 {
        font-size: 0.9em;
      }
      div {
        width: 240px;
        span {
          text-transform: capitalize !important;
        }
        .ant-picker {
          border-radius: 10px;
          height: 40px;
        }
        .ant-select {
          width: 100% !important;
          height: 40px !important;
        }
      }
    }
  }
`;
export const CardFooter = styled("div")`
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 1em;
  p {
    font-size: 1.5em;
  }
  span {
    color: #ffffff90;
  }
  button {
    cursor: pointer;
  }
`;
