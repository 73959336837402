import React from "react";
import { Wrapper } from "./index.style";
import FacilitiesList from "../../../components/dataTables/FacilitiesList";
import { CollapseData, CollapseHeader } from "../../user/dashboard/index.style";
import { Collapse } from "antd";

const FacilityList = () => {
  const items = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>FACILITIES LIST</h1> <span>view all</span>
        </CollapseHeader>
      ),
      children: <div className="mobileTable">
      <FacilitiesList />
    </div> ,
    },
  ];
  return (
    <Wrapper>
      <div className="webView">

      <header>
        <p>FACILITIES LIST</p>
        {/* <div className='btnContainer'>
            <Button onClick={() => setOpenInviteModal(true)} className='inviteBtn'>Invite User</Button>
            <Button onClick={handleAddUser}>Add User</Button>
          </div> */}
      </header>
  
        <FacilitiesList />
      </div>
     
      <div className="mobileView">
      <div className="tabContainer">
      <CollapseData>
        <Collapse items={items} defaultActiveKey={1} />
      </CollapseData>
    </div>
      </div>
      {/* <UserManagementList handleUpdate={handleUpdateUser} handleDelete={() => setOpenDeleteModal(true)} /> */}
    </Wrapper>
  );
};

export default FacilityList;
