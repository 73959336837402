import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Wrapper, Content, Container, MobileSidebar } from "./index.style";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import useWindowDimensions from "../../components/Dimension";
import MobileHeader from "../../components/mobileHeader";
import { Overlay } from "../../common/index.style";

const SuperAdminLayout = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const { width, height } = useWindowDimensions();
  console.log(width, height);
  useEffect(() => {
    if (width <= 993) {
      setShowSidebar(true);
      setShowHeader(true);
      setShowOverlay(true);
    } else {
      setShowOverlay(false);
      setShowSidebar(false);
      setShowHeader(false);
    }
  }, [width]);
  const handleCloseSideNav = () => setShowSidebar(!showSidebar);
  const handelCloseSidebar = () => {
    setShowSidebar(false);
  };
  const handelCloseMobileSidebar = () => {
    if (width <= 993) {
      setShowSidebar(true);
    }
  };
  return (
    <Wrapper>
      {showHeader ? (
        <MobileHeader
          onShow={handleCloseSideNav}
          visible={showSidebar}
          showSidebar={setShowSidebar}
          profileAddress="/super/profile"
          userType="super"
        />
      ) : (
        <Header profileAddress="/super/profile" userType="super" />
      )}
      <Content>
        {showSidebar ? (
          ""
        ) : (
          <Sidebar
            userType="super"
            visible={handelCloseMobileSidebar}
            setVisible={handelCloseSidebar}
          />
        )}

        <Container>
          {showSidebar ? (
            ""
          ) : (
            <>
              {" "}
              {showOverlay ? (
                <Overlay onClick={handelCloseMobileSidebar}></Overlay>
              ) : (
                ""
              )}
            </>
          )}
          <Outlet />
        </Container>
      </Content>
    </Wrapper>
  );
};

export default SuperAdminLayout;
