import React, { useLayoutEffect, useState } from "react";
import AlertsData from "../../../components/dataTables/AlertsData";
import { Wrapper } from "./index.style";
import { Button } from "antd";
import CreateAlert from "../../../components/modals/CreateAlert";
import { GET_ALL_ALERTS_API } from "../../../routes/ApiRoutes";
import toast from "react-hot-toast";
import axios from "axios";

const Alert = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [data, setData] = useState([]);

  const handleShowAlert = () => {
    setShowAlertModal(true);
  };

  const handleCancel = () => {
    setShowAlertModal(false);
  };

  const handleDataFetching = () => {
    axios
      .get(GET_ALL_ALERTS_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useLayoutEffect(() => {
    handleDataFetching();
  }, []);
  return (
    <>
      {showAlertModal && (
        <CreateAlert
          visible={showAlertModal}
          setVisible={handleCancel}
          titleName="Alert"
          updateList={() => {
            handleDataFetching();
          }}
        />
      )}
      <Wrapper>
        <header>
          <h2>Alerts</h2>
          <Button onClick={handleShowAlert}>Create</Button>
        </header>
        <div className="mobileTable">
          <AlertsData
            titleName="Alert Name"
            data={data}
            fetchFunc={() => {
              handleDataFetching();
            }}
          />
        </div>
      </Wrapper>
    </>
  );
};

export default Alert;
