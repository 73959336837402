import React from "react";
import { Wrapper } from "../activePatient/index.style";
import { CustomTabs } from "../../../common/index.style";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import PatientListData from "../../../components/dataTables/PatientList";
import PatientActive from "../../../components/dataTables/PatientActive";
import DischargedPatients from "../../../components/dataTables/DischargedPatients";

const PatientLists = () => {
  const navigate = useNavigate();
  const onChange = (key) => {
    console.log(key);
  };

  const tabs_items = [
    {
      key: "1",
      label: "Master List",
      children: (
        <div className="mobileTable">
          <PatientListData />
        </div>
      ),
    },
    {
      key: "2",
      label: "Active patients",
      children: (
        <div className="mobileTable">
          <PatientActive />
        </div>
      ),
    },
    {
      key: "3",
      label: "Discharged PAtients",
      children: (
        <div className="mobileTable">
          <DischargedPatients />
        </div>
      ),
    },
  ];
  return (
    <Wrapper>
      <header>
        <Button onClick={() => navigate("/add-patient")}>Add Patient</Button>
      </header>
      <CustomTabs defaultActiveKey="1" items={tabs_items} onChange={onChange} />
    </Wrapper>
  );
};

export default PatientLists;
