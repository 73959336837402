import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: .4em 0em 1em;
  background: #15112c;
  border-radius: 12px;
  header {
    padding: 0.4em 1.6em;
    display:flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 1em;
    }
    .btnContainer {
      display:flex;
      .inviteBtn,
      button {
        width: 116px;
        height: 36px;
        border-radius: 60px;
        background: #3a49d6;
        border: 1px solid #3a49d6;
        span {
          color: #ffffff;
      }
      &:hover {
        background: #3a49d699 !important;
        border: 1px solid #3a49d6;
        span {
          color: #ffffff;
        }
      }
    }
    .inviteBtn {
      background: #15112c ;
      border: 1px solid #ffffff;
      margin-right: 1em;
    }
   }
  }
  @media (max-width: 768px) {
    & {
      padding: 0 .5em 1em;
      background: #15112c00;
      header {
        padding: 0.4em 0em;
        padding-bottom: 1em;
        display:flex;
        justify-content: space-between;
        h2 {
          font-size: 1em;
        }
        .btnContainer {
          display:flex;
          align-items: center;
          .inviteBtn,
          button {
            width: 91px;
            height: 33px;
            font-size: 12px;    
        }
        .inviteBtn {
         margin-right: 0.8em;
       }
       }
    }
  }
`;
export const AddPatients = styled("div")``;
