import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: .4em 1em 1em;
  background: #15112c;
  border-radius: 12px;
  header {
    display:flex;
    justify-content: space-between;
    align-items: center;  
    .textPermission;
     p {
      font-size: 1.2em;
      font-weight:600;
      }
    
  }
  .textPermission{
    font-size:15px;
    font-weight:300;
    color:#acacac;
  }
  .permissionHeading{
    font-size:30px;
    font-weight:500;
    color:#ffffff;
  }
  .container{
    border:1px solid #ffffff;
    border-radius: 12px;
    padding:0 1em;
  }
  .card{
    height:58px;
    border:1px solid #ffffff;
    border-radius: 40px;
    padding:0 1em;
    display:flex;
    justify-content:space-between;
    align-items:center;
  }
  .cardUser{
    height:75px;
    border:1px solid #ffffff;
    border-radius:12px;
    padding:0 1em;
    display:flex;
    justify-content:space-between;
    align-items:center;
    p {
      line-height: 2;
      margin: 0
    }
  }
  .textName{
    font-size:15px;
    font-weight:300;
    color:#ffffff;
  }
  .textEmail{
    font-size:14px;
    font-weight:400;
    color:#ffffff;
  }
  .plusBtn;
  button {
        height: 39px;
        border-radius: 60px;
        background: #3a49d6;
        border: 1px solid #3a49d6;
        span {
          color: #ffffff;
        }
        &:hover {
          background: #3a49d699 !important;
          border: 1px solid #3a49d6;
          span {
            color: #ffffff;
          }
        }
      }
   .plusBtn{
    display:flex;
    justify-content:center;
    align-items:center;
    gap:0.5em;
   }    
  @media (max-width: 768px) {
    & {
      padding: 0 .2em 1em;
      background: #15112c00;      
    }
    .permissionHeading{
      font-size:18px;
    }
    .textName{
      color:#ACACAC;
    }
    .textEmail{
      color:#ACACAC;
    }
  }
`;
export const AddPatients = styled("div")``;
