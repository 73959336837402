import React, { Fragment, createContext, useEffect, useState } from "react";
import {
  Actions,
  Chat,
  ChatContainer,
  ChatUsers,
  CreateNewChat,
  Header,
  MessageArea,
  MessageHeader,
  People,
  ReceiveMessage,
  SearchArea,
  SendMessage,
  TypeMessage,
  UserAvatar,
  Wrapper,
} from "./index.style";
import { Row, Col, Input, Button, Form } from "antd";
import { NavLink } from "react-router-dom";
import { HiPlus } from "react-icons/hi";
import DeleteMessage from "../../../components/modals/DeleteMessage";
import NewMessage from "../../../components/modals/NewMessage";
import useWindowDimensions from "../../../components/Dimension";
import { FaChevronLeft } from "react-icons/fa";
import { BackBtn } from "../../../common/index.style";
import axios from "axios";
import {
  GET_ALL_CHATS_API,
  GET_MESSAGES_API,
  MARK_ALL_MESSAGES_AS_READ_API,
  SEARCH_CHATS_API,
  SEND_MESSAGE_API,
} from "../../../routes/ApiRoutes";
import toast from "react-hot-toast";

const { Search } = Input;

export const countContext = createContext();
export const newChatUserContext = createContext();
export const chatPreviewContext = createContext();
export const mobChatPreviewContext = createContext();

const UserChat = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showMobChat, setShowMobChat] = useState(false);
  const [showDeleteTaskMessage, setShowDeleteTaskMessage] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [chatList, setChatsList] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const [messagesList, setMessagesList] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleShowAlert = () => {
    setMessagesList([]);
    setSelectedUser({});
    setShowAlertModal(true);
  };

  const handleShowDelete = () => {
    setShowDeleteTaskMessage(true);
  };

  const handleCancel = () => {
    setShowAlertModal(false);
    setShowDeleteTaskMessage(false);
  };

  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width <= 993) {
      setShowMobChat(false);
    } else {
      setShowChat(false);
    }
  }, [width]);

  const handleSearchChats = (val) => {
    setSearchInput(val);

    axios
      .get(SEARCH_CHATS_API + val, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          setSearchList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleChatPreview = () => {
    if (width <= 993) {
      setShowMobChat(true);
    } else {
      setShowChat(true);
    }
  };

  const getAllChats = () => {
    axios
      .get(GET_ALL_CHATS_API, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res.data.status) {
          setChatsList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleSelectedUser = (val) => {
    setSelectedUser(val);
  };

  const getMessages = (id) => {
    axios
      .get(GET_MESSAGES_API + id._id, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          setMessagesList(res.data.data);
          setSelectedUser(id);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleSendMessage = (id) => {
    axios
      .post(
        SEND_MESSAGE_API,
        {
          to: id,
          message: messageInput,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res.data.status) {
          setMessageInput("");
          getMessages(selectedUser);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleMarkAsRead = (id) => {
    axios
      .get(MARK_ALL_MESSAGES_AS_READ_API + id, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    getAllChats();
  }, []);

  return (
    <newChatUserContext.Provider value={{ selectedUser, handleSelectedUser }}>
      <chatPreviewContext.Provider value={{ showChat, handleChatPreview }}>
        <DeleteMessage
          visible={showDeleteTaskMessage}
          setVisible={handleCancel}
          message="chat"
          updateList={() => {
            getAllChats();
            handleTabClick(null);
            setSelectedUser({});
          }}
          id={chatId}
        />
        <NewMessage visible={showAlertModal} setVisible={handleCancel} />

        <Wrapper>
          <Row gutter={26}>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <ChatUsers>
                <Header>
                  <div>
                    <NavLink to="/user_chat">
                      <img src="/icons/message-icon.svg" alt="" />
                    </NavLink>
                    <span>Messages</span>
                  </div>
                  <CreateNewChat onClick={handleShowAlert}>
                    <HiPlus />
                  </CreateNewChat>
                </Header>
                <SearchArea>
                  <Search
                    placeholder="Search People"
                    allowClear
                    onChange={(e) => {
                      handleSearchChats(e.target.value);
                    }}
                  />
                </SearchArea>
                <div>
                  <h2>People</h2>
                </div>
                <People>
                  {searchInput !== "" ? (
                    <>
                      {searchList.map((tab, index) => (
                        <Chat
                          className={`tab-item ${
                            activeTab === index ? "active" : ""
                          }`}
                          onClick={() => {
                            getMessages(tab);
                            handleTabClick(index);
                            if (width <= 993) {
                              setShowMobChat(true);
                            } else {
                              setShowChat(true);
                            }
                            tab.totalUnread > 0 && handleMarkAsRead(tab.chatId);
                          }}
                        >
                          <UserAvatar>
                            <img
                              src={tab.profilePic}
                              style={{ borderRadius: "50%" }}
                              alt=""
                            />
                            <div>
                              <h6>{tab.firstName + " " + tab.lastName}</h6>
                              <span>{tab.designation}</span>
                            </div>
                          </UserAvatar>
                          <Actions>
                            {tab.totalUnread > 0 && (
                              <span>{tab.totalUnread}</span>
                            )}
                            <img
                              src="/icons/delete.svg"
                              alt=""
                              onClick={() => {
                                setChatId(tab.chatId);
                                handleShowDelete();
                              }}
                            />
                          </Actions>
                        </Chat>
                      ))}
                    </>
                  ) : (
                    <>
                      {chatList.map((tab, index) => (
                        <Chat
                          className={`tab-item ${
                            activeTab === index ? "active" : ""
                          }`}
                          onClick={() => {
                            getMessages(tab);
                            handleTabClick(index);
                            if (width <= 993) {
                              setShowMobChat(true);
                            } else {
                              setShowChat(true);
                            }
                            tab.totalUnread > 0 && handleMarkAsRead(tab.chatId);
                          }}
                        >
                          <UserAvatar>
                            <img
                              src={tab.profilePic}
                              style={{ borderRadius: "50%" }}
                              alt=""
                            />
                            <div>
                              <h6>{tab.firstName + " " + tab.lastName}</h6>
                              <span>{tab.designation}</span>
                            </div>
                          </UserAvatar>
                          <Actions>
                            {tab.totalUnread > 0 && (
                              <span>{tab.totalUnread}</span>
                            )}
                            <img
                              src="/icons/delete.svg"
                              alt=""
                              onClick={() => {
                                setChatId(tab.chatId);
                                handleShowDelete();
                              }}
                            />
                          </Actions>
                        </Chat>
                      ))}
                    </>
                  )}
                </People>
              </ChatUsers>
            </Col>
            {showChat && (
              <Col xs={{ span: 24 }} lg={{ span: 14 }}>
                {selectedUser.firstName && (
                  <MessageArea>
                    <MessageHeader>
                      <UserAvatar>
                        <img
                          src={selectedUser.profilePic}
                          style={{ borderRadius: "50%" }}
                          alt=""
                        />
                        <div>
                          <h6>
                            {selectedUser.firstName +
                              " " +
                              selectedUser.lastName}
                          </h6>
                          <span>{selectedUser.designation}</span>
                        </div>
                      </UserAvatar>
                    </MessageHeader>
                    <ChatContainer>
                      {messagesList.map((val, i) => (
                        <Fragment key={i}>
                          {val.from._id !== user.user._id ? (
                            <ReceiveMessage>
                              <p>{val.message}</p>
                              <span>
                                {<FormatDate isoString={val.createdAt} />}
                              </span>
                            </ReceiveMessage>
                          ) : (
                            <SendMessage>
                              <p>{val.message}</p>
                              <span>
                                {<FormatDate isoString={val.createdAt} />}
                              </span>
                            </SendMessage>
                          )}
                        </Fragment>
                      ))}
                    </ChatContainer>
                    <Form onFinish={() => handleSendMessage(selectedUser._id)}>
                      <TypeMessage>
                        <Input
                          placeholder="Write your message..."
                          value={messageInput}
                          onChange={(e) => setMessageInput(e.target.value)}
                        />
                        <Button htmlType="submit">
                          <img src="/icons/send.svg" alt="" />
                        </Button>
                      </TypeMessage>
                    </Form>
                  </MessageArea>
                )}
              </Col>
            )}
          </Row>

          {/*  &///========== Mobile Chat */}

          {showMobChat && (
            <MessageArea>
              <BackBtn onClick={() => setShowMobChat(false)}>
                <FaChevronLeft /> <span>Back</span>
              </BackBtn>
              <ChatContainer>
                {messagesList.map((val, i) => (
                  <Fragment key={i}>
                    {val.from._id !== user.user._id ? (
                      <ReceiveMessage>
                        <p>{val.message}</p>
                        <span>{<FormatDate isoString={val.createdAt} />}</span>
                      </ReceiveMessage>
                    ) : (
                      <SendMessage>
                        <p>{val.message}</p>
                        <span>{<FormatDate isoString={val.createdAt} />}</span>
                      </SendMessage>
                    )}
                  </Fragment>
                ))}
              </ChatContainer>
              <Form onFinish={() => handleSendMessage(selectedUser._id)}>
                <TypeMessage>
                  <Input
                    placeholder="Write your message..."
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                  />
                  <Button htmlType="submit">
                    <img src="/icons/send.svg" alt="" />
                  </Button>
                </TypeMessage>
              </Form>
            </MessageArea>
          )}
        </Wrapper>
      </chatPreviewContext.Provider>
    </newChatUserContext.Provider>
  );
};

export default UserChat;

function FormatDate({ isoString }) {
  // Parse the ISO string into a Date object
  const date = new Date(isoString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  // Create a new Date object for today
  const today = new Date();

  // Set time to midnight for accurate comparison
  today.setHours(0, 0, 0, 0);

  // Get the local time parts
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine AM/PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour time to 12-hour time
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format minutes to always have two digits
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Build the time string
  const timeString = hours + ":" + formattedMinutes + " " + ampm;

  // Get the date string without the time part
  const dateString = date.toDateString();
  const todayString = today.toDateString();

  // Check if the given date is today
  const isToday = dateString === todayString;

  // Return the formatted string
  return isToday ? `Today, ${timeString}` : `${dateString}, ${timeString}`;
}
