import React from "react";
import {
  CollapseData,
  CollapseHeader,
  Wrapper,
} from "../../user/dashboard/index.style";
import StaffDataTable from "../../../components/dataTables/Staffs";
import { Collapse } from "antd";

const NursesList = () => {
  const items = [
    {
      key: "1",
      label: (
        <CollapseHeader>
          <h1>Nurses List</h1> <span>View all</span>
        </CollapseHeader>
      ),
      children: (
        <div className="mobileTable">
          <StaffDataTable staffCategory="Nurses Name" type='nurses' />
        </div>
      ),
    },
  ];
  return (
    <Wrapper>
      <div className="tabContainer">
        <CollapseData>
          <Collapse items={items} defaultActiveKey={1} />
        </CollapseData>
      </div>
    </Wrapper>
  );
};

export default NursesList;
