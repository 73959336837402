import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import NotificationAlert from "../modals/NotificationAlert";
import {
  GET_COUNTS_API,
  GET_PROFILE_DATA_API,
  SEARCH_PATIENT_API,
} from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import axios from "axios";
import { GET_LOGIN_USER_DETAILS } from "../../routes/AdminApiRoutes";
import { getFacilityDetails } from "../../utils/getFacilityDetails";

const { Search } = Input;

const Header = ({ profileAddress, userType }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const [showNotification, setShowNotification] = useState(false);
  const [search, setSearch] = useState("");
  const [searchArr, setSearchArr] = useState([]);
  const [counts, setCounts] = useState({});
  const [profilePic, setProfilePic] = useState("");
  const [facilityDetails, setFacilityDetails] = useState({});

  const [adminsArr, setAdminsArr] = useState([]);
  const [facilityArr, setFacilityArr] = useState([]);

  const handleAdminSearches = (search_val) => {
    if (!search_val) return;

    const token = user.token.startsWith("Bearer ")
      ? user.token
      : `Bearer ${user.token}`;

    axios
      .get(`https://gapcure.teknovation.io/search/users?query=${search_val}`, {
        headers: { Authorization: token },
      })
      .then((res) => setSearchArr(res.data.data))
      .catch((err) => alert(err));
  };

  const handleSuperAdminSearches = (search_val) => {
    if (!search_val) return;

    const token = user.token.startsWith("Bearer ")
      ? user.token
      : `Bearer ${user.token}`;

    // search admins
    axios
      .get(
        `https://gapcure.onrender.com/v1/admins?fullName=${search_val}&username=${search_val}&email=${search_val}`,
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => setAdminsArr(res.data.data))
      .catch((err) => alert(err));

    // search facility
    axios
      .get(`https://gapcure.onrender.com/v1/facilities?name=${search_val}`, {
        headers: { Authorization: token },
      })
      .then((res) => setFacilityArr(res.data.data))
      .catch((err) => alert(err));
  };

  const handleSearch = (search_val) => {
    if (!search_val) return;

    const token = user.token.startsWith("Bearer ")
      ? user.token
      : `Bearer ${user.token}`;

    axios
      .get(`${SEARCH_PATIENT_API}${search_val}`, {
        headers: { Authorization: token },
      })
      .then((res) => setSearchArr(res.data))
      .catch((err) => alert(err));
  };

  const handleCounts = () => {
    axios
      .get(GET_COUNTS_API, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res.data.status) {
          setCounts(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleFetch = () => {
    axios
      .get(GET_PROFILE_DATA_API, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res.data.status) {
          setProfilePic(res.data.data.profilePic);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(`Error fetching profile data: ${err.message}`);
      });
  };

  const handleProfile = () => {
    axios
      .get(GET_LOGIN_USER_DETAILS, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((res) => {
        if (res.data.status) {
          setProfilePic(res.data.data.avatar);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(`Error fetching profile data: ${err.message}`);
      });
  };

  useEffect(() => {
    if (user.role !== "super") {
      const facilityId =
        user.role === "admin" ? user.facility : user.user.facility;

      getFacilityDetails(facilityId)
        .then((res) => {
          if (res.status) {
            setFacilityDetails(res.data);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(`Error fetching facility details: ${err.message}`);
        });
    }

    if (user.role !== "super" && user.role !== "admin") {
      handleCounts();
      handleFetch();
    }

    if (user.role === "super" || user.role === "admin") {
      handleProfile();
    }
  }, []);

  return (
    <HeaderContainer>
      {userType === "super" ? (
        <LogoArea style={{ background: "#15112c", paddingRight: "1em" }}>
          <img
            style={{ marginLeft: "-2em" }}
            src="/images/admin-logo.svg"
            alt=""
          />
        </LogoArea>
      ) : (
        <LogoArea>
          <img src={facilityDetails.logo} height={70} alt="" />
        </LogoArea>
      )}
      <SearchArea style={{ position: "relative" }}>
        <Search
          placeholder="Search"
          value={search}
          allowClear
          onChange={(e) => {
            setSearch(e.target.value);
            if (userType === "admin") {
              handleAdminSearches(e.target.value);
            } else if (userType === "super") {
              handleSuperAdminSearches(e.target.value);
            } else {
              handleSearch(e.target.value);
            }
          }}
        />
        {search && (
          <SearchContainer>
            {userType === "admin" ? (
              <>
                <h5 style={{ color: "#fff", marginLeft: "1rem" }}>Users</h5>
                {searchArr.map((data, i) => (
                  <SearchItem
                    key={i}
                    onClick={() => {
                      data.designation == "doctor" && navigate("/doctors");
                      data.designation == "nurse" && navigate("/nurses");
                      data.designation == "caretaker" &&
                        navigate("/caregivers");
                      setSearch("");
                    }}
                  >
                    <div style={{ alignSelf: "center" }}>
                      <img
                        src={data.profilePic}
                        width={50}
                        height={50}
                        style={{ borderRadius: "50%" }}
                        alt=""
                      />
                    </div>
                    <div style={{ alignSelf: "center", marginLeft: "0.5rem" }}>
                      <h5>
                        {data.firstName + " " + data.lastName}
                        <small>
                          <br />
                          {data.designation}
                        </small>
                      </h5>
                    </div>
                  </SearchItem>
                ))}
              </>
            ) : userType === "super" ? (
              <>
                <>
                  <h5 style={{ color: "#fff", marginLeft: "1rem" }}>Admin</h5>
                  {adminsArr.map((data, i) => (
                    <SearchItem
                      key={i}
                      onClick={() => {
                        data.fullName && navigate("/super/home");
                        setSearch("");
                      }}
                    >
                      <div
                        style={{ alignSelf: "center", marginLeft: "0.5rem" }}
                      >
                        <h5>{data.fullName}</h5>
                      </div>
                    </SearchItem>
                  ))}
                </>
                <>
                  <h5 style={{ color: "#fff", marginLeft: "1rem" }}>
                    Facility
                  </h5>
                  {facilityArr.map((data, i) => (
                    <SearchItem
                      key={i}
                      onClick={() => {
                        data.name && navigate("/super/facility_list");
                        setSearch("");
                      }}
                    >
                      <div style={{ alignSelf: "center" }}>
                        <img
                          src={data.logo}
                          width={50}
                          height={50}
                          style={{ borderRadius: "50%" }}
                          alt=""
                        />
                      </div>
                      <div
                        style={{ alignSelf: "center", marginLeft: "0.5rem" }}
                      >
                        <h5>{data.name}</h5>
                      </div>
                    </SearchItem>
                  ))}
                </>
              </>
            ) : (
              ["patients", "alerts", "reminders", "tasks"].map(
                (key) =>
                  searchArr[key]?.length > 0 && (
                    <React.Fragment key={key}>
                      <h5 style={{ color: "#fff", marginLeft: "1rem" }}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </h5>
                      {searchArr[key].map((data, i) => (
                        <SearchItem
                          key={i}
                          onClick={() => {
                            key === "patients" &&
                              navigate(`/patient-profile/${data._id}`);
                            setSearch("");
                          }}
                        >
                          {key === "patients" && (
                            <div style={{ alignSelf: "center" }}>
                              <img
                                src={data.image.path}
                                width={50}
                                height={50}
                                style={{ borderRadius: "50%" }}
                                alt=""
                              />
                            </div>
                          )}
                          <div
                            style={{
                              alignSelf: "center",
                              marginLeft: key !== "patients" ? "0.5rem" : 0,
                            }}
                          >
                            <h5>{data.alertName || data.name}</h5>
                          </div>
                        </SearchItem>
                      ))}
                    </React.Fragment>
                  )
              )
            )}
          </SearchContainer>
        )}
      </SearchArea>
      <NotificationArea>
        <>
          {userType === "admin" ? (
            ""
          ) : (
            <>
              {userType === "super" ? (
                ""
              ) : (
                <>
                  <NavLink
                    to="#"
                    onClick={(e) => {
                      setShowNotification(!showNotification);
                      e.preventDefault();
                    }}
                  >
                    {counts.notifications > 0 && (
                      <span>{counts.notifications}</span>
                    )}
                    <img src="/icons/bell-icon.svg" alt="" />
                  </NavLink>
                  <NavLink to="/user_chat">
                    {counts.messages > 0 && <span>{counts.messages}</span>}
                    <img src="/icons/message-icon.svg" alt="" />
                  </NavLink>
                </>
              )}
            </>
          )}
        </>
        <NavLink
          className="profileAvatar"
          to={profileAddress ? `${profileAddress}` : "/profile"}
        >
          <img
            src={profilePic ? profilePic : "/images/avatar.PNG"}
            style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            alt=""
          />
        </NavLink>
      </NotificationArea>
      {showNotification && (
        <AlertModal>
          <NotificationAlert handelCloseModal={setShowNotification} />
        </AlertModal>
      )}
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled("header")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5em;
  background: #15112c;
  height: 70px;
`;
const LogoArea = styled("div")`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 325px;
  height: 70px;
  span {
    cursor: pointer;
    margin-right: 1em;
  }
`;
const SearchArea = styled("div")`
  .ant-input-outlined {
    background: #15112c;
    border-color: #15112c;

    &:focus-within,
    &:focus {
      box-shadow: none !important;
    }
  }
  width: 100%;
  margin: auto;
  .ant-input-search {
    background: #15112c;
  }
  .ant-input-wrapper-focused {
  }
  .ant-input-wrapper {
    border-radius: 40px;
    background: #15112c;
    color: #ffffff;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row-reverse !important;
    overflow: hidden;
    height: 50px;
    padding-left: 0.5em;
    input {
      border: none;
      font-size: 1em;
      background: #15112c;
      font-family: "Poppins", sans-serif;
      color: #ffffff;
      :focus {
        border-color: #15112c;
        box-shadow: none important;
      }
      &::placeholder {
        color: #ffffff90;
      }
    }
    button {
      border: none;
      width: 40px;
      height: 40px;
      background: url(/icons/search-icon.svg);
      background-repeat: no-repeat;
      background-size: 52%;
      background-position: center;
      :hover,
      :focus {
        background: url(/icons/search-icon.svg) !important;
        width: 40px !important;
        height: 40px !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: 52% !important;
        border-color: #15112c;
        box-shadow: none;
      }
    }
  }
  .ant-input-group-addon {
    background: #15112c;
    overflow: hidden;
    width: 50px;
    :last-child {
      left: 0px !important;
    }
  }
`;

const NotificationArea = styled("div")`
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5em;
  padding: 0 1em 0 0;
  a {
    margin: auto;
    position: relative;
    span {
      position: absolute;
      top: -5px;
      right: -8px;
      background: rgb(251, 131, 131);
      color: #ffffff;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      border-radius: 50%;
    }
  }
  .profileAvatar {
    margin-left: auto !important;
    margin-right: 0 !important;
  }
`;
const AlertModal = styled("div")`
  position: absolute;
  right: 6%;
  top: 70px;
  z-index: 11111;
`;
const SearchContainer = styled("div")`
  position: absolute;
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 12px;
  background-color: #191633;
  margin-top: 5px;
  z-index: 9;
  &::last-child {
    div {
      border-bottom: none;
    }
  }
`;
const SearchItem = styled("div")`
  display: flex;
  color: white;
  border-bottom: 1px solid #ffffff;
  padding: 5px 8px;
  gap: 10px;
  cursor: pointer;
`;
