import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoutes = ({ children }) => {
  let location = useLocation();
  const isAuthenticated = localStorage.getItem("user") !== null;
  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoutes;
