import React, { useLayoutEffect, useState } from "react";
import {
  Avatar,
  ImageSection,
  UploadArea,
  UserProfileAvatar,
  Wrapper,
} from "./index.style";
import { Button, Col, Form, Row, Upload } from "antd";
import CustomInput from "../../../common/CustomInput";
import PasswordInput from "../../../common/PasswordInput";
import { FormFooter } from "../../user/dashboard/index.style";
import axios from "axios";
import { GET_LOGIN_USER_DETAILS } from "../../../routes/AdminApiRoutes";
import toast from "react-hot-toast";

const AdminProfile = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const [avatarPreview, setAvatarPreview] = useState("");

  const Style = {
    paddingRight: "0",
    borderTop: "none",
  };

  const handleProfileData = () => {
    setIsLoading(true);
    axios
      .get(GET_LOGIN_USER_DETAILS, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((res) => {
        if (res.data.status) {
          setProfileData(res.data.data);
          setAvatarPreview(res.data.data.avatar || "/images/avatar.PNG");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(`Error fetching profile data: ${err.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditProfile = () => {
    if (editedDetails?.password !== editedDetails?.r_n_password) {
      toast.error("New password and re enter new password do not match");
      return;
    }

    // Remove r_n_password from editedDetails if present
    const { r_n_password, ...updatedDetails } = editedDetails;

    axios
      .patch(GET_LOGIN_USER_DETAILS, updatedDetails, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(`Error updating profile data: ${err.message}`);
      });
  };

  const handleAvatarChange = (info) => {
    const file = info.file.originFileObj || info.file;
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setAvatarPreview(reader.result);
        setEditedDetails({
          ...editedDetails,
          avatar: file,
        });
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("No file found in the upload info.");
    }
  };

  useLayoutEffect(() => {
    handleProfileData();
  }, []);
  return (
    <Wrapper>
      {isLoading ? (
        <></>
      ) : (
        <ImageSection>
          <h1>Profile settings</h1>
          <Form
            layout="vertical"
            onFinish={handleEditProfile}
            initialValues={{ full_name: profileData.fullName }}
          >
            <UserProfileAvatar>
              <UploadArea>
                <Avatar>
                  <img src={avatarPreview} alt="Avatar Preview" />
                </Avatar>
                <Upload
                  maxCount={1}
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={handleAvatarChange}
                >
                  <span>Click to Edit</span>
                </Upload>
              </UploadArea>
              <div className="nameInput">
                <CustomInput
                  label="Full name"
                  name="full_name"
                  placeholder="Enter Full Name"
                  onChange={(e) => {
                    setEditedDetails({
                      ...editedDetails,
                      fullName: e.target.value,
                    });
                  }}
                />
              </div>
            </UserProfileAvatar>

            <FormFooter style={Style}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </FormFooter>
          </Form>
        </ImageSection>
      )}
      <ImageSection>
        <h1>Change Password</h1>
        <Form layout="vertical" autoComplete="off" onFinish={handleEditProfile}>
          <Row>
            <Col span={24}>
              <Row gutter={26}>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <PasswordInput
                    label="Current Password"
                    name="c_password"
                    placeholder="Enter Current Password"
                    onChange={(e) => {
                      setEditedDetails({
                        ...editedDetails,
                        currentPassword: JSON.stringify(e.target.value),
                      });
                    }}
                  />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <PasswordInput
                    label="New Password"
                    name="n_password"
                    placeholder="New Password"
                    onChange={(e) => {
                      setEditedDetails({
                        ...editedDetails,
                        password: JSON.stringify(e.target.value),
                      });
                    }}
                  />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <PasswordInput
                    label="Re Enter New Password"
                    name="r_n_password"
                    placeholder="Re Enter New Password"
                    onChange={(e) => {
                      setEditedDetails({
                        ...editedDetails,
                        r_n_password: JSON.stringify(e.target.value),
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <FormFooter style={Style}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </FormFooter>
        </Form>
      </ImageSection>
      {isLoading ? (
        <></>
      ) : (
        <ImageSection>
          <h1>Email Address</h1>
          <Form layout="vertical" initialValues={{ email: profileData.email }}>
            <Row gutter={26}>
              <Col xs={{ span: 24 }} md={{ span: 16 }}>
                <Row gutter={26}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <CustomInput
                      label="Email"
                      name="email"
                      placeholder="Enter New Email"
                      disable={true}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </ImageSection>
      )}
    </Wrapper>
  );
};

export default AdminProfile;
