import { GET_FACILITY_DETAILS } from "../routes/AdminApiRoutes";


export const getFacilityDetails = async (facilityId) => {
    try {
    const response = await fetch(`${GET_FACILITY_DETAILS}/${facilityId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching facility details:', error);
    throw error;
  }
};
