const BaseUrl = "https://gapcure.onrender.com";

// login api for admin & super admin
const LOGIN_API = `${BaseUrl}/v1/auth/login`;

// get all admins api super admin
const GET_ALL_ADMINS = `${BaseUrl}/v1/admins`;

// get all facilities api + add facility api + edit facility
const GET_ALL_FACILITIES = `${BaseUrl}/v1/facilities`;

// get login user details + UPDATE_PROFILE_DETAILS
const GET_LOGIN_USER_DETAILS = `${BaseUrl}/v1/auth/profile`

// delete admin + reset password
const DELETE_ADMIN = `${BaseUrl}/v1/admins/` // :id

// get facility details
const GET_FACILITY_DETAILS = `${BaseUrl}/v1/public/facilities` // :id


export { LOGIN_API, GET_ALL_ADMINS, GET_ALL_FACILITIES, GET_LOGIN_USER_DETAILS, DELETE_ADMIN,GET_FACILITY_DETAILS };
