import React, { useState } from "react";
import { Container, Header, Wrapper } from "../allRoles/index.style";
import { Button } from "antd";
import { BsPlusCircleFill } from "react-icons/bs";
import RolesDataTable from "../../../components/dataTables/RolesList";
import AddPermission from "../../../components/modals/AddPermission";

const RolesLists = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <Wrapper>
      <AddPermission
        visible={openModal}
        setVisible={() => setOpenModal(false)}
      />
      <Container>
        <Header>
          <h1>Roles</h1>
          <Button onClick={() => setOpenModal(true)}>
            <BsPlusCircleFill /> <span>NEW PERMISSION</span>
          </Button>
        </Header>
        <div className="mobileTable ">
          <RolesDataTable />
        </div>
      </Container>
    </Wrapper>
  );
};

export default RolesLists;
