import styled from "styled-components";

export const Wrapper = styled("div")`
  padding-bottom: 6em;
  .webCollapse {
    .ant-collapse-content {
      border-top: none;
    }
  }
  .adminTabs {
    .ant-collapse-content-box {
      padding-top: 0 !important;
      .staffDataTable {
        table {
          thead {
            th {
              border-bottom: 1px solid #ffffff90;
            }
          }
        }
      }
      @media (max-width: 768px) {
        & {
          .staffDataTable {
            table {
              thead {
                th {
                  border-bottom: 1px solid #ffffff90;
                  background: rgb(21, 17, 44) !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .hideData {
    display: none;
    @media (max-width: 768px) {
      & {
        display: block;
      }
    }
  }
  .showData {
    display: block;
    @media (max-width: 768px) {
      & {
        display: none;
      }
    }
  }
  .mobileTable {
    overflow-x: auto;
    padding-bottom: 1em;
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    &::-webkit-scrollbar {
      // width: 9px !important;
      height: 5px !important;
    }
    .facilitiesListTable {
      table {
        tbody {
          td {
            &:first-child {
              padding-left: 1.5em !important;
            }
          }
        }
      }
    }
  }
  .bodyBorder {
    .ant-collapse-content {
      border-top: none !important;
    }
    .ant-collapse-content-box {
      padding-top: 0 !important;
    }
    .adminPageTable {
      .ant-table-thead {
        .ant-table-cell {
          background: #24204a !important;
          border-bottom: none !important;
          padding: 1.5em 0.7em !important;
        }
      }
    }
  }
  .tabContainer {
    border-radius: 12px;
    background: #15112c;
    padding: 0em 1em;
    .ant-collapse {
      .ant-table-thead {
        .ant-table-cell {
          background: #00000000 !important;
          padding-left: 0em !important;
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          background: #00000000 !important;
          padding-left: 0em !important;
        }
      }
      .patients {
        padding-left: 0em !important;
      }
    }
    .ant-collapse-header {
      padding: 1em 0 !important;
      .ant-collapse-expand-icon {
        right: -10px !important;
      }
    }
  }
  .superTab,
  .taskData {
    .ant-collapse-content {
      border-top: none !important;
    }
    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
  @media (max-width: 768px) {
    padding-bottom: 2em !important;
    .taskData {
      .ant-collapse-content {
        // border-top: 1px solid #ffffff !important;
      }
    }
    .superTab,
    .webCollapse {
      .ant-collapse-content {
        border-top: 1px solid #ffffff !important;
      }
    }
  }
`;
export const MessageCard = styled("div")`
  padding: 1em 2em;
  margin-bottom: 1em;
  border-radius: 12px;
  background: #15112c;
  box-shadow: 0px 1px 3px 0px #00000014;
  h2 {
    color: #ffffff;
    font-size: 1.7em;
    font-weight: 700;
    margin: 0;
  }
  h3 {
    color: #ffffff;
    font-weight: 500;
    font-size: 1.5em;
    margin: 0;
  }
  p {
    span {
      color: #f02d4f;
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 2em 0.5em;
      text-align: center;
      border: 1px solid #ffffff;
      div {
        justify-content: center;
        display: flex;
      }
      h3 {
        font-size: 1.3em;
      }
      h2 {
        font-size: 1.3em;
        margin-left: 0.3em;
      }
    }
  }
`;
export const CollapseData = styled("div")`
  width: 100%;
  .ant-collapse {
    border: none;
    background: transparent !important;
    width: 100%;
  }
  .ant-collapse-item {
    border: none;
    border-radius: ${(props) =>
      props.type === "custom" ? "12px" : "12px 12px"} !important;
    background: #15112c;
    box-shadow: 0px 1px 3px 0px #00000014;
    margin-bottom: 1em;
    overflow: hidden;
    // border-bottom: 1px solid #ffffff90;
    .ant-collapse-header {
      background: #15112c;
      color: #ffffff;
      .ant-collapse-expand-icon {
        // display: none;
        position: absolute;
        right: 0;
        top: 23px;
        svg {
          font-size: 1.2em;
          transform: rotate(90deg);
        }
      }
    }
    .ant-collapse-content,
    .ant-collapse-content-active {
      background: #15112c;
      color: #ffffff;
    }
  }
  .ant-collapse-item-active {
    border-radius: 12px !important;
    border-bottom: none !important;
    .ant-collapse-header {
      .ant-collapse-expand-icon {
        svg {
          transform: rotate(-90deg) !important;
        }
      }
    }
  }
  .ant-collapse-content {
    border-top: 1px solid #ffffff90;
  }
  .ant-collapse-content-box {
    padding: 0.5em 0 2em !important;
  }
`;
export const CollapseHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  margin-right: 1.5em;
  h1 {
    margin: 0;
    font-size: 1.3em;
    font-weight: 500;
    text-transform: uppercase;
  }
  span {
    font-size: 0.9em;
  }
`;

export const AddTaskForm = styled("div")`
  padding: 1em 0 0.5em;
  margin-bottom: 1em;
  border-radius: 12px;
  background: #15112c;
  box-shadow: 0px 1px 3px 0px #00000014;
  .ant-input-outlined {
    border-color: #00000000 !important;
  }
  .ant-input-status-error {
    border-color: #00000000 !important;
  }
  .taskInput {
    width: 93%;
    background: #15112c;
    resize: none;
    color: #ffffff;
    margin: 0.5em 0 0 1em;
    font-size: 2em;
    border: none;
    border-color: #15112c !important;
    box-shadow: none !important;
    &::placeholder {
      color: #ffffff99;
    }
    &:focus-within,
    &:hover {
      background: #15112c;
      color: #ffffff;
      border-color: #15112c !important;
      box-shadow: none !important;
    }
  }
  @media (max-width: 768px) {
    & {
      .taskInput {
        font-size: 1.4em;
        margin: 0.5em auto 3em !important;
      }
    }
  }
`;

export const FormFooter = styled("div")`
  padding: 2em;
  text-align: right;
  border-top: 1px solid #ffffff90;
  .ant-btn {
    padding: 0em 2em;
    height: 40px;
    border-radius: 33px;
    border: 1px solid #3a49d6;
    background: #3a49d6;
    span {
      color: #ffffff;
      font-size: 1.1em;
    }
    &:hover {
      border: 1px solid #3a49d6 !important;
      background: #3a49d6 !important;
      span {
        color: #ffffff;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      border-top: none;
      padding: 0.5em 0em;
      .ant-btn {
        width: 100%;
      }
    }
  }
`;
export const SelectArea = styled("div")`
  .ant-picker .ant-picker-input > input[disabled] {
    color: #ffffff !important;
  }
  label {
    color: #ffffff !important;
  }
  margin: 2em;
  display: flex;
  .ant-select,
  .ant-picker {
    border: 1px solid #ffffff;
    color: #ffffff;
    background: #15112c;
    width: 139px !important;
    height: 56px !important;
    border-radius: 10px !important;
    margin-right: 1em;
    input {
      ::placeholder {
        color: #ffffff;
      }
    }
    .ant-picker-input {
      flex-direction: row-reverse;
    }
    .ant-picker-clear,
    .ant-picker-suffix {
      margin-right: 0.5em;
      svg {
        color: #ffffff;
      }
      &:hover {
        svg {
          color: #ffffff;
        }
      }
    }
    .ant-picker-suffix {
      display: block !important;
    }
    .ant-picker-clear {
      display: none;
    }
  }
  .ant-select-selector {
    color: #ffffff;
    background: #15112c !important;
    border-radius: 10px !important;
  }
  .ant-select-arrow {
    transform: rotateX(45deg);
    svg {
      color: #ffffff;
      font-size: 1.1em;
    }
  }
  .ant-select-selection-item {
    svg {
      display: none;
    }
  }
  .prioritySelect {
    .ant-select-arrow {
      display: none;
    }
    .ant-select-selection-item {
      color: #000000 !important;
      background-color: ${(props) => props.primary} !important;
      border-radius: 30px;
      display: flex;
      justify-content: center !important;
      align-items: center;
      padding: 0.1em 1em;
    }
    .ant-select-selector {
      position: relative;
      padding: 0 1em 0 2em;
      &::before {
        content: "";
        background-image: url(./icons/emoji_flags.svg);
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 20px;
        left: 8px;
        z-index: 2;
      }
    }
  }
  .ant-select-selection-placeholder {
    color: #ffffff;
  }
  .ant-select {
    border: none;
    width: auto !important;

    &:hover {
      border: none;
      box-shadow: none;
      border-color: #ffffff !important;
    }
  }
  @media (max-width: 768px) {
    & {
      margin: 1em;
      flex-direction: column;
      gap: 1em;
      .ant-select,
      .ant-picker {
        width: 100% !important;
        margin-right: 0;
        text-align: center;
        display: flex !important;
        justify-content: center !important;
        .ant-picker-input {
          width: auto !important;
          input {
            text-align: center;
          }
        }
      }
      .ant-select {
        border: 1px solid #ffffff;
        align-items: center;
        .ant-select-selector {
          border: 1px solid #ffffff00;
          width: auto;
        }
        .ant-select-arrow {
          position: unset !important;
        }
        &:hover,
        &:focus-within,
        &:focus {
          border: 1px solid #ffffff !important;
          .ant-select-selector {
            border-color: #ffffff00 !important;
            box-shadow: none !important;
          }
        }
      }
      .prioritySelect {
        .ant-select-selector {
          text-align: -webkit-center;
          padding: 0px 0em 0px 2em !important;
        }
        .ant-select-selection-item {
          margin-top: 0em;
        }
      }
    }
  }
`;
