import styled from "styled-components";
import { Form, Tabs } from "antd";

export const Container = styled("div")`
  //   background: #000000;
  background-image: linear-gradient(120deg, #15112c, #000000, #000000);
  height: 96.6vh;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  padding: 1em;
  @media (max-width: 768px) {
    & {
      padding: 4em 1em 1em;
      flex-direction: column;
      height: auto;
    }
  }
`;
export const FormContainer = styled("div")`
  width: 100%;
  text-align: center;
  position: relative;
  padding-top: 4em;
  img {
    position: absolute;
    right: 0px;
    top: -10px;
    height: 78px;
  }
  p {
    color: #ffffff;
    margin: 1em auto 2em;
    font-size: 1.3em;
  }
  @media (max-width: 768px) {
    & {
      padding-top: 1em;
      position: unset;
      img {
        right: 4%;
        top: 5px;
        height: 45px;
      }
    }
  }
`;
export const LoginForm = styled(Form)`
  width: 80%;
  margin: 2em auto;
  text-align: left;
  color: #ffffff;
  .rememberInput {
    position: relative;
    .ant-checkbox-wrapper {
      span {
        color: #ffffff !important;
      }
    }
    a {
      position: absolute;
      right: 0;
      color: #ffffff;
    }
    .ant-checkbox-inner {
      background: #15112c !important;
      width: 20px;
      height: 20px;
    }
  }
  .ant-btn {
    width: 232px;
    height: 49px;
    border-radius: 33px;
    background: #3a49d6;
    margin-left: auto;
  }
  .buttonArea {
    text-align: right;
  }
  @media (max-width: 768px) {
    & {
      width: 100%;
      margin: 2em auto 0;
      .ant-btn {
        width: 100%;
        height: 40px;
        margin-left: auto;
      }
    }
  }
`;
export const Images = styled("div")`
  background: #000000;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    & {
      height: 320px;
    }
  }
`;
export const CustomTabs = styled(Tabs)`
  .ant-tabs-nav {
    width: 300px;
    margin: auto;
    border-radius: 40px;
    padding: 0em !important;
    overflow: hidden;
    background: #15112c;
    ::before {
      display: none !important;
    }
  }
  .ant-tabs-nav-wrap {
    background: #15112c;
    width: 300px;
    ::after,
    ::before {
      display: none !important;
    }
  }
  .ant-tabs-tab-active,
  .ant-tabs-tab-btn {
    background: #15112c;
    border-radius: 30px;
    width: 100%;
    color: #ffffff;
    transition: 200ms !important;
    .ant-tabs-tab-btn {
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 30px;
      background: #15112c;
      border-radius: 30px;
    }
  }
  .ant-tabs-tab-active {
    background: #3a49d6 !important;
    .ant-tabs-tab-btn {
      background: #3a49d6 !important;
      transition: 100ms;
      color: #ffffff !important;
    }
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
`;
export const Heading = styled("div")`
  position: absolute;
  left: 10px;
  bottom: 5px;
  padding: 1em 2em;
  h2 {
    font-size: 2.5em;
    margin-bottom: 0;
    color: #ffffff;
    line-height: 1.5;
  }
  p {
    margin-top: 0;
    font-size: 1.5em;
    color: #ffffff;
  }
  @media (max-width: 768px) {
    & {
      left: 3%;
      padding: 1em 1em;
      h2 {
        font-size: 1.5em;
      }
      p {
        font-size: 1em;
      }
    }
  }
`;
export const Overlay = styled("div")`
  background: linear-gradient(
    180deg,
    rgba(166, 166, 166, 0.35) 0%,
    rgba(0, 0, 0, 0.35) 100%
  );
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;
