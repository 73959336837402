import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  DatePicker,
  Select,
  Form,
  Input,
  Upload,
  TimePicker,
  Button,
} from "antd";
import CustomInput, { InputContainer } from "../../../common/CustomInput";
import { AddBtn, AddPatients, Wrapper } from "./index.style";
import { FiFileText, FiPlus } from "react-icons/fi";
import { AddTaskForm, FormFooter, SelectArea } from "../dashboard/index.style";
import { AddAlertBtn } from "../../../common/index.style";
import axios from "axios";
import {
  ADD_MULTIPLE_ALERTS_API,
  ADD_MULTIPLE_REMINDERS_API,
  ADD_PATIENT_API,
  ADD_TASK_API,
  GET_ALL_USERS_API,
} from "../../../routes/ApiRoutes";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FaTrashAlt, FaUser } from "react-icons/fa";
import { IoListCircle } from "react-icons/io5";
import PatientAdmitMessage from "../../../components/modals/PatientAdmitMessage";
import FormItem from "antd/es/form/FormItem";
import moment from "moment";

const { TextArea } = Input;

const AddPatient = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const redirect = useNavigate();

  const [selectedItems, setSelectedItems] = useState([]);
  const [showReminderForm, setShowReminderForm] = useState(false);
  const [showAlertForm, setShowAlertForm] = useState(false);
  const [showAdmitMessage, setShowAdmitMessage] = useState(false);
  const [showTaskForm, setShowTaskForm] = useState(false);
  const [careTeam, setCareTeam] = useState([]);
  const [date, setDate] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [reportFiles, setReportFiles] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [image, setImage] = useState(null);
  const [value, setValue] = useState({});
  const [taskFormData, setTaskFormData] = useState({
    description: "",
    assignee: "",
    priority: "",
    dueDate: "",
  });
  const [recordObj, setRecordObj] = useState(null);

  const format = "HH:mm";
  const dateChange = (date, dateString) => {
    setDate(dateString);
  };

  const handleCreateTaskAgainstPatient = (patient) => {
    const data = {
      assignee: taskFormData.assignee,
      priority: taskFormData.priority,
      dueDate: taskFormData.dueDate,
      patient,
      description: taskFormData.description,
    };

    axios
      .post(ADD_TASK_API, data, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res.data.status) {
          toast.success("Task Created!");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const priorityChange = (item) => {
    setTaskFormData({ ...taskFormData, priority: item });
    if (item === "high") {
      setSelectValue("#ff8888");
    } else if (item === "medium") {
      setSelectValue("#8aff88");
    } else if (item === "low") {
      setSelectValue("#ffef61");
    }
  };

  const handleSubmit = () => {
    setInProgress(true);
    const patientCareTeam = selectedItems.join(", ");
    const { name, addressLine1, addressLine2, documentation } = value;

    const formData = {
      name,
      dateOfBirth: date,
      addressLine1,
      addressLine2,
      patientCareTeam,
      documentation,
      reports: reportFiles,
      image,
    };

    axios
      .post(ADD_PATIENT_API, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (taskFormData.description !== "") {
            handleCreateTaskAgainstPatient(res.data.data._id);
          }

          if (showAlertForm) {
            handleMultiAlert(res.data.data._id);
          }

          if (showReminderForm) {
            handleMultiReminder(res.data.data._id);
          }

          redirect("/patient-lists");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  const handleCareTeam = () => {
    axios
      .get(GET_ALL_USERS_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCareTeam(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const addReminder = () => {
    setRemindersForm([
      ...remindersForm,
      { reminderName: "", date: "", time: "" },
    ]);
  };

  const removeReminder = (index) => {
    setRemindersForm((prevReminders) =>
      prevReminders.filter((_, i) => i !== index)
    );
  };

  const [alertsForm, setAlertsForm] = useState([
    { alertName: "", date: "", time: "" },
  ]);
  const [remindersForm, setRemindersForm] = useState([
    { reminderName: "", date: "", time: "" },
  ]);

  const handleInputChange = (index, field, value) => {
    const newAlertsForm = [...alertsForm];
    newAlertsForm[index][field] = value;
    setAlertsForm(newAlertsForm);
  };
  const handleInputChangeReminder = (index, field, value) => {
    const newAlertsForm = [...remindersForm];
    newAlertsForm[index][field] = value;
    setRemindersForm(newAlertsForm);
  };

  const dateChangeAlert = (date, dateString, index) => {
    handleInputChange(index, "date", dateString);
  };
  const dateChangeReminder = (date, dateString, index) => {
    handleInputChangeReminder(index, "date", dateString);
  };

  const timeChange = (time, timeString, index) => {
    handleInputChange(index, "time", timeString);
  };
  const timeChangeReminder = (time, timeString, index) => {
    handleInputChangeReminder(index, "time", timeString);
  };

  const handleMultiAlert = (id) => {
    const alerts = alertsForm.filter(
      (alert) => alert.alertName || alert.date || alert.time
    );

    const formData = { patient: id, alerts };

    axios
      .post(ADD_MULTIPLE_ALERTS_API, formData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          toast.success("Alerts Added!");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleMultiReminder = (id) => {
    const reminders = remindersForm.filter(
      (alert) => alert.reminderName || alert.date || alert.time
    );

    const formData = { patient: id, reminders };

    axios
      .post(ADD_MULTIPLE_REMINDERS_API, formData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          toast.success("Reminders Added!");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const disabledDate = (current) => {
    return current && current > moment().startOf("day");
  };

  useEffect(() => {
    handleCareTeam();
  }, []);

  const handleCancel = () => {
    setShowAdmitMessage(false);
  };

  const handleBeforeUpload = (file) => {
    setImage(file);
    return false; // Prevent the file from being uploaded immediately
  };

  return (
    <>
      <PatientAdmitMessage
        visible={showAdmitMessage}
        setVisible={handleCancel}
        onSubmit={handleSubmit}
        message="Admit"
        loading={inProgress}
      />
      <Wrapper>
        <Form
          layout="vertical"
          onFinish={(value) => {
            // validate the form
            if (value.name === "") {
              toast.error("Please input your Patient Name!");
              return;
            }
            if (value.dateOfBirth === "") {
              toast.error("Please input your Date of Birth!");
              return;
            }
            if (value.addressLine1 === "") {
              toast.error("Please input your Address Line 1!");
              return;
            }
            if (value.addressLine2 === "") {
              toast.error("Please input your Address Line 2!");
              return;
            }

            setShowAdmitMessage(true);
            setValue(value);
          }}
          autoComplete="off"
        >
          <AddPatients>
            <h1>Add Patient</h1>
            <Row
              gutter={{
                xs: 6,
                md: 20,
              }}
            >
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Row
                  gutter={{
                    xs: 6,
                    md: 20,
                  }}
                >
                  <Col xs={{ span: 24 }} md={{ span: 16 }}>
                    <CustomInput
                      required
                      label="Patient Name"
                      name="name"
                      placeholder="Patient Name"
                    />
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    <Form.Item
                      label="Date of Birth"
                      name="dateOfBirth"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Date of Birth!",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={dateChange}
                        required
                        disabledDate={disabledDate}
                        placeholder="YYYY/MM/DD"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}></Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomInput
                  required
                  label="Patient Address"
                  name="addressLine1"
                  placeholder="Address Line 1"
                />
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <CustomInput
                  required
                  label=" "
                  name="addressLine2"
                  placeholder="Address Line 2"
                />
              </Col>
              <Col span={24}>
                <Form.Item
                  name="documentation"
                  label="Patient Details & Documentations"
                >
                  <TextArea
                    style={{
                      height: 120,
                      resize: "none",
                    }}
                    placeholder="Enter Patient Details & Documentation"
                  />
                </Form.Item>
              </Col>
            </Row>
            <h1>Add Medical Records</h1>
            <div>
              {reportFiles.map((val, i) => (
                <Row key={i}>
                  <Col span={22}>
                    <Form.Item
                      label={`Medical Report # ${i + 1}`}
                      onClick={() => {
                        window.open(URL.createObjectURL(val), "_blank");
                      }}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                          border: "1px solid white",
                          padding: "15px",
                          color: "white",
                          textAlign: "center",
                          borderRadius: "5px",
                        }}
                      >
                        {val.name}
                      </div>
                    </Form.Item>
                  </Col>

                  <Col span={2}>
                    <AddBtn
                      style={{ marginTop: "2rem" }}
                      onClick={() => {
                        const newArr = [...reportFiles];
                        newArr.splice(i, 1);
                        setReportFiles(newArr);
                      }}
                    >
                      <FaTrashAlt size={20} color="brown" />
                    </AddBtn>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col span={22}>
                  <Form.Item style={{ cursor: "pointer" }}>
                    <Upload
                      accept="application/pdf"
                      customRequest={({ file }) => {
                        if (file.type.startsWith("application/pdf")) {
                          setRecordObj(file);
                        } else {
                          toast.error("Only PDF files are allowed.");
                        }
                      }}
                      listType="picture"
                      fileList={recordObj === null ? [] : [recordObj]}
                      maxCount={1}
                      multiple={false}
                    >
                      <img src="/icons/content_paste.svg" alt="" /> Upload PDF
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <AddBtn
                    onClick={() => {
                      if (
                        Object.keys(recordObj).length === 0 &&
                        recordObj.constructor === Object
                      ) {
                        toast.error("No file selected!");
                        return;
                      }

                      setReportFiles([...reportFiles, recordObj]);
                      setRecordObj(null);
                    }}
                  >
                    <FiPlus />
                  </AddBtn>
                </Col>
              </Row>
            </div>
            <div className="imgUpload">
              <Form.Item
                label="Upload Patient Picture"
                style={{ cursor: "pointer" }}
              >
                <Upload
                  listType="picture"
                  maxCount={1}
                  multiple={false}
                  // percent
                  accept="image/*"
                  // customRequest={({ file }) => {
                  //   if (file.type.startsWith("image/")) {
                  //     setImage(file);
                  //   } else {
                  //     toast.error("Only Image files are allowed.");
                  //   }
                  // }}
                  beforeUpload={handleBeforeUpload}
                >
                  <img src="/icons/camera.svg" alt="" />
                  Click to Upload
                </Upload>
              </Form.Item>
            </div>
          </AddPatients>
          <div className="selectionArea" style={{ paddingBottom: "0" }}>
            <h1>Selecting Patient Care Team</h1>
            <Form.Item label="Care Team">
              <Select
                mode="multiple"
                placeholder=""
                value={selectedItems}
                onChange={setSelectedItems}
                style={{
                  width: "100%",
                }}
                options={careTeam.map((item) => ({
                  value: item._id,
                  label: item.name,
                }))}
              />
            </Form.Item>
            {showReminderForm ? (
              <>
                <h1>Add Reminder</h1>
                <Row align="bottom">
                  <Col xs={{ span: 24 }} md={{ span: 23 }}>
                    <Row gutter={{ xs: 6, md: 10, lg: 20 }} align="middle">
                      <>
                        {remindersForm.map((val, i) => (
                          <React.Fragment key={i}>
                            <Col span={8}>
                              <InputContainer>
                                <Form.Item
                                  label="Reminder Name"
                                  name={`reminderName${i}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: `Please input your Reminder Name!`,
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Reminder Name"
                                    value={val.reminderName}
                                    onChange={(e) =>
                                      handleInputChangeReminder(
                                        i,
                                        "reminderName",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Form.Item>
                              </InputContainer>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                label="Date"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your Date!",
                                  },
                                ]}
                              >
                                <DatePicker
                                  disabledDate={(current) =>
                                    current && current < moment().startOf("day")
                                  }
                                  onChange={(date, dateString) =>
                                    dateChangeReminder(date, dateString, i)
                                  }
                                  placeholder="mm/dd/yyyy"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item label="Time">
                                <TimePicker
                                  format={format}
                                  required
                                  placeholder="hh/mm"
                                  onChange={(time, timeString) =>
                                    timeChangeReminder(time, timeString, i)
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Button
                                type="danger"
                                onClick={() => removeReminder(i)}
                                style={{
                                  marginTop: "1rem",
                                }}
                              >
                                <FaTrashAlt color="red" />
                              </Button>
                            </Col>
                          </React.Fragment>
                        ))}
                      </>
                    </Row>
                  </Col>
                  <AddBtn
                    positionStyle="absolute"
                    style={{ top: 0, bottom: 0, margin: "auto" }}
                    onClick={addReminder}
                  >
                    <FiPlus />
                  </AddBtn>
                </Row>
              </>
            ) : (
              <AddAlertBtn>
                <h1>Add Reminder</h1>
                <AddBtn onClick={() => setShowReminderForm(true)}>
                  <FiPlus />
                </AddBtn>
              </AddAlertBtn>
            )}
            {showAlertForm ? (
              <>
                <h1>Add Alert</h1>
                <Row align="bottom">
                  <Col xs={{ span: 24 }} md={{ span: 23 }}>
                    <Row gutter={{ xs: 6, md: 10, lg: 20 }} align="middle">
                      <>
                        {alertsForm.map((val, i) => (
                          <React.Fragment key={i}>
                            <Col span={8}>
                              <InputContainer>
                                <Form.Item
                                  label="Alert Name"
                                  name={`alertName${i}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: `Please input your Alert Name!`,
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Alert Name"
                                    value={val.alertName}
                                    onChange={(e) =>
                                      handleInputChange(
                                        i,
                                        "alertName",
                                        e.target.value
                                      )
                                    }
                                  />
                                </Form.Item>
                              </InputContainer>
                            </Col>
                            <Col span={8}>
                              <Form.Item label="Date">
                                <DatePicker
                                  required
                                  onChange={(date, dateString) =>
                                    dateChangeAlert(date, dateString, i)
                                  }
                                  placeholder="mm/dd/yyyy"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item label="Time">
                                <TimePicker
                                  format={format}
                                  required
                                  placeholder="hh/mm"
                                  onChange={(time, timeString) =>
                                    timeChange(time, timeString, i)
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </React.Fragment>
                        ))}
                      </>
                    </Row>
                  </Col>
                  <AddBtn
                    positionStyle="absolute"
                    style={{ top: 0, bottom: 0, margin: "auto" }}
                  >
                    <FiPlus
                      onClick={() => {
                        setAlertsForm([
                          ...alertsForm,
                          { alertName: "", date: "", time: "" },
                        ]);
                      }}
                    />
                  </AddBtn>
                </Row>
              </>
            ) : (
              <AddAlertBtn>
                <h1>Add Alert</h1>
                <AddBtn onClick={() => setShowAlertForm(true)}>
                  <FiPlus />
                </AddBtn>
              </AddAlertBtn>
            )}
          </div>
          {showTaskForm ? (
            <>
              <AddTaskForm style={{ margin: "1em 1.5em" }}>
                <Form layout="vertical" name="basic" autoComplete="off">
                  <FormItem name="description">
                    <TextArea
                      className="taskInput"
                      required
                      placeholder="Write Task"
                      autoSize
                      onChange={(e) => {
                        setTaskFormData({
                          ...value,
                          description: e.target.value,
                        });
                      }}
                    />
                  </FormItem>
                  <SelectArea primary={selectValue}>
                    <DatePicker
                      required
                      style={{
                        width: "auto",
                        height: "48px",
                        borderRadius: "10px",
                      }}
                      onChange={(val, val2) => {
                        setTaskFormData({ ...taskFormData, dueDate: val2 });
                      }}
                      placeholder="Due Date"
                    />

                    <Select
                      onChange={priorityChange}
                      placeholder="Priority"
                      className="prioritySelect"
                      dropdownStyle={{
                        width: "auto",
                        borderRadius: "10px",
                        border: "none",
                      }}
                    >
                      <Select.Option value="high" className="high">
                        High
                      </Select.Option>
                      <Select.Option value="medium" className="medium">
                        Medium
                      </Select.Option>
                      <Select.Option value="low" className="low">
                        Low
                      </Select.Option>
                    </Select>

                    <Select
                      style={{
                        width: "auto",
                        height: "48px",
                      }}
                      onChange={(val) => {
                        setTaskFormData({ ...taskFormData, assignee: val });
                      }}
                      defaultValue={user.user._id}
                      dropdownStyle={{
                        width: "auto",
                        borderRadius: "10px",
                      }}
                    >
                      <Select.Option value={user?.user?._id}>
                        <FaUser /> <span>Myself</span>
                      </Select.Option>
                      <Select.Option value="waitlist">
                        <IoListCircle /> <span>Waitlist</span>
                      </Select.Option>
                      {careTeam.map((val, i) => (
                        <Select.Option value={val._id} key={i}>
                          {val.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </SelectArea>
                </Form>
              </AddTaskForm>
            </>
          ) : (
            <div className="selectionArea" style={{ paddingTop: "0" }}>
              <AddAlertBtn>
                <h1>Add Task</h1>
                <AddBtn onClick={() => setShowTaskForm(true)}>
                  <FiPlus />
                </AddBtn>
              </AddAlertBtn>
            </div>
          )}

          <FormFooter style={{ borderTop: "none", margin: "0em 1.5em" }}>
            <Button htmlType="submit" disabled={inProgress}>
              Admit
            </Button>
          </FormFooter>
        </Form>
      </Wrapper>
    </>
  );
};

export default AddPatient;
