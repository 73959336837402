import React from "react";
import styled from "styled-components";
import { ConvertToReadableDate } from "../alertsDates";

const ReminderCard = ({ titleName, list }) => {
  return (
    <Container>
      <h1>{titleName}s</h1>

      <div className="dataContainer">
        <Items>
          <h4>{titleName}:</h4>
        </Items>
        {titleName === "Alert" ? (
          <>
            {list?.map((val, i) => (
              <Items key={i}>
                <p>{val.alertName}</p>
                <span>
                  <ConvertToReadableDate date={val.date} />
                </span>
                <span>{val.time}</span>
              </Items>
            ))}
          </>
        ) : (
          <>
            {list?.map((val, i) => (
              <Items key={i}>
                <p>{val.reminderName}</p>
                <span>
                  <ConvertToReadableDate date={val.date} />
                </span>
                <span>{val.time}</span>
              </Items>
            ))}
          </>
        )}
      </div>
    </Container>
  );
};

export default ReminderCard;
export const Container = styled("div")`
  background: #15112c;
  border-radius: 10px;
  margin-bottom: 1em;
  padding-bottom: 1em;
  h1 {
    border-bottom: 1px solid #ffffff90;
    padding: 1em 1.5em;
    text-transform: capitalize;
    margin: 0 0 0.3em;
  }
  div {
    padding: 0.5em 1.5em;
  }
  .dataContainer {
    height: 150px;
    padding-bottom: 2em;
    overflow-y: scroll;
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
  }
  @media (max-width: 768px) {
    & {
      margin-bottom: 1em;
      .dataContainer {
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`;
export const Items = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 1em;
  h4 {
    margin: 0;
    font-weight: 500;
  }
  p {
    margin: 0;
  }
`;
