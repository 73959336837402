import React, { useState } from "react";
import { CustomTable, TableAction } from "./index.style";
import AddUser from "../modals/AddUser";
import axios from "axios";
import { DELETE_ALERT_API } from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import DeleteMessage from "../modals/DeleteMessage";

const UserManagementList = ({ users, updateList }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [editModal, setEditModal] = useState(false);
  const [record, setRecord] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleEdit = (record) => {
    setEditModal(true);
    setRecord(record);
  };

  const columns = [
    {
      title: "Users List",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name - b.name,
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={record.profilePic} alt="" />
          <span style={{ color: "#ffffff" }}>
            {record.firstName}&nbsp;{record.lastName}
          </span>
        </div>
      ),
    },
    {
      title: "User Designation",
      dataIndex: "designation",
      key: "designation",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.designation - b.designation,
      render: (_, record) => (
        <>
          {record.designation.charAt(0).toUpperCase() +
            record.designation.slice(1)}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <TableAction>
          <span onClick={() => handleEdit(record)}>
            <img src="/icons/edit.svg" alt="" />
          </span>
          <span
            onClick={() => {
              setRecord(record);
              setOpenDeleteModal(true);
            }}
          >
            <img src="/icons/ben.svg" alt="" />
          </span>
        </TableAction>
      ),
    },
  ];

  return (
    <>
      {editModal && (
        <AddUser
          visible={editModal}
          type="edit"
          setVisible={() => setEditModal(false)}
          handleList={updateList}
          record={record}
        />
      )}

      {openDeleteModal && (
        <DeleteMessage
          visible={openDeleteModal}
          setVisible={() => setOpenDeleteModal(false)}
          message="user"
          id={record._id}
          updateList={updateList}
        />
      )}
      <div className="mobileTable userManagementListTable">
        <CustomTable dataSource={users} columns={columns} />
      </div>
    </>
  );
};

export default UserManagementList;
