import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { CustomTable, TableAction } from "./index.style";
import TimestampConverter from "../TimestampConverter";
import DeleteMessage from "../modals/DeleteMessage";
import EditAlert from "../modals/EditAlert";

const { Search } = Input;

const AlertsData = ({ titleName, data, fetchFunc }) => {
  const [showDeleteTaskMessage, setShowDeleteTaskMessage] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState({});
  const [filteredData, setFilteredData] = useState(data);

  const columns = [
    {
      title: titleName,
      dataIndex: titleName === "Alert Name" ? "alertName" : "reminderName",
      key: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        const nameA =
          a[titleName === "Alert Name" ? "alertName" : "reminderName"] || "";
        const nameB =
          b[titleName === "Alert Name" ? "alertName" : "reminderName"] || "";
        return nameA.localeCompare(nameB);
      },
    },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (text, val) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <TimestampConverter timestamp={text} />
          {", "}
          {val.time}
        </div>
      ),
    },
    {
      title: "Patient",
      dataIndex: "patientName",
      key: "patientName",
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        const nameA = a.patientName || "";
        const nameB = b.patientName || "";
        return nameA.localeCompare(nameB);
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: () => (
        <TableAction>
          <span
            onClick={() => {
              setEditModal(true);
            }}
          >
            <img src="/icons/edit.svg" alt="edit" />
          </span>
          <span
            onClick={() => {
              setShowDeleteTaskMessage(true);
            }}
          >
            <img src="/icons/ben.svg" alt="delete" />
          </span>
        </TableAction>
      ),
    },
  ];

  const handleRowClick = (record) => {
    setSelectedRowKeys(record);
  };

  const handleSearch = (value) => {
    const filtered = data.filter((item) =>
      (item[titleName === "Alert Name" ? "alertName" : "reminderName"] || "")
        .toLowerCase()
        .includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <div className="alertDataTable">
      <DeleteMessage
        visible={showDeleteTaskMessage}
        setVisible={() => {
          setShowDeleteTaskMessage(false);
        }}
        message={titleName === "Alert Name" ? "alert" : "reminder"}
        id={selectedRowKeys?._id}
        updateList={fetchFunc}
      />

      {EditModal && (
        <EditAlert
          visible={EditModal}
          setVisible={() => {
            setEditModal(false);
          }}
          updateList={fetchFunc}
          titleName={titleName === "Alert Name" ? "Alert" : "Reminder"}
          data={selectedRowKeys}
        />
      )}
      <div style={{ textAlign: "right" }}>
        <Search
          placeholder={`Search by ${titleName}`}
          onSearch={handleSearch}
          allowClear
          style={{ marginBottom: 16, width: "230px" }}
          className="searchItems"
        />
      </div>
      <CustomTable
        className="alertsData"
        dataSource={filteredData}
        columns={columns}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </div>
  );
};

export default AlertsData;
