import React, { useEffect, useState } from "react";
import { Avatar, CreateModal } from "./index.style";
import { Button, Col, Form, Row, Select, Upload } from "antd";
import CustomInput from "../../common/CustomInput";
import PasswordInput from "../../common/PasswordInput";
import axios from "axios";
import toast from "react-hot-toast";
import {
  ADD_USER_API,
  GET_ONE_USER_API,
  EDIT_ADMIN_USER_API,
} from "../../routes/ApiRoutes";
import styled from "styled-components";

const AddUser = ({ visible, setVisible, type, handleList, record }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [form] = Form.useForm();

  const handleDataFetch = async () => {
    if (type === "edit" && record?._id) {
      try {
        const { data } = await axios.get(GET_ONE_USER_API + record._id, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        if (data.status) {
          setUserData(data.data);
          form.setFieldsValue({
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            email: data.data.email,
            designation: data.data.designation,
          });
        } else {
          toast.error(data.data);
        }
      } catch (err) {
        toast.error(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    handleDataFetch();
  }, [type]);

  const handleSubmit = async (values) => {
    if (values.password && values.password !== values.confirm_password) {
      toast.error("Passwords do not match");
      return;
    }

    const getChangedValues = (values) => {
      const changedValues = {};
      if (type === "edit") {
        for (const key in values) {
          if (values[key] !== userData[key] && key !== "image") {
            changedValues[key] = values[key];
          }
        }
        if (values.image && values.image.file.originFileObj) {
          changedValues.profilePic = values.image.file.originFileObj;
        } else {
          changedValues.profilePic = record?.profilePic;
        }
      } else {
        changedValues.username = `${values.firstName} ${values.lastName}`;
        changedValues.email = values.email;
        changedValues.password = values.password;
        changedValues.designation = values.designation;
        changedValues.firstName = values.firstName;
        changedValues.lastName = values.lastName;
        if (values.image && values.image.file.originFileObj) {
          changedValues.profilePic = values.image.file.originFileObj;
        }
      }
      if (changedValues.password === undefined) {
        delete changedValues.password;
      }
      if (changedValues.confirm_password) {
        delete changedValues.confirm_password;
      }
      return changedValues;
    };

    const apiEndpoint =
      type === "add" ? ADD_USER_API : `${EDIT_ADMIN_USER_API}${record._id}`;
    const payload = getChangedValues(values);

    setLoading(true);
    try {
      const { data } = await axios.post(apiEndpoint, payload, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      if (data.status) {
        handleList();
        setVisible(false);
        form.resetFields();
        toast.success(
          ` ${
            data.message
              ? `${data.message}.Password is send on user email`
              : "User update successfully"
          }` ?? "User update successfully"
        );
      } else {
        toast.error(data.data);
      }
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CreateModal
      footer={false}
      title={
        <h3 className="modalTitle">
          {type === "add" ? "Add User" : "Edit User"}
        </h3>
      }
      open={visible}
      onCancel={() => setVisible(false)}
      width="667px"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          email: userData?.email,
          designation: userData?.designation,
        }}
        style={{ paddingTop: "4em" }}
        onFinish={handleSubmit}
      >
        <Row gutter={{ xs: 6, md: 12 }} align="middle">
          <Col xs={24} md={12}>
            <CustomInput
              label="First Name"
              name="firstName"
              placeholder="First Name"
            />
          </Col>
          <Col xs={24} md={12}>
            <CustomInput
              label="Last Name"
              name="lastName"
              placeholder="Last Name"
            />
          </Col>
          <Col xs={24} md={12}>
            <CustomInput
              label="Email"
              name="email"
              placeholder="Email"
              disabled={type === "edit"}
            />
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Designation" name="designation">
              <Select placeholder="Caretaker">
                <Select.Option value="doctor">Doctor</Select.Option>
                <Select.Option value="nurse">Nurse</Select.Option>
                <Select.Option value="caretaker">Caretaker</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {type === "add" ? (
            ""
          ) : (
            <>
              <Col xs={24} md={12}>
                <PasswordInput
                  label="Password"
                  name="password"
                  placeholder="Password"
                />
              </Col>
              <Col xs={24} md={12}>
                <PasswordInput
                  label="Confirm Password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                />
              </Col>
            </>
          )}
          <Col xs={24} md={12}>
            <Form.Item
              style={{ cursor: "pointer" }}
              label="Upload Your Picture"
              name="image"
            >
              <Upload accept="images/*" maxCount={1} listType="picture">
                <img src="/icons/camera.svg" alt="Upload Icon" />
                <span>Click to Upload</span>
              </Upload>
            </Form.Item>
          </Col>
          {type === "edit" && userData?.profilePic && (
            <Col xs={24} md={6}>
              <div style={{ width: "50px" }}>
                <Avatar>
                  <img src={userData.profilePic} alt="Profile" />
                </Avatar>
              </div>
            </Col>
          )}
        </Row>
        <div style={{ textAlign: "right", marginTop: "2em" }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {type === "add" ? "Add User" : "Save Changes"}
          </Button>
        </div>
      </Form>
    </CreateModal>
  );
};

export default AddUser;

export const Container = styled("div")`
 display:flex;
 justify-content:flex-end;
 button {
  width: 196px;
  height: 43px;
  border-radius: 60px;
  background: #3a49d6;
  border: 1px solid #3a49d6;
  span {
    color: #ffffff;
}
&:hover {
  background: #3a49d699 !important;
  border: 1px solid #3a49d6;
  span {
    color: #ffffff;
  }
}
`;
