import { ResponsiveLine } from "@nivo/line";

const data = [
  {
    id: "Score",
    color: "hsl(228, 70%, 50%)",
    data: [
      { x: "JAN", y: 3 },
      { x: "FEB", y: 2 },
      { x: "MAR", y: 0 },
      { x: "APR", y: 3 },
      { x: "MAY", y: 5 },
      { x: "JUN", y: 4 },
      { x: "JUL", y: 2 },
      { x: "AUG", y: 4 },
      { x: "SEP", y: 3 },
      { x: "OCT", y: 3 },
      { x: "NOV", y: 5 },
      { x: "DEC", y: 4 },
    ],
  },
];

export const PythoScoreGraph = () => (
  <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 50, bottom: 50, left: 20 }}
    xScale={{ type: "point" }}
    xFormat=" >-"
    yScale={{
      type: "linear",
      min: "auto",
      max: "auto",
      stacked: true,
      reverse: false,
    }}
    yFormat={(value) => value.toFixed(2)} // Round to 1 decimal place
    curve="natural"
    axisTop={null}
    axisRight={{
      orient: "right",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "",
      legendOffset: 0,
      truncateTickAt: 0,
      stroke: "#ffffff20",
    }}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "",
      legendOffset: 36,
      legendPosition: "middle",
      truncateTickAt: 0,
    }}
    axisLeft={null}
    enableGridX={false}
    lineWidth={4}
    pointSize={10}
    colors={["#436DDD", "#436DDD70"]}
    colorBy="index"
    pointColor={{ theme: "background" }}
    pointBorderWidth={9}
    pointBorderColor={{ from: "serieColor", modifiers: [] }}
    pointLabelYOffset={-12}
    enableArea={true}
    areaOpacity={0.35}
    theme={"#ffffff"}
    enableSlices="x"
    fill={"#ffffff"}
    crosshairType="top-right"
    useMesh={false}
    legends={[]}
    motionConfig={{
      mass: 1,
      tension: 170,
      friction: 26,
      clamp: false,
      precision: 0.01,
      velocity: 0,
    }}
  />
);
