import { Toaster } from "react-hot-toast";
import "./App.css";
import AllRoutes from "./routes";

// trigger deployment
function App() {
  return (
    <div className="App">
      <Toaster position="top-right" reverseOrder={false} />
      <AllRoutes />
    </div>
  );
}

export default App;
