import React, { useEffect, useState } from "react";
import { CustomTable, TableAction } from "./index.style";
import DeleteMessage from "../modals/DeleteMessage";
import axios from "axios";
import { GET_ALL_ADMINS } from "../../routes/AdminApiRoutes";
import toast from "react-hot-toast";
import EditAdmin from "../modals/EditAdmin";

const AdminLists = () => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const [showDeleteTaskMessage, setShowDeleteTaskMessage] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);

  const handleRowClick = (record) => {
    setSelectedAdmin(record);
  };

  const handleShowDelete = () => {
    setShowDeleteTaskMessage(true);
  };

  const handleCancel = () => {
    setShowDeleteTaskMessage(false);
  };

  const handleAdminList = () => {
    setLoading(true);
    axios
      .get(GET_ALL_ADMINS, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        if (res.data.status) {
          setAdmins(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })

      .catch((err) => {
        toast.error("Something went wrong", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleAdminList();
  }, []);

  const columns = [
    {
      title: "Admins",
      dataIndex: "fullName",
      key: "fullName",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.fullName - b.fullName,
      render: (data, record) => {
        return (
          <div className="patients">
            {record.avatar ? (
              <img src={record.avatar} alt="" />
            ) : (
              <img src="/images/avatar.PNG" alt="" />
            )}
            <span>{data}</span>
          </div>
        );
      },
    },
    {
      title: "Facility",
      dataIndex: "facility",
      key: "facility",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.facility?.name - b.facility?.name,
      render: (data, record) => {
        return (
          <>{record.facility?.name ? record.facility.name : <span>N/A</span>}</>
        );
      },
    },
    {
      title: "Contact",
      dataIndex: "email",
      key: "email",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.email - b.email,
      render: (data, record) => {
        return <>{record.email ? record.email : <span>N/A</span>}</>;
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (data, record) => (
        <TableAction>
          {record.role === "super" ? (
            <span style={{ cursor: "not-allowed" }}>
              <img src="/icons/edit.svg" alt="" />
            </span>
          ) : (
            <span onClick={() => setShowEditTaskModal(true)}>
              <img src="/icons/edit.svg" alt="" />
            </span>
          )}
          <span onClick={handleShowDelete}>
            <img src="/icons/ben.svg" alt="" />
          </span>
        </TableAction>
      ),
    },
  ];
  return (
    <>
      {showEditTaskModal && (
        <EditAdmin
          visible={showEditTaskModal}
          setVisible={() => setShowEditTaskModal(false)}
          data={selectedAdmin}
          updateList={handleAdminList}
        />
      )}
      <DeleteMessage
        visible={showDeleteTaskMessage}
        setVisible={handleCancel}
        message="Admin"
        updateList={handleAdminList}
        id={selectedAdmin?.id}
      />
      <div className="todoDataTable adminListsTable">
        <CustomTable
          dataSource={admins}
          columns={columns}
          loading={loading}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </div>
    </>
  );
};

export default AdminLists;
