import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AutoComplete, Button, Col, Form, Input, Row } from "antd";
import CustomInput from "../../common/CustomInput";
import toast from "react-hot-toast";
import {
  GET_DIAGNOSIS_API,
  GET_MEDICATIONS_API,
  UPDATE_PATIENT_API,
} from "../../routes/ApiRoutes";
import axios from "axios";

const DiagnosisCard = ({ titleName, patient }) => {
  const [medicationValue, setMedicationValue] = useState(null);
  const [medicationsData, setMedicationsData] = useState([]);
  const [options, setOptions] = useState([]);
  const [icdOptions, setIcdOptions] = useState([]);
  const [descriptorOptions, setDescriptorOptions] = useState([]);
  const [diagnosisIdValue, setDiagnosisIdValue] = useState({
    icd10: null,
    descriptor: null,
  });
  const [allDiagnosis, setAllDiagnosis] = useState(null);
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [loading, setLoading] = useState({
    icd10Loading: false,
    descriptorLoading: false,
    medicationLoading: false,
  });
  const [form] = Form.useForm();

  const user = JSON.parse(localStorage.getItem("user"));

  // const handleChange = (value) => {
  //   console.log(`selected ${value}`);
  // };

  useEffect(() => {
    if (patient?.medications) {
      setMedicationsData(patient?.medications);
    }
    if (patient?.diagnosis) {
      setDiagnosisData(patient?.diagnosis);
    }
  }, [patient]);

  // Add Medication search

  const onSearchMedication = (value) => {
    setLoading((prev) => {
      return { ...prev, medicationLoading: true };
    });
    setMedicationValue(value);
    const fetchData = async (query) => {
      try {
        const response = await axios.get(
          GET_MEDICATIONS_API + `?name=${query}`,
          {
            headers: {
              Authorization: user.token,
            },
          }
        );
        const data = response.data?.data;

        const formattedOptions = data?.map((item) => ({
          label: item?.name,
          value: item?.name,
        }));
        setOptions(formattedOptions);
        setLoading((prev) => {
          return { ...prev, medicationLoading: false };
        });
      } catch (error) {
        setLoading((prev) => {
          return { ...prev, medicationLoading: false };
        });
        console.error("Error fetching data:", error);
      }
    };

    if (value) {
      fetchData(value);
    } else {
      setLoading((prev) => {
        return { ...prev, medicationLoading: false };
      });
      setOptions([]); // Clear options if input is empty
    }
  };

  // Create Medication Name

  // const handleCreateMedication = () => {
  //   const medications = {
  //     name: medicationValue,
  //   };
  //   axios
  //     .post(GET_MEDICATIONS_API, medications, {
  //       headers: { Authorization: user.token },
  //     })
  //     .then((res) => {
  //       if (res.data.status) {
  //         toast.success("New medication name added.");
  //       } else {
  //         toast.error(res.data.message);
  //       }
  //     })
  //     .catch((err) => {
  //       alert(err);
  //     });
  //   form.resetFields();
  // };

  // Add Medication

  const handleSubmit = (values) => {
    const medicationFormData = new FormData();
    const newMedication = {
      name: values.name,
      dosage: values.dosage,
      frequency: values.frequency,
      route: values.route,
    };
    const allMedications = [...patient?.medications, newMedication];

    for (let medication of allMedications) {
      medicationFormData.append("medications[]", JSON.stringify(medication));
    }
    axios
      .post(UPDATE_PATIENT_API + patient?._id, medicationFormData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          toast.success("Add Medication Success");
          form.resetFields();
          setMedicationsData((prevData) => [...prevData, newMedication]);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  // Add Diagnosis icd and descriptor search

  const onSearchDiagnosisICD = (value) => {
    setLoading((prev) => {
      return { ...prev, icd10Loading: true };
    });
    setDiagnosisIdValue((prev) => {
      return { ...prev, icd10: value };
    });
    const fetchData = async (query) => {
      try {
        const response = await axios.get(
          GET_DIAGNOSIS_API + `?icd10=${query}`,
          {
            headers: {
              Authorization: user.token,
            },
          }
        );
        const data = response.data?.data;
        setLoading((prev) => {
          return { ...prev, icd10Loading: false };
        });

        setAllDiagnosis(data);

        const formattedICDOptions = data?.map((item) => ({
          label: item?.icd10,
          value: item?.icd10,
        }));

        setIcdOptions(formattedICDOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading((prev) => {
          return { ...prev, icd10Loading: false };
        });
      }
    };

    if (value) {
      fetchData(value);
    } else {
      setLoading((prev) => {
        return { ...prev, icd10Loading: false };
      });

      setIcdOptions([]); // Clear options if input is empty
    }
  };

  const onSearchDiagnosisDescriptor = (value) => {
    setLoading((prev) => {
      return { ...prev, descriptorLoading: true };
    });

    setDiagnosisIdValue((prev) => {
      return { ...prev, descriptor: value };
    });
    const fetchData = async (query) => {
      try {
        const response = await axios.get(
          GET_DIAGNOSIS_API + `?descriptor=${query}`,
          {
            headers: {
              Authorization: user.token,
            },
          }
        );
        const data = response.data?.data;
        setLoading((prev) => {
          return { ...prev, descriptorLoading: false };
        });

        setAllDiagnosis(data);

        const formattedDesOptions = data?.map((item) => ({
          label: item?.descriptor,
          value: item?.descriptor,
        }));

        setDescriptorOptions(formattedDesOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading((prev) => {
          return { ...prev, descriptorLoading: false };
        });
      }
    };

    if (value) {
      fetchData(value);
    } else {
      setLoading((prev) => {
        return { ...prev, descriptorLoading: false };
      });
      setDescriptorOptions([]); // Clear options if input is empty
    }
  };
  // Create Diagnosis Descriptor

  // const handleCreateDiagnosisData = () => {
  //   const newDescriptor = form.getFieldValue("descriptor");
  //   const newicd10 = form.getFieldValue("icd10");

  //   if (newDescriptor && newicd10) {
  //     axios
  //       .post(
  //         GET_DIAGNOSIS_API,
  //         { descriptor: newDescriptor, icd10: newicd10 },
  //         {
  //           headers: { Authorization: user.token },
  //         }
  //       )
  //       .then((res) => {
  //         if (res.data.status) {
  //           toast.success("New diagnosis data added.");
  //         } else {
  //           toast.error(res.data.message);
  //         }
  //       })
  //       .catch((err) => {
  //         alert(err);
  //       });
  //   } else {
  //     toast.error("Please fill complete form.");
  //   }
  // };

  // Add  Diagnosis

  const handleSubmitDiagnosis = async (values) => {
    const diagnosisFormData = new FormData();

    const newDiagnosis = {
      icd10: values.icd10,
      descriptor: values.descriptor,
    };

    const allDiagnosis = [...patient?.diagnosis, newDiagnosis];
    for (let diagnosis of allDiagnosis) {
      diagnosisFormData.append("diagnosis[]", JSON.stringify(diagnosis));
    }
    try {
      const res = await axios.post(
        UPDATE_PATIENT_API + patient?._id,
        diagnosisFormData,
        {
          headers: { Authorization: user.token },
        }
      );
      if (res.data.status) {
        toast.success("Add Diagnosis Success");
        setDiagnosisData((prevData) => [...prevData, newDiagnosis]);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      alert(err);
    }

    form.resetFields();
  };

  return (
    <>
      {titleName === "Diagnosis" && (
        <Container>
          <Items className="header">
            <p>{titleName}</p>
          </Items>
          <Form form={form} layout="vertical" onFinish={handleSubmitDiagnosis}>
            <Row gutter={16} align="middle">
              <Col xs={{ span: 12 }} md={{ span: 5 }}>
                <Form.Item
                  label="ICD-10"
                  name="icd10"
                  rules={[{ required: true, message: "Icd is required" }]}
                >
                  <AutoComplete
                    notFoundContent={
                      loading.icd10Loading ? "loading..." : "write icd!"
                    }
                    name="icd10"
                    options={icdOptions}
                    onSearch={(value) => onSearchDiagnosisICD(value)}
                    placeholder=""
                    onSelect={(value) => {
                      form.setFieldValue(
                        "descriptor",
                        allDiagnosis?.find((item) => item.icd10 === value)
                          ?.descriptor
                      );
                    }}
                  >
                    <Input />
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 15 }}>
                <Form.Item
                  label="Descriptor"
                  name="descriptor"
                  rules={[
                    { required: true, message: "Descriptor is required" },
                  ]}
                >
                  <AutoComplete
                    notFoundContent={
                      loading.descriptorLoading
                        ? "loading..."
                        : "write descriptor!"
                    }
                    name="descriptor"
                    options={descriptorOptions}
                    onSearch={(value) => onSearchDiagnosisDescriptor(value)}
                    placeholder=""
                    onSelect={(value) => {
                      form.setFieldValue(
                        "icd10",
                        allDiagnosis?.find((item) => item.descriptor === value)
                          ?.icd10
                      );
                    }}
                  >
                    <Input />
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 4 }}>
                <Button htmlType="submit">Add</Button>
              </Col>
            </Row>
          </Form>
          <div className="dataContainer" style={{ maxHeight: "213px" }}>
            {diagnosisData?.map((item) => (
              <Items key={item.id}>
                <Row>
                  <Col span={5}>
                    <span>{item.icd10}</span>
                  </Col>
                  <Col span={19}>
                    <p>{item.descriptor}</p>
                  </Col>
                </Row>
              </Items>
            ))}
          </div>
        </Container>
      )}
      {titleName === "Medications" && (
        <Container>
          <Items className="header">
            <p>{titleName}</p>
          </Items>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Row gutter={16} align="middle">
              <Col xs={{ span: 12 }} md={{ span: 5 }}>
                <Form.Item
                  label="Generic Medication"
                  name="name"
                  rules={[
                    { required: true, message: "Medication is required" },
                  ]}
                >
                  <AutoComplete
                    notFoundContent={
                      loading.medicationLoading
                        ? "loading..."
                        : "write medication!"
                    }
                    name="medication"
                    options={options}
                    onSearch={(value) => onSearchMedication(value)}
                    placeholder="Type Name"
                  >
                    <Input />
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 5 }}>
                <CustomInput
                  label="Dosage"
                  name="dosage"
                  placeholder="Write Dosage"
                  required
                />
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 5 }}>
                <CustomInput
                  label="Frequency"
                  name="frequency"
                  placeholder="Write Frequency"
                  required
                />
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 5 }}>
                <CustomInput
                  label="Route"
                  name="route"
                  placeholder="Write Route"
                  required
                />
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 4 }}>
                <Button htmlType="submit">Add</Button>
              </Col>
            </Row>
          </Form>
          <div className="dataContainer">
            {medicationsData?.map((item, i) => (
              <Items key={i} style={{ marginBottom: "1em" }}>
                <Row>
                  <Col span={5}>
                    <span>{item.name}</span>
                  </Col>
                  <Col span={5}>
                    <span>{item.dosage}</span>
                  </Col>
                  <Col span={5}>
                    <span>{item.frequency}</span>
                  </Col>
                  <Col span={9}>
                    <p>{item.route}</p>
                  </Col>
                </Row>
              </Items>
            ))}
          </div>
        </Container>
      )}
    </>
  );
};

export default DiagnosisCard;

const Container = styled("div")`
  background: #15112c;
  border-radius: 10px;
  padding-top: 0em;
  margin-top: -1.1em;
  margin-bottom: 1em;
  h1 {
    margin: 0;
    padding: 1em 1.5em;
    text-transform: capitalize;
  }
  .header {
    background: #24204a !important;
    padding: 0.6em 2em;
    margin-bottom: 1em;
  }
  .dataContainer {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    max-height: 293px;
    margin: 0.5em 0;
    padding: 0.2em 0 0.5em;
    overflow-y: scroll;
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    &::-webkit-scrollbar {
      width: 10px !important;
    }
  }
  button {
    height: 54px;
    border-radius: 30px;
  }
  .ant-select {
    width: 100%;
    color: #ffffff;
    background: #15112c;
    width: 100%;
    // padding-right: 1em;
    height: 54px;
    .ant-select-selection-placeholder {
      color: #acacac;
    }
    .ant-select-arrow {
      right: 23px;
      display: none;
    }
  }
  .ant-select-selector {
    color: #ffffff;
    background: #15112c !important;
    border-radius: 40px;
    .ant-select-selection-search-input {
      color: #ffffff;
      background: #15112c !important;
      border-radius: 40px;
      height: 56px;
    }
  }
  .ant-select-arrow {
    transform: rotateX(45deg);
    svg {
      color: #ffffff;
      font-size: 1.1em;
    }
  }
  @media (max-width: 768px) {
    & {
      margin-bottom: 0em;
      .dataContainer {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`;
const Items = styled("div")`
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  color: #ffffff;
  // padding: 0.7em 2em;
  h4 {
    margin: 0;
    font-weight: 500;
  }
  p {
    margin: 0;
  }
  .ant-col {
    padding: 0.7em 2em;
    border: 1px solid #ffffff;
  }
  div {
    display: flex;
    svg {
      color: #ffffff;
      font-size: 1.1em;
    }
  }
`;
