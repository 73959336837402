import React from "react";
import styled from "styled-components";
import { FaCaretUp } from "react-icons/fa";

const PythoHistory = () => {
  return (
    <Container>
      <h1>Pytho History</h1>

      <div className="dataContainer">
        <Items className="header">
          <p>Date</p>
          <p>Pytho Score</p>
        </Items>
        <Items>
          <p>12-12-2023</p>
          <PythoScore>
            <div>
              <span>
                <p>100</p>
                <div>
                  <span>+9%</span>
                  <FaCaretUp />
                </div>
              </span>
            </div>
          </PythoScore>
        </Items>
        <Items>
          <p>12-12-2023</p>
          <PythoScore>
            <div>
              <span>
                <p>100</p>
                <div>
                  <span>+9%</span>
                  <FaCaretUp />
                </div>
              </span>
            </div>
          </PythoScore>
        </Items>
      </div>
    </Container>
  );
};

export default PythoHistory;

export const Container = styled("div")`
  background: #15112c;
  border-radius: 10px;
  margin-bottom: 1em;
  h1 {
    margin: 0;
    padding: 1em 1.5em;
    text-transform: capitalize;
  }
  .header {
    background: #24204a;
    padding: 0.6em 2em;
  }
  .dataContainer {
    height: 210px;
    overflow-y: auto;
  }
`;
export const Items = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 0.3em 2em;
  h4 {
    margin: 0;
    font-weight: 500;
  }
  p {
    margin: 0;
  }
`;
const PythoScore = styled("div")`
  display: flex;
  justify-content: space-between;
  color: #ff8888;
  div {
    p {
      font-size: 1.1em;
      margin: 0 0.3em 0 0;
    }
    span {
      font-size: 0.8em;
      display: flex;
      color: #ffffff;
      justify-content: start;
      align-items: center;
      &:first-child {
        color: #ff8888;
      }
    }
  }
  svg {
    cursor: pointer;
    color: #ff8888;
    font-size: 1.3em;
  }
`;
