import { Tabs } from "antd";
import styled from "styled-components";

export const CustomTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0em 0;
    border-radius: 10px;
    padding: 0em !important;
    overflow: hidden;
    background: #15112c;
    ::before {
      display: none !important;
    }
  }
  .ant-tabs-content-holder {
    border-left: 1px solid #00000000;
  }
  .ant-tabs-nav-wrap {
    background: #15112c;
    ::after,
    ::before {
      display: none !important;
    }
  }
  .ant-tabs-tab-active,
  .ant-tabs-tab {
    // border: 1px solid #3a49d6;
    padding: 0em !important;
    background: #15112c;
    margin: 0 0 0 11px !important;
    border-radius: 10px;
    color: #ffffff;
    transition: 200ms !important;
    .ant-tabs-tab-btn {
      color: #ffffff;
      margin-bottom: 1em;
      height: 56px !important;
      display: flex;
      width: 100%;
      padding: 0em 1.5em !important;
      border: 1px solid #3a49d6 !important;
      justify-content: center;
      text-transform: uppercase;
      align-items: center;
      background: #15112c;
      border-radius: 8px;
    }
  }
  .ant-tabs-tab-active {
    // background: #3a49d6 !important;
    .ant-tabs-tab-btn {
      border: 1px solid #3a49d6;
      background: #3a49d6 !important;
      height: 56px !important;
      transition: 100ms;
      color: #ffffff !important;
    }
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  @media (max-width: 768px) {
    & {
      .ant-tabs-nav {
        margin: 0em 0;
        border-radius: 0px;
      }
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          &:first-child {
            margin-left: 0 !important;
          }
        }
      }
      .ant-tabs-tab,
      .ant-tabs-nav,
      .ant-tabs-nav-wrap {
        background: #15112c00;
      }
      .ant-tabs-tab {
        border-radius: 5px;
        .ant-tabs-tab-btn {
          height: 40px !important;
          font-size: 0.94em;
          border-radius: 5px;
          padding: 0em 0.5em !important;
        }
      }
    }
  }
  @media (max-width: 420px) {
    & {
      .ant-tabs-tab {
        .ant-tabs-tab-btn {
          height: 40px !important;
          font-size: 0.8em;
          padding: 0em 0.5em !important;
        }
      }
    }
  }
`;
export const CustomModalTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 1em 0;
    border-radius: 10px;
    padding: 1em 0 !important;
    overflow: hidden;
    background: #15112c;
    ::before {
      display: none !important;
    }
  }
  .ant-tabs-content-holder {
    border: 1px solid #ffffff90;
    margin-left: 1em;
    padding: 2em;
    border-radius: 10px;
    background: "#191633";
  }
  .ant-tabs-nav-wrap {
    background: #15112c;
    ::after,
    ::before {
      display: none !important;
    }
  }
  .ant-tabs-tabpane {
    padding-left: 0px !important;
  }
  .ant-tabs-tab-active,
  .ant-tabs-tab {
    // border: 1px solid #3a49d6;
    padding: 0em !important;
    background: #15112c;
    margin: 0 0 0 0px !important;
    border-radius: 10px;
    color: #ffffff;
    transition: 200ms !important;
    .ant-tabs-tab-btn {
      color: #ffffff90;
      margin-bottom: 1em;
      height: 56px !important;
      display: flex;
      width: 200px;
      padding: 0em 1.5em !important;
      border: 1px solid #ffffff !important;
      justify-content: center;
      align-items: center;
      background: #15112c;
      border-radius: 10px;
    }
  }
  .ant-tabs-tab-active {
    // background: #3a49d6 !important;
    .ant-tabs-tab-btn {
      border: 1px solid #ffffff;
      background: #3a49d6 !important;
      height: 56px !important;
      transition: 100ms;
      color: #ffffff !important;
    }
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .todoForm {
    .ant-select-arrow {
      right: 10px !important;
    }
  }
  @media (max-width: 768px) {
    & {
      flex-direction: column !important;
      .ant-tabs-nav-list {
        flex-wrap: wrap;
        padding-top: 1em;
        gap: 1em;
        flex-direction: row !important;
        .ant-tabs-tab {
          height: 40px;
          .ant-tabs-tab-btn {
            width: 118px;
            height: 40px !important;
            margin-bottom: 0.3em;
          }
          &:nth-child(1) {
            .ant-tabs-tab-btn {
              width: 185px;
            }
          }
          &:nth-child(2) {
            .ant-tabs-tab-btn {
              width: 185px;
            }
          }
        }
      }
      .ant-tabs-content-holder {
        margin-left: 0em;
        padding: 1em 1em;
      }
    }
  }
`;

export const ShowPdfButton = styled("div")`
  color: #ffff;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 1.3em;
  svg {
    margin-left: 0.5em;
  }
`;

export const TitleHeading = styled("div")`
  background: #15112c;
  border-radius: 10px;
  padding: 0em 0em 1em;
  margin-bottom: 1em;
  label {
    color: #ffffff !important;
  }
  button {
    background: #3a49d6 !important;
    border: 1px solid #3a49d6 !important;
    border-radius: 20px;
    span {
      color: #ffffff !important;
    }
  }
  svg {
    g {
      text {
        fill: #ffffff !important;
        font-size: 1em !important;
      }
    }
  }
  .content {
    padding: 1em;
  }
  textarea,
  .ant-picker {
    border: 1px solid #ffffff;
    color: #ffffff;
    background: #15112c;
    width: 100%;
    border-radius: 40px;
    margin-right: 1em;
    height: 56px;
    input {
      ::placeholder {
        color: #ffffff90;
      }
    }
    .ant-picker-input {
      flex-direction: row-reverse;
    }
    .ant-picker-clear,
    .ant-picker-suffix {
      display: none;
      margin-right: 0.5em;
      svg {
        color: #ffffff;
      }
      &:hover {
        svg {
          color: #ffffff;
        }
      }
    }
    .ant-picker-clear {
      display: none;
    }
  }
  textarea {
    padding: 1em;
    border-radius: 20px;
    &:focus,
    &:hover {
      background: #15112c !important;
    }
    ::placeholder {
      color: #ffffff90;
    }
  }
  .ant-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    height: 56px;
    .ant-upload {
      border: 1px solid #ffffff00;
      color: #ffffff;
      background: #15112c;
      width: 100%;
      height: 50px;
      img {
        height: 20px;
        margin-right: 1em;
      }
    }
  }
  .header {
    padding: 0.5em 1.5em;
    border-bottom: 1px solid #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      color: #ffffff;
      cursor: pointer;
      svg {
        margin-right: 0.5em;
      }
    }
  }
  .staffingReport {
    width: 700px;
    gap: 1em;
    .ant-btn {
      height: 48px;
      border-radius: 50px;
      margin-top: 1.5em;
      padding: 0 2em;
    }
  }
  .report {
  }
  .saveBtn {
    border-top: none !important;
    padding: 1em 2em;
    .ant-btn {
      height: 35px !important;
    }
  }

  @media (max-width: 768px) {
    & {
      .content {
        padding: 0em 0.5em;
        .ant-picker,
        input {
          width: 100%;
          height: 40px !important;
          border-radius: 10px !important;
        }
        textarea {
          height: 120px !important;
        }
        label {
          font-size: 0.7em !important;
        }
      }
      .saveBtn {
        padding: 0.3em 0.5em;
      }

      .staffingReport {
        width: 100%;
        // flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5em;
        margin-bottom: 1em;

        .ant-picker,
        input {
          width: 100%;
          height: 40px !important;
          border-radius: 10px !important;
        }

        label {
          font-size: 0.7em !important;
        }
        div {
          width: 115px !important;
          &:last-child {
            width: 100% !important;
          }
        }
        .ant-btn {
          width: 100% !important;
          height: 40px;
          border-radius: 50px;
          margin-top: 1.5em;
        }
      }
      .report {
        text-align: left;
        align-items: start;
        flex-direction: column;
        padding: 0.5em 0.5em;
      }
      .header {
        h1 {
          font-size: 1em;
        }
      }
    }
  }
`;

export const Document = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5em;
  div {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    color: #ffffff;
    background: #24204a;
    height: 50px;
    padding-left: 2em;
  }
  @media (max-width: 768px) {
    & {
      div {
        padding-left: 0.5em;
      }
    }
  }
`;
export const DocumentItems = styled("div")`
  display: flex;
  align-items: center;
  gap: 2em;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  height: 50px;
  padding-left: 2em;
  div {
    width: 100%;
    display: flex;
    justify-content: start;
    p {
      display: flex;
      gap: 0.4em;
      align-items: center;
      font-weight: 300;
      color: #d1d0d6;
      a {
        margin-top: 0.3em;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      padding-left: 0.5em;
      p {
        font-size: 0.8em !important;
      }
    }
  }
`;

export const AddAlertBtn = styled("div")`
  height: 56px;
  display: flex;
  padding: 0 1em;
  margin-bottom: 1em;
  background: #15112c;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  // cursor: pointer;
  @media (max-width: 768px) {
    & {
      background: #191633;
    }
  }
`;
export const BackBtn = styled("span")`
  cursor: pointer;
  position: fixed;
  top: 90px;
  left: 3%;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  svg {
    color: #ffffff;
  }
`;

export const Overlay = styled("div")`
  flex-shrink: 0;
  background: #ffffff20 !important;
  backdrop-filter: blur(2px);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
`;
