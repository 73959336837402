import React from "react";
import { MessageContainer } from "./index.style";
import { Button, Input } from "antd";
import styled from "styled-components";

const UserInvite = ({ visible, setVisible }) => {
  return (
    <MessageContainer
      centered
      footer={false}
      open={visible}
      onCancel={setVisible}
      width="465px"
    >
      <Wrapper>
        <h3>Refer Wellchain</h3>
        <p>Enter email to refer wellchain.</p>
        <div className="container">
          <div className="content">
            <Input name="email" placeholder="Enter Email" />
            <Button className="inviteBtn">Send Invite</Button>
          </div>
        </div>
      </Wrapper>
    </MessageContainer>
  );
};

export default UserInvite;

const Wrapper = styled("div")`
h3{
   font-size:1.2em;
   font-weight:600;
 }
 p{
  padding-bottom:1em;
  font-weight:400;
  font-size:.9em
 }
.container{
  .content{
    display:flex;
    border: 1px solid #ffffff;
    border-radius: 40px;
    position: relative;
    .inviteBtn;
    .ant-input {
      color: #ffffff;
    background: #15112c;
    height: 48px;
    border: 1px solid #ffffff00;
    border-radius: 40px;
    &::placeholder {
      color: #ffffff99;
    }
  }  
 }

 .copyBtn;  
 .inviteBtn{
  position: absolute;
  right: -1px;
  top: -1px;
}
.copyBtn{
   border: 1px solid #ffffff00;
 margin:1em 0;
 display:flex;
 justify-content:center;
 align-items:center;
 gap:.5em;
 height: 40px;

 } 
  button {
   width: 139px;
   height: 50px;
   border-radius: 60px;
   background: #3a49d6;
   border: 1px solid #ffffff;
   span {
     color: #ffffff;
  }
  &:hover {
    background: #3a49d699 !important;
    border: 1px solid #ffffff;
    span {
      color: #ffffff;
    }
  }
}
`;
