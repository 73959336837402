import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  DatePicker,
  Select,
  Form,
  Input,
  Upload,
  Button,
} from "antd";
import CustomInput from "../../../common/CustomInput";
import { AddBtn, AddPatients, Wrapper } from "./index.style";
import { FiPlus } from "react-icons/fi";
import { FormFooter } from "../dashboard/index.style";
import axios from "axios";
import {
  EDIT_PATIENT_API,
  GET_ALL_USERS_API,
  SINGLE_PATIENT_DATA_API,
} from "../../../routes/ApiRoutes";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import PatientAdmitMessage from "../../../components/modals/PatientAdmitMessage";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { TextArea } = Input;

const EditPatient = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const redirect = useNavigate();

  const { patientId } = useParams();

  const [data, setData] = useState({});
  const [showAdmitMessage, setShowAdmitMessage] = useState(false);
  const [careTeam, setCareTeam] = useState([]);
  const [date, setDate] = useState(null);
  const [reportFiles, setReportFiles] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [image, setImage] = useState(null);
  const [value, setValue] = useState({});
  const [recordObj, setRecordObj] = useState(null);

  const dateChange = (date, dateString) => {
    setDate(dateString);
  };

  const handleSubmit = () => {
    setInProgress(true);
    const { name, addressLine1, addressLine2, documentation, careTeam } = value;
    const patientCareTeam = careTeam.join(", ");

    const formData = {
      name,
      dateOfBirth: date,
      addressLine1,
      addressLine2,
      patientCareTeam,
      documentation,
      reports: reportFiles,
      image: image ? image : data.image,
    };

    axios
      .post(EDIT_PATIENT_API + patientId, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          redirect("/patient-lists");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  const handleCareTeam = () => {
    axios
      .get(GET_ALL_USERS_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCareTeam(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleData = () => {
    axios
      .get(SINGLE_PATIENT_DATA_API + patientId, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data.patient);
          setImage(res.data.data.patient.image);
          setReportFiles(res.data.data.patient.reports);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const disabledDate = (current) => {
    return current && current > dayjs().startOf("day");
  };

  useEffect(() => {
    handleData();
    handleCareTeam();
  }, []);

  const handleCancel = () => {
    setShowAdmitMessage(false);
  };

  return (
    <>
      <PatientAdmitMessage
        visible={showAdmitMessage}
        setVisible={handleCancel}
        onSubmit={handleSubmit}
        message="edit"
      />
      <Wrapper>
        {data.name && (
          <Form
            layout="vertical"
            onFinish={(value) => {
              setShowAdmitMessage(true);
              setValue(value);
            }}
            initialValues={{
              name: data.name,
              addressLine1: data.addressLine1,
              addressLine2: data.addressLine2,
              documentation: data.documentation,
              careTeam: data.patientCareTeam,
            }}
          >
            <AddPatients>
              <h1>Edit Patient</h1>
              <Row
                gutter={{
                  xs: 6,
                  md: 20,
                }}
              >
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Row
                    gutter={{
                      xs: 6,
                      md: 20,
                    }}
                  >
                    <Col xs={{ span: 24 }} md={{ span: 16 }}>
                      <CustomInput
                        label="Patient Name"
                        name="name"
                        placeholder="Patient Name"
                      />
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Form.Item
                        label="Date of Birth"
                        initialValue={dayjs(data.dateOfBirth)}
                        name="dateOfBirth"
                        rules={[
                          {
                            required: true,
                            message: "Please select your date of birth!",
                          },
                        ]}
                      >
                        <DatePicker
                          disabledDate={disabledDate}
                          onChange={dateChange}
                          placeholder="YYYY/MM/DD"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}></Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <CustomInput
                    label="Patient Address"
                    name="addressLine1"
                    placeholder="Address Line 1"
                  />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <CustomInput
                    label=" "
                    name="addressLine2"
                    placeholder="Address Line 2"
                  />
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="documentation"
                    label="Patient Details & Documentations"
                  >
                    <TextArea
                      required
                      maxLength={100}
                      style={{
                        height: 120,
                        resize: "none",
                      }}
                      placeholder="Enter Patient Details & Documentation"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <h1>Add Medical Records</h1>
              <div>
                {reportFiles.map((val, i) => (
                  <Row key={i}>
                    <Col span={22}>
                      <Form.Item
                        label={`Medical Report # ${i + 1}`}
                        style={{ cursor: "pointer" }}
                      >
                        <Upload disabled accept="application/pdf">
                          {val.name}
                        </Upload>
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      <AddBtn
                        style={{ marginTop: "2rem" }}
                        onClick={() => {
                          const newArr = [...reportFiles];
                          newArr.splice(i, 1);
                          setReportFiles(newArr);
                        }}
                      >
                        <FaTrashAlt size={20} color="brown" />
                      </AddBtn>
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col span={22}>
                    <Form.Item style={{ cursor: "pointer" }}>
                      <Upload
                        accept="application/pdf"
                        customRequest={({ file }) => {
                          if (file.type.startsWith("application/pdf")) {
                            setRecordObj(file);
                          } else {
                            toast.error("Only PDF files are allowed.");
                          }
                        }}
                        listType="picture"
                        fileList={recordObj === null ? [] : [recordObj]}
                        maxCount={1}
                        multiple={false}
                      >
                        <img src="/icons/content_paste.svg" alt="" /> Upload PDF
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <AddBtn
                      onClick={() => {
                        if (
                          Object.keys(recordObj).length === 0 &&
                          recordObj.constructor === Object
                        ) {
                          toast.error("No file selected!");
                          return;
                        }

                        setReportFiles([...reportFiles, recordObj]);
                        setRecordObj(null);
                      }}
                    >
                      <FiPlus />
                    </AddBtn>
                  </Col>
                </Row>
              </div>
              <div className="imgUpload">
                <Form.Item
                  label="Upload Patient Picture"
                  style={{ cursor: "pointer" }}
                >
                  <Upload
                    listType="picture"
                    maxCount={1}
                    multiple={false}
                    fileList={[image]}
                    accept="image/"
                    customRequest={({ file }) => {
                      if (file.type.startsWith("image/")) {
                        setImage(file);
                      } else {
                        toast.error("Only Image files are allowed.");
                      }
                    }}
                  >
                    <img src="/icons/camera.svg" alt="" />
                    Click to Upload
                  </Upload>
                </Form.Item>
              </div>
            </AddPatients>
            <div className="selectionArea" style={{ paddingBottom: "0" }}>
              <h1>Selecting Patient Care Team</h1>
              <Form.Item label="Care Team" name="careTeam">
                <Select
                  mode="multiple"
                  placeholder="Care Team"
                  style={{
                    width: "100%",
                  }}
                  options={careTeam.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                />
              </Form.Item>
            </div>

            <FormFooter style={{ borderTop: "none", margin: "0em 1.5em" }}>
              <Button htmlType="submit" disabled={inProgress}>
                Save
              </Button>
            </FormFooter>
          </Form>
        )}
      </Wrapper>
    </>
  );
};

export default EditPatient;
