import styled from "styled-components";

export const Wrapper = styled("div")`
  background-image: linear-gradient(-45deg, #000000, #000000, #08024a);
  height: 100vh;
  overflow: hidden;
`;
export const Content = styled("div")`
  display: flex;
  height: 97vh;
  .mobileTable {
    background: #15112c;
    padding-bottom: 1em;
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    &::-webkit-scrollbar {
      // width: 9px !important;
      height: 5px !important;
    }
  }

  @media (max-width: 768px) {
    & {
      .mobileTable {
        border-radius: 12px !important;
        overflow-x: auto;
        .adminListsTable,
        .facilitiesListTable {
          table {
            width: 750px !important;
            .ant-table-thead {
              th {
                background: rgb(21, 17, 44) !important;
                border-bottom: 1px solid #ffffff90;
              }
            }
          }
        }
        .adminListsTable {
          table {
            width: 550px !important;
          }
        }
      }
    }
  }
`;
export const Container = styled("div")`
  width: 100%;
  // height: 97vh;
  overflow-y: scroll;
  padding: 1em 1em 4em;
  color: #ffffff;
  @media (max-width: 768px) {
    padding-bottom: 2em !important;
  }
`;
