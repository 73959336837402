import React, { useState } from "react";
import {
  Container,
  Header,
  SelectItems,
  SelectRole,
  SelectRoleItems,
  Wrapper,
} from "./index.style";
import { Button, Col, Form, Row } from "antd";
import { BsPlusCircleFill } from "react-icons/bs";
import CustomInput from "../../../common/CustomInput";
import Roles from "../../../components/cards/Roles";
import AddPermission from "../../../components/modals/AddPermission";

const AllRoles = () => {
  const [openModal, setOpenModal] = useState(false);

  const rolesData = [
    { title: "Dashboard Read" },
    { title: "Certificate Create" },
    { title: "Certificate Read" },
    { title: "Certificate Update" },
    { title: "Dashboard Read" },
    { title: "Certificate Create" },
    { title: "Certificate Read" },
    { title: "Certificate Update" },
    { title: "Dashboard Read" },
    { title: "Certificate Create" },
    { title: "Certificate Read" },
    { title: "Certificate Update" },
    { title: "Dashboard Read" },
    { title: "Certificate Create" },
    { title: "Certificate Read" },
    { title: "Certificate Update" },
    { title: "Dashboard Read" },
    { title: "Certificate Create" },
    { title: "Certificate Read" },
    { title: "Certificate Update" },
    { title: "Dashboard Read" },
    { title: "Certificate Create" },
    { title: "Certificate Read" },
    { title: "Certificate Update" },
  ];
  return (
    <>
      <Wrapper>
        <Container>
          <Header>
            <h1>Create Roles</h1>
            <Button onClick={() => setOpenModal(true)}>
              <BsPlusCircleFill /> <span>NEW PERMISSION</span>
            </Button>
          </Header>
          <Form layout="vertical">
            <CustomInput
              label="Role Name"
              name="role_name"
              placeholder="Enter role name"
            />
            <SelectRole>
              <span>Select Permission for this Role:</span>
              <SelectRoleItems>
                <Row gutter={{ xs: 6, md: 16, lg: 20 }}>
                  {rolesData.map((item, i) => (
                    <Col xs={{ span: 12 }} md={{ span: 6 }}>
                      <div key={i}>
                        <Roles data={item} />
                      </div>
                    </Col>
                  ))}
                  <Col xs={{ span: 24 }}>
                    <Button>Create</Button>
                  </Col>
                </Row>
              </SelectRoleItems>
            </SelectRole>
          </Form>
        </Container>
      </Wrapper>
      <AddPermission
        visible={openModal}
        setVisible={() => setOpenModal(false)}
      />
    </>
  );
};

export default AllRoles;
