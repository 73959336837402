import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 1em 0 4em;
  background: #15112c;
  border-radius: 12px;
  position: relative;
  header {
    position: absolute;
    top: 35px;
    right: 10px;
    z-index: 2;
    display: flex;
    gap:0.5em;

    button {
      width: 116px;
      height: 36px;
      border-radius: 60px;
      background: #3a49d6;
      border: 1px solid #3a49d6;
      span {
        color: #ffffff;
      }
      &:hover {
        background: #3a49d699 !important;
        border: 1px solid #3a49d6;
        span {
          color: #ffffff;
        }
      }
    }
    .ant-dropdown-trigger {
      width: 116px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 0.4em;
      height: 36px;
      border-radius: 60px;
      background: #ffffff50;
      // border: 1px solid #ffffff50;
      cursor:pointer;
      font-size: 0.9em;
      &:hover {
        background: #ffffff50;
        // border: 1px solid #ffffff50;
       
      }
    }
  }
  .waitList {
    table {
      thead {
        tr {
          th {
            &:nth-child(2) {
              display: none !important;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:nth-child(2) {
              display: none !important;
            }
              span {
                  color: #ffffff !important;
                }
          }
        }
      }
    }
  }
 
    table {
      tbody {
        tr {
          td {
            
              span {
                  color: #ffffff !important;
                }
          }
        }
      }
    }
  @media (max-width: 768px) {
    & {
      padding: 0em 0em;
      background: #15112c00;
      header {
        padding: em 0em;
        margin-bottom: 1em;
        top:0;
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 0.2em;
        
        h2 {
          font-size: 1em;
          font-weightL 400;
        }
        button {
          width: 76px;
          span {
            font-size: 0.8em;
          }
        }
        .ant-dropdown-trigger {
          width: 76px;
          font-size: 0.8em;
        }
      }

      .todoDataTable {
        background: #15112c;
      }
    }
  }
`;
