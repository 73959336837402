import React, { useLayoutEffect, useState } from "react";
import {
  AddTaskForm,
  FormFooter,
  SelectArea,
} from "../screens/user/dashboard/index.style";
import { Button, DatePicker, Form, Input, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { FaUser } from "react-icons/fa";
import { IoListCircle } from "react-icons/io5";
import {
  ADD_TASK_API,
  GET_ALL_PATIENT_API,
  GET_ALL_USERS_API,
} from "../routes/ApiRoutes";
import toast from "react-hot-toast";
import axios from "axios";
import moment from "moment";

const { TextArea } = Input;

const AddTaskComponent = () => {
  const user = JSON.parse(localStorage.getItem("user")); // user data from localstorage

  // states
  const [selectValue, setSelectValue] = useState("");
  const [patientList, setPatientList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [taskFormData, setTaskFormData] = useState({
    assignee: "",
    priority: "",
    dueDate: "",
    patient: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const dateChange = (date, dateString) => {
    setTaskFormData({ ...taskFormData, dueDate: dateString });
  };

  const priorityChange = (item) => {
    setTaskFormData({ ...taskFormData, priority: item });
    if (item === "high") {
      setSelectValue("#ff8888");
    } else if (item === "medium") {
      setSelectValue("#8aff88");
    } else if (item === "low") {
      setSelectValue("#ffef61");
    }
  };

  const onFinish = (value) => {
    setIsLoading(true);
    const data = { ...value, ...taskFormData };
    axios
      .post(ADD_TASK_API, data, {
        headers: {
          Authorization: user?.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          toast.success("Task Created!");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePatientList = () => {
    axios
      .get(GET_ALL_PATIENT_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setPatientList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleUsersList = () => {
    axios
      .get(GET_ALL_USERS_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setUserList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  useLayoutEffect(() => {
    handlePatientList();
    handleUsersList();
  }, []);

  return (
    <AddTaskForm>
      <Form
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <FormItem name="description" rules={[{ required: true }]}>
          <TextArea
            autoSize={{
              minRows: 3,
              maxRows: 5,
            }}
            className="taskInput"
            placeholder="Write Task"
          />
        </FormItem>
        <SelectArea primary={selectValue}>
          <DatePicker
            onChange={dateChange}
            placeholder="Due Date"
            required
            disabledDate={disabledDate}
          />

          <FormItem name="priority" rules={[{ required: true }]}>
            <Select
              onChange={priorityChange}
              placeholder="Priority"
              className="prioritySelect"
              dropdownStyle={{ width: "auto", border: "none" }}
            >
              <Select.Option value="high" className="high">
                High
              </Select.Option>
              <Select.Option value="medium" className="medium">
                Medium
              </Select.Option>
              <Select.Option value="low" className="low">
                Low
              </Select.Option>
            </Select>
          </FormItem>

          <FormItem name="patient" rules={[{ required: true }]}>
            <Select
              onChange={(item) => {
                setTaskFormData({ ...taskFormData, patient: item });
              }}
              placeholder="Patient"
              dropdownStyle={{ width: "auto" }}
              direction="center"
              dropdownAlign="center"
            >
              {patientList.map((val, i) => (
                <Select.Option value={val._id} key={i}>
                  {val.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem name="assignee" rules={[{ required: true }]}>
            <Select
              onChange={(item) => {
                setTaskFormData({ ...taskFormData, assignee: item });
              }}
              defaultValue="Myself"
              dropdownStyle={{ width: "auto" }}
            >
              <Select.Option value={user?.user?._id}>
                <FaUser /> <span>Myself</span>
              </Select.Option>
              <Select.Option value="waitlist">
                <IoListCircle /> <span>Waitlist</span>
              </Select.Option>

              {userList.map((val, i) => (
                <Select.Option value={val._id} key={i}>
                  {val.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </SelectArea>
        <FormFooter style={{ padding: "1em" }}>
          <Button htmlType="submit">Add</Button>
        </FormFooter>
      </Form>
    </AddTaskForm>
  );
};

export default AddTaskComponent;
