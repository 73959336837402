import React, { useEffect, useLayoutEffect, useState } from "react";
import { ModalContainer } from "./index.style";
import {
  AddTaskForm,
  FormFooter,
  SelectArea,
} from "../../screens/user/dashboard/index.style";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { FaUser } from "react-icons/fa";
import { IoListCircle } from "react-icons/io5";
import FormItem from "antd/es/form/FormItem";
import axios from "axios";
import {
  GET_ALL_PATIENT_API,
  GET_ALL_USERS_API,
  GET_ONE_TASK_DATA_API,
  MARK_AS_COMPLETED_API,
} from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import dayjs from "dayjs";

const MarkAsCompleted = ({ visible, setVisible, id, updateList }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [selectValue, setSelectValue] = useState("");
  const [taskData, setTaskData] = useState({});
  const [patientList, setPatientList] = useState([]);
  const [userList, setUserList] = useState([]);

  const dateChange = (date, dateString) => {
    setTaskData({ ...taskData, dueDate: dateString });
  };

  const priorityChange = (item) => {
    setTaskData({ ...taskData, priority: item });
    if (item === "high") {
      setSelectValue("#ff8888");
    } else if (item === "medium") {
      setSelectValue("#8aff88");
    } else if (item === "low") {
      setSelectValue("#ffef61");
    }
  };

  const handleDataFetching = () => {
    axios
      .get(GET_ONE_TASK_DATA_API + id, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          setTaskData(res.data.data);
          if (res.data.data.priority === "high") {
            setSelectValue("#ff8888");
          } else if (res.data.data.priority === "medium") {
            setSelectValue("#8aff88");
          } else if (res.data.data.priority === "low") {
            setSelectValue("#ffef61");
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handlePatientList = () => {
    axios
      .get(GET_ALL_PATIENT_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setPatientList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleUsersList = () => {
    axios
      .get(GET_ALL_USERS_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setUserList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleEditTask = () => {
    axios
      .get(MARK_AS_COMPLETED_API + id, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          toast.success("Task marked as completed");
          setVisible(false);
          updateList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useLayoutEffect(() => {
    handlePatientList();
    handleUsersList();
  }, []);

  useEffect(() => {
    if (id) {
      handleDataFetching();
    }
  }, []);

  return (
    <ModalContainer
      title=""
      footer={false}
      open={visible}
      onCancel={setVisible}
      width="700px"
    >
      <AddTaskForm>
        {taskData.description && (
          <Form
            layout="vertical"
            name="basic"
            initialValues={{
              task: taskData.description,
            }}
            onFinish={(value) => {
              handleEditTask(value);
            }}
          >
            <FormItem name="task">
              <Input className="taskInput" disabled placeholder="Task Name |" />
            </FormItem>
            <SelectArea primary={selectValue}>
              <DatePicker
                onChange={dateChange}
                disabled
                defaultValue={dayjs(taskData.dueDate)}
              />

              <Select
                onChange={priorityChange}
                placeholder="Priority"
                disabled
                defaultValue={taskData.priority}
                className="prioritySelect"
                dropdownStyle={{ width: "auto", border: "none" }}
              >
                <Select.Option value="high" className="high">
                  High
                </Select.Option>
                <Select.Option value="medium" className="medium">
                  Medium
                </Select.Option>
                <Select.Option value="low" className="low">
                  Low
                </Select.Option>
              </Select>

              <Select
                onChange={(item) => setTaskData({ ...taskData, patient: item })}
                defaultValue={taskData.patient}
                placeholder="Patient"
                disabled
                dropdownStyle={{ width: "auto" }}
              >
                {patientList.map((val, i) => (
                  <Select.Option value={val._id} key={i}>
                    {val.name}
                  </Select.Option>
                ))}
              </Select>

              <Select
                onChange={(item) =>
                  setTaskData({ ...taskData, assignee: item })
                }
                placeholder="Priority"
                defaultValue={taskData.assignee}
                disabled
                dropdownStyle={{ width: "auto" }}
              >
                <Select.Option value={user?.user?._id}>
                  <FaUser /> <span>Myself</span>
                </Select.Option>
                <Select.Option value="waitlist">
                  <IoListCircle /> <span>Waitlist</span>
                </Select.Option>

                {userList.map((val, i) => (
                  <Select.Option value={val._id} key={i}>
                    {val.name}
                  </Select.Option>
                ))}
              </Select>
            </SelectArea>
            <FormFooter>
              <Button htmlType="submit">Mark As Completed</Button>
            </FormFooter>
          </Form>
        )}
      </AddTaskForm>
    </ModalContainer>
  );
};

export default MarkAsCompleted;
