import React, { useContext, useState } from "react";
import { MessageContainer } from "./index.style";
import { SearchArea, UserAvatar } from "../../screens/user/chats/index.style";
import { Input } from "antd";
import axios from "axios";
import toast from "react-hot-toast";
import {
  chatPreviewContext,
  newChatUserContext,
} from "../../screens/user/chats";

const { Search } = Input;

const NewMessage = ({ visible, setVisible }) => {
  const [list, setList] = useState([]);

  const { selectedUser, handleSelectedUser } = useContext(newChatUserContext);
  const { showChat, handleChatPreview } = useContext(chatPreviewContext);

  const handleSearch = (val) => {
    axios
      .get("https://gapcure.teknovation.io/search/users?query=" + val, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <MessageContainer
      centered
      title={<h3>New Message</h3>}
      footer={false}
      open={visible}
      onCancel={setVisible}
    >
      <div className="line"></div>
      <SearchArea style={{ margin: "1em 0" }}>
        <Search
          placeholder="Search Contact"
          allowClear
          onChange={(e) => handleSearch(e.target.value)}
        />
      </SearchArea>
      <div className="line"></div>

      {list.map((val) => (
        <div
          onClick={() => {
            handleChatPreview();
            handleSelectedUser(val);
            setVisible(false);
          }}
          style={{ borderBottom: "2px solid #ffffff20" }}
          key={val._id}
        >
          <UserAvatar>
            <img src={val.profilePic} style={{ borderRadius: "50%" }} alt="" />
            <div>
              <h6>{val.firstName + " " + val.lastName}</h6>
              <span>{val.designation}</span>
            </div>
          </UserAvatar>
        </div>
      ))}
    </MessageContainer>
  );
};

export default NewMessage;
