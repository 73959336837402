import React, { useLayoutEffect } from "react";
import { CustomTable, Status, TableAction } from "./index.style";
import { useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import DeleteMessage from "../modals/DeleteMessage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GET_ALL_PATIENT_API } from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import { render } from "@testing-library/react";

const AdminPatientListData = ({ showEditTaskModal }) => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const [showDeleteTaskMessage, setShowDeleteTaskMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const handleShowDelete = () => {
    setShowDeleteTaskMessage(true);
  };

  const handleCancel = () => {
    setShowDeleteTaskMessage(false);
  };

  const handlePatientList = () => {
    setIsLoading(true);
    axios
      .get(GET_ALL_PATIENT_API, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setPatientList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useLayoutEffect(() => {
    handlePatientList();
  }, []);

  const columns = [
    {
      title: "Patients",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {},
    {
      title: "Pytho Score",
      dataIndex: "assignee",
      key: "assignee",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: (assignee) => {
        let color = "#FFCB41";
        return (
          <span
            style={{
              color: color,
            }}
          >
            <span
              span
              style={{
                color: color,
                textAlign: "right",
              }}
            >
              {assignee >= 100 ? "+" : "-"}9%
            </span>
            <div style={{ paddingRight: "0.3em" }}>
              {assignee} {assignee >= 100 ? <FaCaretUp /> : <FaCaretDown />}
            </div>
          </span>
        );
      },
    },
    {
      title: "Doctor",
      dataIndex: "patientCareTeam",
      key: "patientCareTeam",
      render: (patientCareTeam) => {
        const maxLength = 15;
        const names = patientCareTeam.map((item) => item.firstName).join(", ");
        return (
          <>
            {names.length > maxLength
              ? `${names.substring(0, maxLength)}...`
              : names}
          </>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <TableAction>
            <span onClick={showEditTaskModal}>
              <img src="/icons/edit.svg" alt="" />
            </span>
            <span
              onClick={() => {
                setSelectedPatient(record);
                handleShowDelete();
              }}
            >
              <img src="/icons/ben.svg" alt="" />
            </span>
          </TableAction>
        );
      },
    },
  ];

  return (
    <>
      <DeleteMessage
        visible={showDeleteTaskMessage}
        setVisible={handleCancel}
        message="Patient"
        id={selectedPatient?._id}
        updateList={handlePatientList}
      />
      <div className="adminPatientListData">
        <CustomTable
          dataSource={patientList}
          columns={columns}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default AdminPatientListData;
