import React, { createContext, useState } from "react";

import CustomCalendar from "../../../components/calendar";
import { Wrapper } from "../alert/index.style";
import AddEvent from "../../../components/modals/AddEvent";

export const DateContext = createContext();

const CalendarPage = () => {
  const [showEventModal, setShowEventModal] = useState(false);
  const [dueDate, setDueDate] = useState(null);

  const handleShowEvent = () => {
    setShowEventModal(true);
  };

  const handleCancel = () => {
    setShowEventModal(false);
  };

  const updateDate = (val) => {
    setDueDate(val);
  };

  return (
    <DateContext.Provider value={{ dueDate, updateDate }}>
      <AddEvent
        visible={showEventModal}
        setVisible={handleCancel}
        date={dueDate}
      />
      <Wrapper>
        <div style={{ padding: " 0em 0em 4em" }}>
          <CustomCalendar showEvent={handleShowEvent} />
        </div>
      </Wrapper>
    </DateContext.Provider>
  );
};

export default CalendarPage;
