import React from "react";
import { Wrapper } from "./index.style";
import { Button, Col, Row } from "antd";
import { useState } from "react";
import AddPermission from "../../../components/modals/AddPermission";
import { useNavigate } from "react-router-dom";

const CreateRoles = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const permission = [
    { title: "Dashboard Read" },
    { title: "All Available Certificates" },
    { title: "My Requests History" },
    { title: "My Approved Certificates" },
    { title: "Lorem" },
    { title: "Dashboard Read" },
  ];

  const users = [
    { name: "Phelan Pratt", email: "client1@gmail.com" },
    { name: "Tatyana Howell", email: "client2@gmail.com" },
    { name: "Dylan Klein", email: "client3@gmail.com" },
    { name: "Zeph Park", email: "client4@gmail.com" },
    { name: "Test client", email: "client5@gmail.com" },
  ];

  return (
    <>
      <Wrapper>
        <header>
          <p>DOCTORS</p>
          <Button onClick={() => navigate("/admin/create_roles/all_roles")}>
            All Roles
          </Button>
        </header>
        <Row gutter={{ xs: 6, md: 16 }} style={{ paddingTop: "1em" }}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 14 }}
            style={{ paddingBottom: "2em" }}
          >
            <div className="container">
              <header>
                <p className="permissionHeading">Permissions</p>
                <Button onClick={() => setOpenModal(true)} className="plusBtn">
                  <img src="/icons/plus-white.svg" alt="" />
                  PERMISSION
                </Button>
              </header>
              <Row gutter={{ xs: 6, md: 20 }}>
                {permission.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      xs={{ span: 24 }}
                      md={{ span: 12 }}
                      style={{ paddingBottom: "2em" }}
                    >
                      <div className="card">
                        <p className="textPermission">{item.title}</p>
                        <img src="/icons/ben-red.svg" alt="" />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 10 }}
            style={{ paddingBottom: "2em" }}
          >
            <div className="container">
              <header>
                <p className="permissionHeading">Users</p>
                <Button className="plusBtn">
                  <img src="/icons/plus-white.svg" alt="" />
                  User
                </Button>
              </header>
              <Row gutter={{ xs: 6, md: 20 }}>
                {users.map((item, index) => {
                  return (
                    <Col key={index} span={24} style={{ paddingBottom: "1em" }}>
                      <div className="cardUser">
                        <div>
                          <p className="textName">{item.name}</p>
                          <p className="textEmail">{item.email}</p>
                        </div>
                        <img src="/icons/ben-red.svg" alt="" />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </Wrapper>
      <AddPermission
        visible={openModal}
        setVisible={() => setOpenModal(false)}
      />
    </>
  );
};

export default CreateRoles;
