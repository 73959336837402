import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 0em 0em;
`;
export const ImageSection = styled("div")`
  padding: 0.5em 0;
  border-radius: 10px;
  //   height: 258px;
  margin-bottom: 1.3em;
  background: #15112c;
  h1 {
    border-bottom: 1px solid #ffffff99;
    margin: 0.2em 0;
    padding-left: 1em;
    padding-bottom: 0.3em;
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: 600;
  }
  form {
    padding: 1em 2em 0;
  }
  @media (max-width: 992px) {
    & {
      h1 {
        font-size: 1em;
      }
      form {
        padding: 2em 1em 0;
      }
    }
  }
`;

export const UploadArea = styled("div")`
  width: 200px;
  span {
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 0.3em;
    display: block;
    color: #ffffff;
  }
  @media (max-width: 768px) {
    & {
      width: 100%;
      text-align: center;
    }
  }
`;
export const Avatar = styled("div")`
  width: 82px;
  height: 82px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  box-shadow: inset 0 0 10px 0 #000000;
  img {
    max-width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    & {
      margin: auto;
      img {
        width: 100%;
      }
    }
  }
`;
export const UserProfileAvatar = styled("div")`
  max-width: 500px;
  display: flex;
  align-items: center;
  gap: 1em;
  .nameInput {
    width: 100%;
  }
  @media (max-width: 768px) {
    & {
      flex-direction: column;
      text-align: center;
    }
  }
`;
