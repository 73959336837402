import axios from "axios";
import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { GET_WAITLIST_API } from "../../routes/ApiRoutes";
import toast from "react-hot-toast";

const WaitListData = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [list, setList] = useState([]);
  console.log(list, "----------data");

  const handleList = () => {
    axios
      .get(GET_WAITLIST_API + "/waitlist", {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useLayoutEffect(() => {
    handleList();
  }, []);

  return (
    <Container>
      <Header>
        <p>Patient Name</p>
        <div>
          <p>Status</p>
        </div>
      </Header>
      {list.map((val, i) => (
        <Items key={i}>
          <p>{val.patientName}</p>
          <div>
            <span className={val.completed ? "" : "status"}>
              {val.completed ? "Done" : "Not Completed"}
            </span>
          </div>
        </Items>
      ))}
    </Container>
  );
};

export default WaitListData;

export const Container = styled("div")`
  div {
    border-top: 1px solid #ffffff90;
    &:first-child {
      border-top: none;
    }
  }
`;
export const Header = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -6px;
  padding: 0.3em 1em;
  background: #24204a;
  div {
    border-top: 0px solid #ffffff90;
    text-align: center;
    width: 90px;
  }
  p {
    font-weight: 600;
  }
`;
export const Items = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 1em;
  div {
    border-top: 0px solid #ffffff90;
    text-align: center;
    width: 110px;
  }
  p {
    margin: 0;
  }
  span {
    background: #ffffffcc;
    height: 22px;
    text-align: center;
    font-size: 11px;
    padding: 2px 10px;
    border-radius: 61px;
    color: #000000;
  }
  .status {
    background: #ffffff50;
  }
`;
