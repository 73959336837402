import React, { useLayoutEffect, useState } from "react";
import { CustomTable, Priority, Status, TableAction } from "./index.style";
import { Checkbox } from "antd";
import axios from "axios";
import { GET_DUE_DATE_TASK_API } from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import EditTask from "../modals/EditTask";
import DeleteMessage from "../modals/DeleteMessage";
import MarkAsCompleted from "../modals/MarkAsCompleted";
import { useNavigate } from "react-router-dom";

const TodoList = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [list, setList] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [statusChangeModal, setStatusChangeModal] = useState(false);

  const handleList = () => {
    axios
      .get(GET_DUE_DATE_TASK_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useLayoutEffect(() => {
    handleList();
  }, []);

  const handleRowClick = (record) => {
    console.log(record);
    setSelectedRowKeys(record);
  };

  const mapDataSource = (data) =>
    data.map((item) => ({
      key: item.id,
      task: (
        <div>
          <Checkbox defaultChecked={item.completed}>
            <h3 style={{ maxWidth: "300px" }}>{item.description}</h3>
            <div>
              <span>{item.assigneeName}</span>
              <span>{new Date(item.dueDate).toLocaleDateString()}</span>
            </div>
          </Checkbox>
        </div>
      ),
      priority: item.priority
        ? item.priority?.charAt(0).toUpperCase() + item.priority?.slice(1)
        : "",
      status: item.completed ? "Completed" : "Not Complete",
      action: "delete",
      address: "10 Downing Street",
    }));

  const columns = [
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: (priority) => {
        let color = "#FF888800";
        if (priority === "Medium") {
          color = "#8AFF88";
        }
        if (priority === "Low") {
          color = "#FFEF61";
        }
        if (priority === "High") {
          color = "#FF8888";
        }
        return <Priority style={{ background: color }}>{priority}</Priority>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: (status) => (
        <Status
          onClick={() => {
            if (status !== "Completed") {
              setStatusChangeModal(true);
            }
          }}
          style={
            status === "Completed"
              ? { backgroundColor: "#ffffff" }
              : { backgroundColor: "#ffffff90", cursor: "pointer" }
          }
        >
          {status}
        </Status>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      render: () => (
        <TableAction>
          <span onClick={() => setEditModal(true)}>
            <img src="/icons/edit.svg" alt="" />
          </span>
          <span onClick={() => setDeleteModal(true)}>
            <img src="/icons/ben.svg" alt="" />
          </span>
          <span onClick={() => navigate("/calendar")}>
            <img src="/icons/calendar.svg" alt="" />
          </span>
        </TableAction>
      ),
    },
  ];

  return (
    <div>
      {selectedRowKeys?.key && (
        <>
          <EditTask
            visible={editModal}
            setVisible={() => setEditModal(false)}
            updateList={() => handleList()}
            id={selectedRowKeys?.key}
          />
          <MarkAsCompleted
            visible={statusChangeModal}
            setVisible={() => setStatusChangeModal(false)}
            updateList={() => handleList()}
            id={selectedRowKeys?.key}
          />
        </>
      )}
      <DeleteMessage
        visible={deleteModal}
        setVisible={() => setDeleteModal(false)}
        message="task"
        updateList={() => handleList()}
        id={selectedRowKeys?.key}
      />
      <CustomTable
        dataSource={mapDataSource(list)}
        columns={columns}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </div>
  );
};

export default TodoList;
