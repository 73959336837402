import React from "react";

function TimestampConverter({ timestamp }) {
  const convertTimestampToReadable = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const readableFormat = `${day < 10 ? "0" + day : day}-${
      month < 10 ? "0" + month : month
    }-${year}`;

    return readableFormat;
  };

  return <div>{convertTimestampToReadable(timestamp)}</div>;
}

export default TimestampConverter;
