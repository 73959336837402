import React, { useState } from "react";
import { Buttons, MessageContainer } from "./index.style";
import { Button } from "antd";
import axios from "axios";
import {
  DELETE_ALERT_API,
  DELETE_CHAT_API,
  DELETE_PATIENT_API,
  DELETE_REMINDER_API,
  DELETE_TASK_API,
  DELETE_USER_API,
} from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import { DELETE_ADMIN } from "../../routes/AdminApiRoutes";

const DeleteMessage = ({ visible, setVisible, message, id, updateList }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [isProgress, setIsProgress] = useState(false);

  const deleteApiMap = {
    reminder: DELETE_REMINDER_API,
    patient: DELETE_PATIENT_API,
    alert: DELETE_ALERT_API,
    task: DELETE_TASK_API,
    chat: DELETE_CHAT_API,
    user: DELETE_USER_API,
    admin: DELETE_ADMIN,
  };

  const handleDelete = () => {
    const token = user.token;
    const authHeader = token.startsWith("Bearer ") ? token : `Bearer ${token}`;

    setIsProgress(true);
    const deleteApi = deleteApiMap[message.toLowerCase()];

    const axiosRequest =
      message.toLowerCase() === "admin"
        ? axios.delete(deleteApi + id, {
            headers: { Authorization: authHeader },
          })
        : axios.get(deleteApi + id, { headers: { Authorization: authHeader } });

    axiosRequest
      .then((res) => {
        if (res.data.status) {
          toast.success(
            `${message.charAt(0).toUpperCase() + message.slice(1)} deleted`
          );
          setVisible(false);
          updateList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsProgress(false);
      });
  };

  return (
    <MessageContainer
      centered
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      <h3>Sure you want to delete?</h3>
      <p>Are you sure you want to delete this {message}?</p>
      <Buttons>
        <Button onClick={() => setVisible(false)}>No, cancel</Button>
        <Button onClick={handleDelete} loading={isProgress}>
          Yes, confirm
        </Button>
      </Buttons>
    </MessageContainer>
  );
};

export default DeleteMessage;
