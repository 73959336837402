import React from "react";
import { CustomTable, TableAction } from "./index.style";
import { useNavigate } from "react-router-dom";

const RolesDataTable = ({ handleUpdate, handleDelete }) => {
  const navigate = useNavigate();
  const dataSource = [
    {
      key: "1",
      task: "Doctors",
      user: "5 user(s)",
    },
    {
      key: "2",
      task: "Nurses",
      user: "7 user(s)",
    },
    {
      key: "3",
      task: "Caretakers",
      user: "8 user(s)",
    },
    {
      key: "3",
      task: "Nurses",
      user: "6 user(s)",
    },
  ];

  const columns = [
    {
      title: "Role",
      dataIndex: "task",
      key: "task",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Users",
      dataIndex: "user",
      key: "user",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "PERMISSIONS",
      dataIndex: "action",
      key: "action",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      width: "50%",
      render: () => (
        <TableAction>
          <span className="permission">Permission</span>
          <span className="permission">Permission</span>
          <span className="permission">Permission</span>
          <span className="showMore">+3 more</span>
        </TableAction>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
      width: "15%",
      render: () => (
        <TableAction>
          <span>
            <img src="/icons/eye_open.svg" alt="" />
          </span>
          <span onClick={handleUpdate}>
            <img src="/icons/edit.svg" alt="" />
          </span>
          <span onClick={handleDelete}>
            <img src="/icons/ben.svg" alt="" />
          </span>
        </TableAction>
      ),
    },
  ];

  return (
    <div className="todoDataTable rolesDataTable">
      <CustomTable dataSource={dataSource} columns={columns} />
    </div>
  );
};

export default RolesDataTable;
