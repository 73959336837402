import React, { useEffect, useState } from "react";
import { Wrapper } from "./index.style";
import { Button } from "antd";
import UserManagementList from "../../../components/dataTables/UserList";
import AddUser from "../../../components/modals/AddUser";
import UserInvite from "../../../components/modals/InviteUser";
import axios from "axios";
import { GET_USERS_API } from "../../../routes/ApiRoutes";
import toast from "react-hot-toast";

const UserManagement = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const handleData = () => {
    axios
      .get(GET_USERS_API, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((res) => {
        if (res.data.status) {
          setUsers(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <>
      <Wrapper>
        <header>
          <p>USER MANAGEMENT</p>
          <div className="btnContainer">
            {/* <Button
              onClick={() => setOpenInviteModal(true)}
              className="inviteBtn"
            >
              Refer user
            </Button> */}
            <Button onClick={() => setOpenModal(true)}>Add User</Button>
          </div>
        </header>
        <UserManagementList users={users} updateList={handleData} />
      </Wrapper>

      <AddUser
        visible={openModal}
        type="add"
        setVisible={() => setOpenModal(false)}
        handleList={handleData}
      />

      <UserInvite
        visible={openInviteModal}
        setVisible={() => setOpenInviteModal(false)}
      />
    </>
  );
};

export default UserManagement;
