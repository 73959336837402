import React, { useState } from "react";
import TodoWaitList from "../../../components/dataTables/TodoWaitList";
import { CustomTabs } from "../../../common/index.style";
import { Button, Dropdown } from "antd";
import { Wrapper } from "./index.style";
import { MdOutlineTune } from "react-icons/md";
import AddEvent from "../../../components/modals/AddEvent";
import CreateTaskModal from "../../../components/modals/CreateTask";

const ToDoLists = () => {
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);

  const handleShowEvent = () => {
    setShowEventModal(true);
  };
  const handleShowCreateTaskModal = () => {
    setShowCreateTaskModal(true);
  };

  const handleCancel = () => {
    setShowEventModal(false);
    setShowCreateTaskModal(false);
  };

  const onChange = (key) => {
    console.log(key); 
  };
  const items = [
    {
      label: <a href="#">Sort by Priority</a>,
      key: "0",
    },
    {
      label: <a href="#">Sort by Status</a>,
      key: "1",
    },
  ];
  const tabs_items = [
    {
      key: "1",
      label: "To-Do List",
      children: (
        <div className="mobileTable">
          <TodoWaitList showEvent={handleShowEvent} type="todo" />
        </div>
      ),
    },
    {
      key: "2",
      label: "Waitlist",
      children: (
        <div className="mobileTable">
          <TodoWaitList showEvent={handleShowEvent} type="waitlist" />
        </div>
      ),
    },
  ];
  return (
    <>
      <CreateTaskModal
        visible={showCreateTaskModal}
        setVisible={handleCancel}
      />
      <AddEvent visible={showEventModal} setVisible={handleCancel} />
      <Wrapper>
        <header>
          <Button onClick={handleShowCreateTaskModal}>Create</Button>
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <MdOutlineTune />
              View
            </a>
          </Dropdown>
        </header>
        <CustomTabs
          defaultActiveKey="1"
          items={tabs_items}
          onChange={onChange}
        />
      </Wrapper>
    </>
  );
};

export default ToDoLists;
