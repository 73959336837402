import React, { useLayoutEffect, useState } from "react";
import { CreateModal } from "./index.style";
import { Button, Col, DatePicker, Form, Row, Select, TimePicker } from "antd";
import CustomInput from "../../common/CustomInput";
import { FormFooter } from "../../screens/user/dashboard/index.style";
import {
  GET_ALL_PATIENT_API,
  UPDATE_ALERT_API,
  UPDATE_REMINDER_API,
} from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const EditAlert = ({ visible, setVisible, titleName, data, updateList }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [patientList, setPatientList] = useState([]);
  const [isProgress, setIsProgress] = useState(false);

  const format = "HH:mm";

  const dateChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const fetchPatientList = async () => {
    try {
      const res = await axios.get(GET_ALL_PATIENT_API, {
        headers: {
          Authorization: user.token,
        },
      });
      if (res.data?.status) {
        setPatientList(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleEditAlert = async (values) => {
    setIsProgress(true);
    try {
      const res = await axios.post(
        UPDATE_ALERT_API + data._id,
        {
          alertName: values.alert_name,
          date: values.date.format("YYYY-MM-DD"),
          time: values.time.format("HH:mm"),
        },
        {
          headers: {
            Authorization: user.token,
          },
        }
      );
      if (res.data.status) {
        toast.success("Alert edited.");
        setVisible(false);
        updateList();
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsProgress(false);
    }
  };

  const handleEditReminder = async (values) => {
    setIsProgress(true);
    try {
      const res = await axios.post(
        UPDATE_REMINDER_API + data._id,
        {
          reminderName: values.alert_name,
          date: values.date.format("YYYY-MM-DD"),
          time: values.time.format("HH:mm"),
        },
        {
          headers: {
            Authorization: user.token,
          },
        }
      );
      if (res.data.status) {
        toast.success("Reminder edited.");
        setVisible(false);
        updateList();
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsProgress(false);
    }
  };

  const handleSubmit = (values) => {
    if (titleName === "Alert") {
      handleEditAlert(values);
    } else {
      handleEditReminder(values);
    }
  };

  useLayoutEffect(() => {
    fetchPatientList();
  }, []);

  return (
    <CreateModal
      footer={false}
      title={<h3>Edit {titleName}</h3>}
      open={visible}
      onCancel={() => setVisible(false)}
      width="700px"
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          alert_name:
            titleName === "Alert" ? data.alertName : data.reminderName,
          patient: data?.patient,
          date: dayjs(data.date, "YYYY-MM-DD"),
          time: dayjs(data.time, "HH:mm"),
        }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <CustomInput label={`${titleName} Name`} name="alert_name" />
          </Col>
          <Col span={24}>
            <Form.Item
              label="Choose Patient"
              name="patient"
              rules={[
                {
                  required: true,
                  message: "Please select patient!",
                },
              ]}
            >
              <Select placeholder="Choose Patient">
                {patientList.map((val) => (
                  <Select.Option value={val._id} key={val._id}>
                    {val.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please input your Date!",
                },
              ]}
            >
              <DatePicker
                onChange={dateChange}
                placeholder="mm/dd/yyyy"
                format="MM/DD/YYYY"
                disabledDate={(current) => current < dayjs().startOf("day")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Time"
              name="time"
              rules={[
                {
                  required: true,
                  message: "Please input your Time!",
                },
              ]}
            >
              <TimePicker format={format} placeholder="hh/mm" />
            </Form.Item>
          </Col>
          <FormFooter style={{ borderTop: "none", width: "100%" }}>
            <Button htmlType="submit" disabled={isProgress}>
              Save
            </Button>
          </FormFooter>
        </Row>
      </Form>
    </CreateModal>
  );
};

export default EditAlert;
