import { Navigate, useLocation } from "react-router-dom";

const ProtectedAdmin = ({ children }) => {
  let location = useLocation();
  const isAuthenticated = localStorage.getItem("user") !== null;
  if (!isAuthenticated) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedAdmin;
