import styled from "styled-components";

export const Wrapper = styled("div")`
  padding-bottom: 1em;
  .mobileTable {
    overflow-x: auto;
    border-radius: 0px;
    padding-bottom: 1em;
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }
    &::-webkit-scrollbar {
      // width: 9px !important;
      height: 5px !important;
    }
    .ant-table-thead {
      .ant-table-cell {
        text-align: left !important;
        &:last-child {
          text-align: center !important;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        text-align: left !important;
        padding-left: 1.5em;
        &:first-child {
          border-left: 1px solid #ffffff !important;
        }
        &:last-child {
          text-align: center !important;
          border-right: 1px solid #ffffff !important;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 2em !important;
  }
`;
export const Container = styled("div")`
  background: #15112c;
  border-radius: 20px;
  padding: 1.5em;
  @media (max-width: 768px) {
    & {
      border-radius: 10px;
      border: 1px solid #ffffff !important;
      padding: 0.5em;
    }
  }
`;
export const SelectRole = styled("div")`
  border-radius: 20px;
  span {
    color: #ffffffff;
    margin-bottom: 1em;
    display: flex;
  }
`;
export const SelectRoleItems = styled("div")`
  border-radius: 20px;
  .active {
    background: #b5eefa;
  }
  button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 76px;
    border-radius: 12px;
    background: #3a49d6;
    border: 1px solid #3a49d6;
    margin-top: 2em !important;
    span {
      color: #ffffff;
      margin-bottom: 0em !important;
      text-transform: uppercase;
      font-size: 1.3em;
      font-weight: 400;
    }
    &:hover {
      background: #3a49d6 !important;
      span {
        color: #ffffff !important;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      button {
        margin-top: 0.5em !important;
        height: 46px;
        span {
          font-size: 1em;
        }
      }
    }
  }
`;

export const Header = styled("header")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0 2em;
  h1 {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: 500;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    height: 36px;
    border-radius: 60px;
    background: #3a49d6;
    border: 1px solid #3a49d6;
    span {
      color: #ffffff;
    }
    svg {
      color: #ffffff;
      font-size: 1.5em;
    }
    &:hover {
      background: #3a49d699 !important;
      border: 1px solid #3a49d6;
      span {
        color: #ffffff;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      h1 {
        font-size: 1em;
      }
      button {
        gap: 0.3em;
        height: 30px;

        span {
          font-size: 0.85em;
        }
        svg {
          font-size: 1.3em;
        }
      }
    }
  }
`;
