import React, { useState } from "react";
import styled from "styled-components";

const Roles = ({ data }) => {
  const [selectRole, setSelectRole] = useState(false);
  return (
    <SelectItems
      onClick={() => setSelectRole(!selectRole)}
      className={selectRole ? "active" : ""}
    >
      {data.title}
    </SelectItems>
  );
};

export default Roles;

const SelectItems = styled("div")`
  display: flex;
  align-items: center;
  height: 86px;
  border-radius: 22.77px 0px 0px 0px;
  border-radius: 20px;
  padding: 0em 1em;
  margin-bottom: 1em;
  background: #ffffff;
  cursor: pointer;
  @media (max-width: 768px) {
    & {
      border-radius: 10px;
      height: 60px;
      margin-bottom: 0.5em;
    }
  }
`;
