import axios from "axios";
import React, { useState } from "react";
import { ADD_MULTIPLE_ALERTS_API } from "../routes/ApiRoutes";
import toast from "react-hot-toast";
import {
  AddBtn,
  AddPatients,
} from "../screens/user/patientProfile/index.style";
import { Button, Col, DatePicker, Form, Input, Row, TimePicker } from "antd";
import { InputContainer } from "../common/CustomInput";
import { FiPlus } from "react-icons/fi";
import { AddAlertBtn } from "../common/index.style";
import moment from "moment";

const AddMultiAlerts = ({ id }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [showAlertForm, setShowAlertForm] = useState(false);
  const [alertsForm, setAlertsForm] = useState([
    { alertName: "", date: "", time: "" },
  ]);

  const format = "HH:mm";

  const handleInputChange = (index, field, value) => {
    const newAlertsForm = [...alertsForm];
    newAlertsForm[index][field] = value;
    setAlertsForm(newAlertsForm);
  };

  const dateChange = (date, dateString, index) => {
    handleInputChange(index, "date", dateString);
  };

  const timeChange = (time, timeString, index) => {
    handleInputChange(index, "time", timeString);
  };

  const handleSubmit = () => {
    const alerts = alertsForm.filter(
      (alert) => alert.alertName || alert.date || alert.time
    );

    const formData = { patient: id, alerts };

    axios
      .post(ADD_MULTIPLE_ALERTS_API, formData, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          toast.success("Alerts Added!");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };
  return (
    <div>
      {showAlertForm ? (
        <>
          <AddPatients>
            <h1>Add Alert</h1>
            <Row align="bottom">
              <Col xs={{ span: 24 }} md={{ span: 23 }}>
                <Row gutter={{ xs: 6, md: 10, lg: 20 }} align="middle">
                  <>
                    {alertsForm.map((val, i) => (
                      <React.Fragment key={i}>
                        <Col span={8}>
                          <InputContainer>
                            <Form.Item
                              label="Alert Name"
                              name={`alertName${i}`}
                              rules={[
                                {
                                  required: true,
                                  message: `Please input your Alert Name!`,
                                },
                              ]}
                            >
                              <Input
                                placeholder="Alert Name"
                                value={val.alertName}
                                onChange={(e) =>
                                  handleInputChange(
                                    i,
                                    "alertName",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Item>
                          </InputContainer>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Date">
                            <DatePicker
                              disabledDate={disabledDate}
                              onChange={(date, dateString) =>
                                dateChange(date, dateString, i)
                              }
                              placeholder="mm/dd/yyyy"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="Time">
                            <TimePicker
                              format={format}
                              placeholder="hh/mm"
                              onChange={(time, timeString) =>
                                timeChange(time, timeString, i)
                              }
                            />
                          </Form.Item>
                        </Col>
                      </React.Fragment>
                    ))}
                  </>
                </Row>
              </Col>
              <AddBtn
                positionStyle="absolute"
                style={{ top: 0, bottom: 0, margin: "auto" }}
              >
                <FiPlus
                  onClick={() => {
                    setAlertsForm([
                      ...alertsForm,
                      { alertName: "", date: "", time: "" },
                    ]);
                  }}
                />
              </AddBtn>
            </Row>
            <div className="cardFooter">
              <div></div>
              <Button onClick={handleSubmit}>Save</Button>
            </div>
          </AddPatients>
        </>
      ) : (
        <AddAlertBtn onClick={() => setShowAlertForm(true)}>
          <h1>Add Alert</h1>
          <AddBtn>
            <FiPlus />
          </AddBtn>
        </AddAlertBtn>
      )}
    </div>
  );
};

export default AddMultiAlerts;
