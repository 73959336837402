import React, { useEffect, useLayoutEffect, useState } from "react";
import { ModalContainer } from "./index.style";
import {
  AddTaskForm,
  FormFooter,
  SelectArea,
} from "../../screens/user/dashboard/index.style";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { FaUser } from "react-icons/fa";
import { IoListCircle } from "react-icons/io5";
import FormItem from "antd/es/form/FormItem";
import axios from "axios";
import {
  GET_ALL_PATIENT_API,
  GET_ALL_USERS_API,
  GET_ONE_TASK_DATA_API,
} from "../../routes/ApiRoutes";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const EditTask = ({ visible, setVisible, id, updateList }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [selectValue, setSelectValue] = useState("");
  const [taskData, setTaskData] = useState({});
  const [patientList, setPatientList] = useState([]);
  const [userList, setUserList] = useState([]);

  const dateChange = (date, dateString) => {
    setTaskData({ ...taskData, dueDate: dateString });
  };

  const priorityChange = (item) => {
    setTaskData({ ...taskData, priority: item });
    if (item === "high") {
      setSelectValue("#ff8888");
    } else if (item === "medium") {
      setSelectValue("#8aff88");
    } else if (item === "low") {
      setSelectValue("#ffef61");
    }
  };

  const handleDataFetching = () => {
    axios
      .get(GET_ONE_TASK_DATA_API + id, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          setTaskData(res.data.data);
          const { priority, dueDate } = res.data.data;
          if (priority === "high") {
            setSelectValue("#ff8888");
          } else if (priority === "medium") {
            setSelectValue("#8aff88");
          } else if (priority === "low") {
            setSelectValue("#ffef61");
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handlePatientList = () => {
    axios
      .get(GET_ALL_PATIENT_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setPatientList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleUsersList = () => {
    axios
      .get(GET_ALL_USERS_API, {
        headers: {
          Authorization: user.token,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setUserList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleEditTask = (value) => {
    axios
      .post(
        GET_ONE_TASK_DATA_API + id,
        {
          assignee: taskData.assignee,
          priority: taskData.priority,
          dueDate: taskData.dueDate,
          patient: taskData.patient,
          description: value.task,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res.data.status) {
          toast.success("Task Edited");
          setVisible(false);
          updateList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useLayoutEffect(() => {
    handlePatientList();
    handleUsersList();
  }, []);

  useEffect(() => {
    if (id) {
      handleDataFetching();
    }
  }, [id]);

  return (
    <ModalContainer
      title=""
      footer={false}
      open={visible}
      onCancel={() => setVisible(false)}
      width="700px"
    >
      <AddTaskForm>
        {taskData.description && (
          <Form
            layout="vertical"
            name="basic"
            initialValues={{
              task: taskData.description,
              dueDate: dayjs(taskData.dueDate),
              priority: taskData.priority,
              patient: taskData.patient,
              assignee: taskData.assignee,
            }}
            onFinish={(value) => {
              handleEditTask(value);
            }}
          >
            <FormItem name="task">
              <Input className="taskInput" placeholder="Task Name |" />
            </FormItem>
            <SelectArea primary={selectValue}>
              <FormItem name="dueDate" label="Due Date">
                <DatePicker
                  onChange={dateChange}
                  initialValue={dayjs(taskData.dueDate)}
                  format="YYYY-MM-DD"
                  placeholder="Due Date"
                />
              </FormItem>
              <FormItem name="priority" label="Priority">
                <Select
                  onChange={priorityChange}
                  placeholder="Priority"
                  defaultValue={taskData.priority}
                  className="prioritySelect"
                  dropdownStyle={{ width: "auto", border: "none" }}
                >
                  <Select.Option value="high" className="high">
                    High
                  </Select.Option>
                  <Select.Option value="medium" className="medium">
                    Medium
                  </Select.Option>
                  <Select.Option value="low" className="low">
                    Low
                  </Select.Option>
                </Select>
              </FormItem>
              <FormItem name="patient" label="Patient">
                <Select
                  onChange={(item) =>
                    setTaskData({ ...taskData, patient: item })
                  }
                  defaultValue={taskData.patient}
                  placeholder="Patient"
                  dropdownStyle={{ width: "auto" }}
                >
                  {patientList.map((val, i) => (
                    <Select.Option value={val._id} key={i}>
                      {val.name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem name="assignee" label="Assignee">
                <Select
                  onChange={(item) =>
                    setTaskData({ ...taskData, assignee: item })
                  }
                  defaultValue={taskData.assignee}
                  placeholder="Assignee"
                  dropdownStyle={{ width: "auto" }}
                >
                  <Select.Option value={user?.user?._id}>
                    <FaUser /> <span>Myself</span>
                  </Select.Option>
                  <Select.Option value="waitlist">
                    <IoListCircle /> <span>Waitlist</span>
                  </Select.Option>
                  {userList.map((val, i) => (
                    <Select.Option value={val._id} key={i}>
                      {val.name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </SelectArea>
            <FormFooter>
              <Button htmlType="submit">Save Changes</Button>
            </FormFooter>
          </Form>
        )}
      </AddTaskForm>
    </ModalContainer>
  );
};

export default EditTask;
