import axios from "axios";
import React, { useEffect } from "react";
import styled from "styled-components";
import {
  GET_ALL_NOTIFICATIONS_API,
  MARK_ALL_AS_READ_API,
} from "../../routes/ApiRoutes";
import toast from "react-hot-toast";

const NotificationAlert = ({ handelCloseModal }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [notifications, setNotifications] = React.useState([]);

  const handleGetNotifcations = () => {
    axios
      .get(GET_ALL_NOTIFICATIONS_API, {
        headers: { Authorization: user.token },
      })
      .then((res) => {
        if (res.data.status) {
          setNotifications(res.data.data);
          markAsRead();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const markAsRead = () => {
    axios
      .get(MARK_ALL_AS_READ_API, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res.data.status) {
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    handleGetNotifcations();
  }, []);
  return (
    <Container>
      <ul>
        {notifications.map((val, i) => (
          <li
            className={!val.isRead && `active`}
            onClick={() => handelCloseModal(false)}
          >
            <a href="#">{val.message}</a>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default NotificationAlert;

const Container = styled("div")`
  background: #15112c;
  border: 1px solid #ffffff;
  border-radius: 20px;
  overflow-y: auto;
  height: 50vh;
  padding: 0;
  ul {
    padding-left: 0;
    margin: 0;
    .active {
      background: #ffffff33;
      position: relative;
      &::before {
        width: 8px;
        height: 8px;
        background: rgb(251, 131, 131);
        border-radius: 20px;
        content: "";
        display: block;
        position: absolute;
        left: 15px;
        top: 17px;
      }
    }
  }
  li {
    color: #ffffff;
    list-style: none;
    padding: 0.5em 2em;
    a {
      color: #ffffff;
    }
    &:hover {
      background: #ffffff33;
    }
  }
`;
