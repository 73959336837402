import React, { useLayoutEffect, useState } from "react";
import {
  Avatar,
  ImageSection,
  UploadArea,
  UserProfileAvatar,
  Wrapper,
} from "./index.style";
import { Col, Form, Image, Row } from "antd";
import CustomInput from "../../../common/CustomInput";
import axios from "axios";
import { GET_PROFILE_DATA_API } from "../../../routes/ApiRoutes";
import toast from "react-hot-toast";

const Profile = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [data, setData] = useState({});

  const handleFetch = () => {
    axios
      .get(GET_PROFILE_DATA_API, { headers: { Authorization: user.token } })
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(`Error fetching profile data: ${err.message}`);
      });
  };

  useLayoutEffect(() => {
    handleFetch();
  }, []);

  return (
    <Wrapper>
      <ImageSection>
        <h1>Profile infomation</h1>
        {data?.username && (
          <Form
            layout="vertical"
            initialValues={{
              first_name: data?.firstName,
              last_name: data?.lastName,
            }}
          >
            <UserProfileAvatar>
              <UploadArea>
                <Avatar>
                  <Image
                    src={
                      typeof data.profilePic === "string" &&
                      data.profilePic.startsWith("https://")
                        ? data.profilePic
                        : "/images/avatar.PNG"
                    }
                    alt=""
                  />
                </Avatar>
                {/* <Upload
                  name="avatar"
                  showUploadList={false}
                  customRequest={({ file }) => {
                    if (file.type.startsWith("image/")) {
                      setProfileImage(file);
                    } else {
                      alert("Only image files are allowed.");
                    }
                  }}
                >
                  <span>Click to Edit</span>
                </Upload> */}
              </UploadArea>
              <div style={{ display: "flex", gap: "10px" }}>
                <div className="nameInput">
                  <CustomInput
                    label="First name"
                    disable={true}
                    name="first_name"
                    placeholder="Enter First Name"
                  />
                </div>
                <div className="nameInput">
                  <CustomInput
                    label="Last name"
                    disable={true}
                    name="last_name"
                    placeholder="Enter Last Name"
                  />
                </div>
              </div>
            </UserProfileAvatar>

            {/* <FormFooter style={Style}>
              <Button htmlType="submit">Save</Button>
            </FormFooter> */}
          </Form>
        )}
      </ImageSection>
      {/* <ImageSection>
        <h1>Change Password</h1>
        <Form layout="vertical" autoComplete="off">
          <Row>
            <Col span={24}>
              <Row gutter={26}>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <PasswordInput
                    label="Current Password"
                    name="c_password"
                    placeholder="Enter Current Password"
                  />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <PasswordInput
                    label="New Password"
                    name="n_password"
                    placeholder="New Password"
                  />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <PasswordInput
                    label="Re Enter New Password"
                    name="r_n_password"
                    placeholder="Re Enter New Password"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <FormFooter style={Style}>
            <Button>Save</Button>
          </FormFooter>
        </Form>
      </ImageSection> */}
      <ImageSection>
        <h1>Email Address</h1>
        {data?.email && (
          <Form
            layout="vertical"
            initialValues={{ email: data?.email }}
            // onFinish={handleSubmit}
          >
            <Row gutter={26}>
              <Col xs={{ span: 24 }} md={{ span: 16 }}>
                <Row gutter={26}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <CustomInput
                      disable={true}
                      label="Email"
                      name="email"
                      placeholder="Enter New Email"
                    />
                  </Col>
                  {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <PasswordInput
                      label="Password"
                      name="c_password"
                      placeholder="Your Current Password"
                    />
                  </Col> */}
                </Row>
              </Col>
            </Row>
            {/* <FormFooter style={Style}>
              <Button htmlType="submit">Save</Button>
            </FormFooter> */}
          </Form>
        )}
      </ImageSection>
    </Wrapper>
  );
};

export default Profile;
